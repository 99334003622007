import styles from "./FourthSection.module.css";
import IFPS from "../../../assets/images/IFPS.png";
import blockchain from "../../../assets/images/blockchain.png";
import encrypt from "../../../assets/images/encrypt.png";

const FourthSection = () => {
  return (
    <div className={styles.secondSection}>
      <h1 className={styles.heading2}>Why Data Nominee?</h1>
      <div className={styles.gridContainer}>
        <div className={styles.card}>
          <img src={IFPS} alt="IFPS" />
          <p className={styles.para}>IPFS Protocol</p>
          <p className={styles.para1}>
            Asset information is stored on the decentralized IPFS storage
            protocol
          </p>
        </div>
        <div className={styles.card}>
          <img src={blockchain} alt="blockchain" width={250} height={100} />
          <p className={styles.par}>Blockchain Integration</p>
          <p className={styles.par1}>
            We leverage the immutability of blockchain to create tamper-proof
            digital records of ownership of digital data
          </p>
        </div>
        <div className={styles.card}>
          <img src={encrypt} alt="encrypt" />
          <p className={styles.para}>Data Encryption </p>
          <p className={styles.para1}>
            All data is stored in an encrypted format, decipherable only by
            secure key
          </p>
        </div>
      </div>
    </div>
  );
};

export default FourthSection;
