import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Result } from "antd";
import { actionDeleteFiles } from "../store/slices/uploadSlice";
import { DeleteTwoTone } from "@ant-design/icons";

export default function DeleteModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.account.user);

  const record = props.record;

  const handleDelete = async () => {
    console.log("record: ", record);
    console.log("user: ", user);
    const data = {
      record,
      user,
    };
    await dispatch(actionDeleteFiles(data));
    setDeleted(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setDeleted(false);
    setIsModalOpen(false);
  };

  return (
    <div>
      <DeleteTwoTone style={{ fontSize: "1.5rem" }} onClick={showModal} />
      <Modal
        title="Delete Asset"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {deleted ? (
          <div className="fc">
            <Result
              status="success"
              title="Asset Deleted Successful"
              extra={[
                <Button
                  className="modal-btn"
                  type="primary"
                  key="console"
                  onClick={handleCancel}
                >
                  Ok Close
                </Button>,
              ]}
            />
          </div>
        ) : (
          <div className="fc">
            <p>Are you sure you want to delete these files?</p>
            <br />
            <Button
              className="modal-btn"
              type="primary"
              danger
              key="console"
              onClick={handleDelete}
            >
              Confirm Delete
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}
