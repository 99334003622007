import { Button, Table, Image } from "antd";
import React, { useEffect, useState } from "react";
//import {Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { axiosPostInstance } from "../utils/axiosPostInstance";
import { useLocation, useNavigate } from "react-router-dom";

const verifyClaim = async (userData) => {
  let response = null;
  try {
    const token = sessionStorage.getItem("accessToken");
    response = await axiosPostInstance(
      "/api/users/verifyClaim",
      token,
      userData
    );
    console.log("Response: ", response.data);
  } catch (err) {
    console.log("err: ", err.response.data);
    return err.response.data;
  }
  return { response: response.data };
};

const rejectClaim = async (userData) => {
  let response = null;
  try {
    const token = sessionStorage.getItem("accessToken");
    response = await axiosPostInstance(
      "/api/users/rejectClaim",
      token,
      userData
    );
    console.log("Response: ", response.data);
  } catch (err) {
    console.log("err: ", err.response.data);
    return err.response.data;
  }
  return { response: response.data };
};

const getAllClaims = async () => {
  try {
    const token = sessionStorage.getItem("accessToken");
    const response = await axiosPostInstance("/api/users/allClaims", token);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default function Adminpanal() {
  const [nomnom, setNomList] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [extra, setExtra] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let authinticated = false;

  useEffect(() => {
    const fetchNomineeList = async () => {
      const nomineeList = await getAllClaims();
      console.log("data: ", nomineeList);
      const nomList = nomineeList
        ? nomineeList.map((data, key) => ({
            key: key,
            nominatorEmail: data.nominatorEmail,
            nomineeEmail: data.nomineeEmail,
            dcRegNo: data.dcRegNo,
            viewDc: <Image width={100} src={data.deathCertAddress} />,
            verificationStatus: data.verificationStatus,
            approve: (
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    const dataTemp = {
                      message:
                        "Dear " +
                        data.nomineeName +
                        ", Congratulations!!! Your Claim for " +
                        data.nominatorName +
                        "'s assets in datanominee.com has been approved. Please visit datanominee.com/login to access your assets",
                      phone: data.nomineePhone,
                    };
                    verifyClaim({
                      nominator: data.nominatorEmail,
                      nominee: data.nomineeEmail,
                      status: "Approved",
                      data: dataTemp,
                    });

                    fetchNomineeList();
                    setNomList(nomList);
                    setExtra(!extra);
                  }}
                >
                  Approve
                </Button>
              </div>
            ),
            reject: (
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    const dataTemp = {
                      message:
                        "Dear " +
                        data.nomineeName +
                        ", We are sorry to say that your Claim for " +
                        data.nominatorName +
                        "'s assets in datanominee.com has been rejected. Please visit datanominee.com/login to check the status of your claim or contact us at datanominee@datanominee.com further enquiries",
                      phone: data.nomineePhone,
                    };
                    verifyClaim({
                      nominator: data.nominatorEmail,
                      nominee: data.nomineeEmail,
                      status: "Rejected",
                      data: dataTemp,
                    });
                    fetchNomineeList();
                    setNomList(nomList);
                    setExtra(!extra);
                  }}
                >
                  Reject
                </Button>
              </div>
            ),
          }))
        : [];
      console.log(location);
      if (location.state == null) {
        navigate("/adminLogin");
      }
      if (location.state.auth) {
        authinticated = true;
        setNomList(nomList);
      }
      setHasFetched(true);
    };

    if (!hasFetched) {
      fetchNomineeList();
    }
  }, [hasFetched, extra]);

  const columns = [
    {
      title: "Nominator Email",
      dataIndex: "nominatorEmail",
      key: "nominatorEmail",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Nominee Email",
      dataIndex: "nomineeEmail",
      key: "nomineeEmail",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Death Certificate",
      dataIndex: "dcRegNo",
      key: "dcRegNo",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "View",
      dataIndex: "viewDc",
      key: "viewDc",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Status",
      dataIndex: "verificationStatus",
      key: "verificationStatus",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "",
      dataIndex: "approve",
      key: "approve",
      align: "center",
      width: "5%",
      className: "column-title",
      render: (text, _record) => text,
    },
    {
      title: "",
      dataIndex: "reject",
      key: "reject",
      align: "center",
      width: "5%",
      className: "column-title",
      render: (text, _record) => text,
    },
  ];

  const tableProps = {
    scroll: { x: "max-content" }, // Enable horizontal scrolling for the table
  };

  return (
    <div className="nomineelist-layout">
      <p className="color-white" style={{ fontSize: "2rem", color: "#123962" }}>
        Admin Panel
      </p>
      <div className="table-container" style={{ marginTop: "2rem" }}>
        <Table
          columns={columns}
          dataSource={nomnom}
          pagination={false}
          {...tableProps}
        />
      </div>
    </div>
  );
}
