import "../css/style.css";

import React, { useEffect, useState } from "react";

import { signInAdmin } from "../services/adminSignIn";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actionUserLog } from "../store/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const AdminCard = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const verifyAdmin = async () => {
    try {
      axios
        .post("/api/users/verifyAdmin", {
          user: name,
          password: password,
        })
        .then((res) => {
          console.log(res);
          if (res.data == true) {
            navigate("/admin", { state: { auth: true } });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: "Error logging in.",
      description: message,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("");
    verifyAdmin();
  };
  function handleNameChange(event) {
    setName(event.currentTarget.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.currentTarget.value);
  }

  return (
    <div className="login-card">
      <div className="">
        <div className="w3a-modal__header">
          <div className="w3a-header">
            <div>
              <div className="w3a-header__title">Welcome Admin</div>
              <div className="w3a-header__title">Enter your credentials</div>
            </div>
          </div>
        </div>

        <div className="w3a-modal__content">
          <div className="w3ajs-email-passwordless w3a-group w3a-group--email">
            <form
              className="w3ajs-email-passwordless-form"
              onSubmit={handleSubmit}
            >
              <input
                className="w3a-text-field"
                type="text"
                name="Name"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
              />
              <input
                className="w3a-text-field"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />

              <button
                className="register-button"
                type="submit"
                onClick={() => {
                  const data = { name: name, password: password };
                  verifyAdmin();
                }}
              >
                Verify
              </button>

              {contextHolder}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
