import { Button, Table } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetClaimsList } from "../store/slices/accountSlice";

export default function Claims() {
  const user = useSelector((state) => state.account.user);
  const claimList = useSelector((state) => state.account.claimList);
  const dispatch = useDispatch();
  const claimsListArray = [];

  const columns = [
    {
      title: "Nominator Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      className: "column-title",
    },
    {
      title: "Nominator Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      className: "column-title",
    },
    {
      title: "Nominator Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      className: "column-title",
    },
    {
      title: "Verification Status",
      dataIndex: "verificationStatus",
      key: "verificationStatus",
      align: "center",
      className: "column-title",
    },
    {
      title: "",
      dataIndex: "claim",
      key: "claim",
      align: "center",
      className: "column-title",
      render: (text, _record) => {
        console.log("record: ", _record);
        return (
          <div>
            {_record.verificationStatus === "Pending" && (
              <Button style={{ color: "grey" }} disabled>
                Pending
              </Button>
            )}
            {_record.verificationStatus === "Approved" && (
              <Button style={{ color: "white", background: "#1ccc5b" }}>
                <Link
                  to="/claim/download"
                  state={{ record: _record }}
                  style={{ color: "white" }}
                >
                  Download
                </Link>
              </Button>
            )}
            {_record.verificationStatus === "Rejected" && (
              <Button type="primary" style={{ color: "white" }} danger>
                Rejected
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const data = claimList
    ? claimList.map((data, key) => {
        claimsListArray.push({
          key: key,
          name: data.nominatorName,
          email: data.nominatorEmail,
          phone: data.nominatorPhone,
          verificationStatus: data.verificationStatus,
        });
      })
    : "";
  useEffect(() => {
    if (user) {
      const userData = {
        email: user.email,
      };
      dispatch(actionGetClaimsList(userData));
      console.log("in effect: ", claimList);
    }
  }, []);

  const tableProps = {
    scroll: { x: "max-content" }, // Enable horizontal scrolling for the table
  };

  return (
    <div className="in-app-layout">
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Claims</h1>
      </div>
      <div className="page-title-desc">
        <p>
          Your already submitted claim requests will be shown here. Download the
          digital assets when verification is complete.
        </p>
      </div>

      <div className="table-container" style={{ marginTop: "2rem" }}>
        <Table
          columns={columns}
          dataSource={claimsListArray}
          pagination={false}
          {...tableProps}
        />
      </div>
    </div>
  );
}
