import { Layout, Row, Button, message } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { actionDataUpload } from "../store/slices/uploadSlice";
import SelectNominee from "../components/SelectNominee";
import { shortenAddress } from "../helpers/ShortenAddress";
import { arrayBufferToString } from "../helpers/arrayBufferToString";
import { useFirebaseAuthContext } from "../contexts/FirebaseVerification";
import { AddNominee } from "../components/AddNominee";
import { actionAddDataNominee } from "../store/slices/nomineeSlice";

export default function SocialMediaPosts() {
  let location = useLocation();
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [gotPosts, setGotPosts] = useState(false);
  const [selected, setSelected] = useState();
  const [isEncrypting, setIsEncrypting] = useState(false);
  const [isStoredInBlockchain, setisStoredInBlockchain] = useState(false);
  const [isStartedToStoreInBlockchain, setIsStartedToStoreInBlockchain] =
    useState(false);
  const [isNomineeSelected, setIsNomineeSelected] = useState(false);
  const [nomineeName, setNomineeName] = useState("");
  const [selectedPosts, setSelectedPosts] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nomineeModal, setNomineeModal] = useState(false); // This modal state is for Add nominee
  const [selectedNominee, setSelectedNominee] = useState({});

  const user = useSelector((state) => state.account.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataNomineeList = useSelector((state) => state.nominee.dataNomineeList);

  useEffect(() => {
    if (location.search.includes("code")) {
      const code = location.search.slice(6);
      console.log("code: ", code);
      try {
        axios
          .post("/api/users/getInstagramLongLivedToken", {
            code: code,
          })
          .then((res) => {
            console.log("res: ", res.data.longLivedToken);
            localStorage.setItem(
              "instagramLongLivedToken",
              JSON.stringify(res.data.longLivedToken)
            );
            getPosts();
          });
      } catch (err) {
        console.log("err: ", err);
      }
    }
  }, [location]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = () => {
    if (localStorage.getItem("instagramLongLivedToken")) {
      const longLivedToken = JSON.parse(
        localStorage.getItem("instagramLongLivedToken")
      );
      const accessToken = longLivedToken.access_token;
      try {
        axios
          .post("/api/users/getInstagramPosts", { code: accessToken })
          .then((res) => {
            console.log("res: ", res.data);
            setInstagramPosts(res.data);
            setGotPosts(true);
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  const handlePostClick = (post) => {
    setSelectedPosts((prevSelectedPosts) => {
      if (prevSelectedPosts.includes(post)) {
        return prevSelectedPosts.filter((p) => p.id !== post.id);
      } else {
        return [...prevSelectedPosts, post];
      }
    });
  };

  const divOnClick = (nomineeId, nomineeName) => {
    console.log("nominee Id", nomineeId);
    setSelected(nomineeId);
    setNomineeName(nomineeName);
    setIsNomineeSelected(true);
  };

  const handleClick = () => {
    window.open(
      "https://api.instagram.com/oauth/authorize?client_id=590763955925678&redirect_uri=https://localhost:3000/socialMediaPosts&scope=user_profile,user_media&response_type=code",
      "_self"
    );
  };

  const mapToSelect = dataNomineeList ? (
    dataNomineeList.map((data, key) => {
      const nomineeId = shortenAddress(data.nomineeId);
      const nomineeName = data.nomineeName;
      const nomineeEmail = data.nomineeEmail;
      const nomineePhone = data.nomineePhone;

      return (
        <div
          key={key}
          onClick={
            () => divOnClick(data.nomineeId, data.nomineeName)
            // setSelectedNominee((prev) => ({ ...prev, [key]: !selectedNominee[key] }))
          }
        >
          <Row className="f-se">
            <SelectNominee
              nomineeId={nomineeId}
              nomineeName={nomineeName}
              nomineeEmail={nomineeEmail}
              nomineePhone={nomineePhone}
              isSelected={selectedNominee[key]}
            />
          </Row>
        </div>
      );
    })
  ) : (
    <div>
      <h1 className="f-l-2">No Nominee Found</h1>
    </div>
  );
  const openUploadDocs = () => {
    setIsNomineeSelected(false);
    navigate("/choose");
  };

  const handleSubmit = async () => {
    console.log(selectedPosts);
    await uploadToIPFS();
  };

  const addNomineeModal = () => {
    setNomineeModal(!nomineeModal);
  };

  const uploadToIPFS = async () => {
    if (selectedPosts.length > 0) {
      if (selected) {
        setIsModalOpen(true);
        let nomineeSelected = null;

        for (let nominee in dataNomineeList) {
          console.log("nominee: ", nominee);
          if (dataNomineeList[nominee].nomineeId === selected) {
            nomineeSelected = dataNomineeList[nominee];
            console.log("nom: ", nomineeSelected);
          }
        }

        const data = {
          filesData: JSON.stringify(selectedPosts),
          folderName: `${user.email}${nomineeSelected.nomineeEmail}igPosts`,
          nomineeId: selected,
          nominee: nomineeSelected,
          user: user,
          filesNames: "igPosts",
        };

        let response = null;
        let responseData;

        try {
          const token = sessionStorage.getItem("accessToken");
          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let tempPercent = Math.floor((loaded * 100) / total);
              console.log("percentage value is", tempPercent);
              if (tempPercent === 100) {
                setIsEncrypting(true);
              }
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          response = await axios.post(
            "/api/users/uploadCredentialFiles",
            data,
            options
          );
          console.log("response in upload files: ", response.data);
          response = response.data;
          responseData = {
            cid: response.cid,
            securityKey: response.securityKey,
            uploadedStatus: true,
            uploadError: null,
          };
          setisStoredInBlockchain(true);
        } catch (err) {
          if (err.response) {
            console.log("err: ", err.response.data);
            responseData = {
              cid: null,
              securityKey: null,
              uploadedStatus: false,
              uploadError: err.response.data,
            };
          }
          console.log("only err:", err);
        }

        // dispatch(actionDataUpload(responseData))
        setIsStartedToStoreInBlockchain(true);
      } else {
        console.log("Not Selected Nominee");
      }
    } else {
      message.warning("No files selected");
      console.log("No Files Selected");
    }
  };

  const instagramPostsList = instagramPosts.map((post, index) => {
    return (
      <div
        key={post.id}
        onClick={() => handlePostClick(post)}
        className="instagram-card"
        style={{
          background: selectedPosts.includes(post) ? "#e1e8f5" : "white",
          zIndex: selectedPosts.includes(post) ? 100 : 0,
        }}
      >
        <img
          style={{
            width: "100%",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          src={post.media_url}
          alt={post.caption}
        />
        <div className="instagram-card-description">
          <p>{post.caption}</p>
        </div>
      </div>
    );
  });

  const onFinishAddNominee = (values) => {
    const accessToken = sessionStorage.getItem("accessToken");
    let nomineeData = {
      name: values.user.name,
      email: user.email,
      nomineeEmail: values.user.email,
      number: values.user.number,
      authorization: accessToken,
    };
    message.success(`Nominee added successfully`);
    dispatch(actionAddDataNominee(nomineeData));
    setNomineeModal(false);
  };

  const addNominee = (e) => {
    console.log("e: ", e);
  };
  return (
    <div className="upload-layout page-name">
      {!isNomineeSelected ? (
        <div
          className="f-c"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "grid",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ justifyContent: "left" }}>
                <div style={{ fontSize: "25px" }}>Upload</div>
                <div style={{ fontSize: "18px", marginTop: "13px" }}>
                  Select your nominee(s)
                </div>
              </div>
              <div style={{ justifyContent: "right", marginTop: "auto" }}>
                <Button
                  type="primary"
                  style={{ marginLeft: "10px", background: "#123962" }}
                  className="color-white"
                  onClick={addNomineeModal}
                >
                  Add nominee
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "10px", background: "#123962" }}
                  className="color-white"
                >
                  Next
                </Button>
              </div>
            </div>

            {dataNomineeList?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  margin: "auto",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginTop: "250px", fontSize: "20px" }}>
                  No Nominees Available/Added. Add a New Nominee to get Started
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: "15px",
                    color: "#2754BA",
                    cursor: "pointer",
                  }}
                  onClick={addNomineeModal}
                >
                  + Add a New Nominee
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflowY: "hidden",
              flexWrap: "wrap",
              marginTop: "40px",
            }}
          >
            {mapToSelect}
          </div>
        </div>
      ) : (
        <>
          <div>
            <p className="f-s-2 ">Select posts here</p>
            <p style={{ marginTop: "7px" }}>Selected Nominee: {nomineeName}</p>
            <br />

            <Button
              style={{ marginTop: "15px", marginBottom: "15px" }}
              onClick={openUploadDocs}
            >
              Back
            </Button>
          </div>
          <div className="f-c">
            <Layout>
              <h1>SocialMediaPosts</h1>
              {gotPosts === false ? (
                <Button
                  className="f-s-1 info-button"
                  onClick={handleClick}
                  style={{ color: "white" }}
                >
                  Authorize Instagram
                </Button>
              ) : (
                <>
                  <div className="instagram-posts">{instagramPostsList}</div>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    className="instagram-submit"
                  >
                    Upload
                  </Button>
                </>
              )}
            </Layout>
          </div>
        </>
      )}
      <>
        <AddNominee
          nomineeModal={nomineeModal}
          setNomineeModal={setNomineeModal}
          onFinish={onFinishAddNominee}
          addNominee={addNominee}
        />
      </>
    </div>
  );
}
