import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { firebaseAuth } from "../config/Firebase";

export const signUpWithEmail = async (name, email, password, sub) => {
    try {
        const result = await createUserWithEmailAndPassword(firebaseAuth, email, password, sub)

        const displayName = result.user.displayName;
        const userEmail = result.user.email;
        const profilePic = result.user.photoURL;
        console.log("result: ", result)

        localStorage.setItem("name", displayName);
        localStorage.setItem("email", userEmail);
        localStorage.setItem("profilePic", profilePic);
        localStorage.setItem("loggedIn", "true");

        try {
            await updateProfile(firebaseAuth.currentUser, { displayName: name })
        } catch (error) {
            console.log("error: ", error)
        }

        localStorage.setItem("user", JSON.stringify(firebaseAuth.currentUser))

        return {
            status: "success",
            code: 200,
            message: "Account created successfully. Please sign in.",
            user: result.user
        }
    } catch (error) {
        console.log("error: ", error)
        return {
            status: "error",
            code: 500,
            message: error.message
        }
    }
}