import "../css/styles.css";
import "../css/custom.css";

import React, { useState, useEffect } from "react";
import { Button, message, Steps, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { useLocation } from "react-router-dom";
import OTPInput from "otp-input-react";
import { useFirebaseAuthContext } from "../contexts/FirebaseVerification";
import axios from "axios";
import { useSelector } from "react-redux";
import { axiosPostInstance } from "../utils/axiosPostInstance";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export default function NDapp() {
  const [error, setError] = useState("");
  const location = useLocation();
  const { record } = location.state;
  const [current, setCurrent] = useState(0);
  const [initVector, setInitVector] = useState();
  const [cid, setCid] = useState();
  const [showText, setShowText] = useState(false);
  const [OTPSent, setOTPSent] = useState(false);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [result, setResult] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPSentLoading, setOTPSentLoading] = useState(false);
  const [dc, setDc] = useState();
  const [regNo, setRegNo] = useState();
  const [failed, setFailed] = useState(true);
  const { setUpRecaptha } = useFirebaseAuthContext();
  const [timer, setTimer] = useState(30);

  const user = useSelector((state) => state.account.user);
  const navigate = useNavigate();

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const handleResendOTP = async (e) => {
    e.preventDefault();
    let number = user?.phone;
    if (number) {
      await getOtp(number);
      console.log("In get OTP");
    } else {
      console.log("In get OTP else");
    }

    setTimer(30); // Reset the timer to 30 seconds after clicking resend
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description: "Please upload different certificate which is clear.",
    });
  };

  const getOtp = async (e) => {
    setShowText(true);
    setOTPSentLoading(true);
    e.preventDefault();
    let number = user?.phone;
    console.log(user);
    console.log("Clicked: ", number);
    try {
      const response = await setUpRecaptha(number);
      setResult(response);
      setOTPSent(true);
      console.log("response: ", response);
      message.success(`OTP send to ${number}.`);
      setOTPSentLoading(false);
    } catch (err) {
      console.log("err: ", err);
      setError(err.message);
    }
  };

  const verifyOtp = async (e) => {
    setOTPVerified(true);
    setFailed(true);
    e.preventDefault();
    setError("");
    if (OTP === "" || OTP === null) return;
    try {
      await result.confirm(OTP);
      message.success(`OTP Verification Done.`);
      setOTPVerified(false);
      setTimeout(() => {
        next();
      }, 2000);
    } catch (err) {
      setError(err.message);
      message.error(`OTP Verification Failed.`);
      setOTPVerified(false);
    }
  };

  const props = {
    name: "file",
    customRequest: dummyRequest,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        let filesData = {
          name: info.file.originFileObj.name,
          buffer: info.file.originFileObj,
          type: info.file.type,
        };
        setDc(filesData);
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file selected successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const verifyDC = async () => {
    setOTPSentLoading(true);

    if (!dc) {
      message.error("Please select DC");
      setOTPSentLoading(false);
      return;
    }
    var filesTemp = [];

    const content = dc.buffer;
    const arrayBuffer = await content.arrayBuffer();
    const buf = new Buffer.from(arrayBuffer);

    let temp = {
      name: "name" + ".zip",
      buffer: buf,
    };
    filesTemp.push(temp);
    const data = {
      filesData: filesTemp,
      name: record.name,
    };
    const data2 = {
      filesData: filesTemp,
      name: record.name,
      email: record.email,
    };
    console.log("record is here:", record);

    let response, upload;
    try {
      const token = sessionStorage.getItem("accessToken");

      upload = await axiosPostInstance("/api/users/addDeathCert", token, data2); //upload.data.url
      response = await axiosPostInstance("/api/users/test", token, data);
    } catch (e) {
      setOTPSentLoading(false);
      console.log("error in dc upload: ", e);
      message.error(e.response.data, 15);
      return;
    }

    setRegNo(response.data.reg);

    if (response.data.reg) {
      const dcData = {
        nominator: record,
        nominee: user,
        dc: response.data.reg,
        dclink: upload.data.url,
      };
      let claimRes;
      try {
        const token = sessionStorage.getItem("accessToken");

        claimRes = await axiosPostInstance("/api/users/addDc", token, dcData);
        console.log("claimRes: ", claimRes);
      } catch (e) {
        console.log("error in dc upload: ", e);
        if (e.response.status === 400) {
          message.error("Error in uploading DC");
          setOTPSentLoading(false);
          return;
        }
        if (e.response.status === 401) {
          message.error(e.response.data.error);
          setOTPSentLoading(false);
          return;
        }
      }
      message.success("DC uploaded successfully");

      let nominatorReferees = await axiosPostInstance(
        "/api/users/getReferees",
        sessionStorage.getItem("accessToken"),
        {
          email: user.email,
          nominatorEmail: record.email,
        }
      );
      nominatorReferees = nominatorReferees.data.referees;
      console.log("nominatorReferees: ", nominatorReferees);

      const smsData = [];
      try {
        for (let i = 0; i < nominatorReferees.length; i++) {
          smsData.push({
            phone: nominatorReferees[i].refereePhone,
            message: `Dear ${nominatorReferees[i].refereeName}, ${record.name} had nominated you as a referee in their datanominee account. Someone has uploaded a death certificate for ${record.name}. Please visit https://datanominee.com/vote?id=${claimRes.data.claim._id} to contact us if you find this claim as false.`,
          });
        }
      } catch (err) {
        console.log("err in for loop sendsms push: ", err);
      }

      try {
        const accessToken = sessionStorage.getItem("accessToken");
        console.log(smsData);
        console.log("accessToken: ", accessToken.slice(0, 10));
        const res = await axiosPostInstance("/api/users/sendSms", accessToken, {
          data: smsData,
          email: user.email,
        });
        console.log("res: ", res);
      } catch (e) {
        console.log(e);
      }

      setTimeout(() => {
        navigate("/pending", {
          state: {
            record: record,
            cid: cid,
          },
        });
      }, 1000);
    } else {
      openNotificationWithIcon("error", "DC not valid");
    }
    setOTPSentLoading(false);
  };

  const onChangeInitVector = (e) => {
    setInitVector(e.target.value);
  };

  const steps = [
    {
      title: "Identity Verification",
      description: "Identity verification through OTP",
      content: (
        <div className="fc">
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            OTP will be sent to {user?.phone}
          </div>
          <div>Verify your Identity with Otp</div>
          {showText ? (
            <div
              className="otpDiv"
              style={{ textAlign: "center", alignContent: "center" }}
            >
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
            </div>
          ) : null}
          <br></br>
          {!OTPSent ? (
            <Button
              className="f-s-1 info-button"
              type="primary"
              id="get-Otp-button"
              onClick={getOtp}
              style={{ color: "white" }}
              loading={OTPSentLoading}
            >
              Get OTP
            </Button>
          ) : (
            <>
              <Button
                className="f-s-1 info-button"
                type="primary"
                onClick={verifyOtp}
                style={{ color: "white" }}
                loading={OTPVerified}
              >
                Verify OTP
              </Button>
              <div id="get-Otp-button"></div>
              <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {timer > 0 ? (
                  <Button disabled>{`Resend OTP (${timer}s)`}</Button>
                ) : (
                  <Button onClick={handleResendOTP}>Resend OTP</Button>
                )}
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      title: "Upload DC",
      content: (
        <div>
          <div>
            <div>
              <p>Upload Death Certificate below for verification</p>

              <br />
              <Upload {...props} maxCount={1} showUploadList={false}>
                <Button className="info-button" icon={<UploadOutlined />}>
                  Click to Upload (1 file)
                </Button>
              </Upload>
              <br />
              {dc ? "File name: " + dc.name : ""}
              <br />
              <div style={{ paddingTop: "1rem" }}>
                <Button
                  className="info-button"
                  loading={OTPSentLoading}
                  onClick={verifyDC}
                  type="primary"
                >
                  Verify
                </Button>
              </div>
              <div style={{ fontWeight: 800 }}>
                {regNo ? "Registration number: " + regNo : ""}
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <div className="nominee-process">
      <Steps
        direction="horizontal"
        size="small"
        current={current}
        items={steps}
      />

      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
}
