import ChoiceCard from "../components/ChoiceCard";
import vehicles from "../images/vehicles.svg";
import liveStock from "../images/liveStock.svg";
import machines from "../images/machines.svg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
const cardData = [
  {
    image: vehicles,
    heading: "Vehicles",
    route: "/upload",
  },
  {
    image: liveStock,
    heading: "Livestock",
    route: "/upload",
  },
  {
    image: machines,
    heading: "Machines",
    route: "/upload",
  },
];

export default function PhysicalAssets() {
  const navigate = useNavigate();

  return (
    <div className="in-app-layout">
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Physical Assets</h1>
      </div>
      <div style={{ float: "right" }}>
        <Button
          type="primary"
          style={{ marginLeft: "10px", background: "rgb(18, 57, 98)" }}
          className="color-white"
          onClick={() => {
            navigate("/choose");
          }}
        >
          Back
        </Button>
      </div>
      <div className="page-title-desc">
        <p>Select your Type of Physical Asset</p>
      </div>
      <div
        className="row"
        style={{ marginTop: "40px", display: "flex", flexWrap: "wrap" }}
      >
        {cardData.map((card, index) => (
          <ChoiceCard
            key={index}
            image={card.image}
            heading={card.heading}
            desc={card.desc}
            route={card.route}
            square={true}
          />
        ))}
      </div>
    </div>
  );
}
