import "react-phone-number-input/style.css";

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Steps,
  Checkbox,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionUserAddReferees } from "../store/slices/accountSlice";
import { useFirebaseAuthContext } from "../contexts/FirebaseVerification";
import OTPInput from "otp-input-react";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import microphone from "../images/microphone.png";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { axiosPostInstance } from "../utils/axiosPostInstance";
import "../../src/css/styles.css";

const layout = {
  // labelCol: {
  //     span: 8,
  // },
  // wrapperCol: {
  //     span: 16,
  // },
};

export const Info = () => {
  const dispatch = useDispatch();
  const profileSetup = useSelector((state) => state.account.profileSetup);
  const user = useSelector((state) => state.account.user);

  const navigate = useNavigate();

  const [OTPSent, setOTPSent] = useState(false);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [result, setResult] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPSentLoading, setOTPSentLoading] = useState(false);
  const [resendOTPDisabled, setResendOTPDisabled] = useState(false);
  const [error, setError] = useState("");
  const [current, setCurrent] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberEmpty, setPhoneNumberEmpty] = useState(false);
  const [phoneNumberNotValid, setPhoneNumberNotValid] = useState(false);
  const { setUpRecaptha } = useFirebaseAuthContext();
  const [isRecording, setIsRecording] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const mediaRecorderRef = useRef(null);
  const intervalRef = useRef(null);
  const [sub, setSub] = useState(true);
  const [timer, setTimer] = useState(30);

  const onChange = (e) => {
    setSub(!e.target.checked);
    console.log(sub);
  };

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  useEffect(() => {
    console.log("ProfileSetup Info: ", profileSetup);
    if (profileSetup) {
      navigate("/dashboard");
    }
  }, [profileSetup]);

  useEffect(() => {
    const loggedIn = checkLoggedInLocalStorage();
    console.log("loggedIn Info: ", loggedIn);
    if (!loggedIn) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (phoneNumberEmpty || phoneNumberNotValid) {
      setPhoneNumberEmpty(false);
      setPhoneNumberNotValid(false);
    }
  }, [phoneNumber]);

  const handleResendOTP = async (e) => {
    e.preventDefault();
    console.log("phoneNumber state: ", phoneNumber);
    if (phoneNumber) {
      if (isValidPhoneNumber(phoneNumber)) await getOtp(phoneNumber);
      else setPhoneNumberNotValid(true);
      console.log("In get OTP");
    } else {
      setPhoneNumberEmpty(true);
      console.log("In get OTP else");
    }

    setTimer(30); // Reset the timer to 30 seconds after clicking resend
  };

  const handlePhoneVerification = async () => {
    let profileData = {
      phoneNumber: phoneNumber,
      email: user.email,
    };
    try {
      const token = sessionStorage.getItem("accessToken");

      const response = await axiosPostInstance(
        "/api/users/info",
        token,
        profileData
      );
      if (response.status !== 200) {
        message.error(response.error);
      }
    } catch (err) {
      console.log("err: ", err.response.data);
      message.error(err.response.data);
    }
  };

  const handlePhoneNumberSubmit = async (e) => {
    e.preventDefault();
    console.log("phoneNumber state: ", phoneNumber);
    if (phoneNumber) {
      if (isValidPhoneNumber(phoneNumber)) await getOtp(phoneNumber);
      else setPhoneNumberNotValid(true);
      console.log("In get OTP");
    } else {
      setPhoneNumberEmpty(true);
      console.log("In get OTP else");
    }
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const getOtp = async (number) => {
    setOTPSentLoading(true);
    console.log("Clicked: ", number);
    const response = await setUpRecaptha(number);
    setResult(response);
    setOTPSent(true);
    console.log("response: ", response);
    message.success(`OTP sent to ${number}.`);
    setOTPSentLoading(false);
    // } catch (err) {
    //   console.log("err: ", err);
    //   setError(err.message);
    //   message.error(`error: ${err.message}`);
    //   setOTPSentLoading(false);
    // }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (OTP === "" || OTP === null) return;
    try {
      setOTPVerified(true);
      await result.confirm(OTP);
      message.success(`OTP Verification Done.`);
      setOTPVerified(false);
      handlePhoneVerification();
      setTimeout(() => {
        next();
      }, 1500);
    } catch (err) {
      setError(err.message);
      message.error(`OTP Verification Failed.`);
      setOTPVerified(false);
    }
  };

  const handleRefereeSubmit = async (values) => {
    console.log("values: ", values);
    const refereeData = values.referees;
    const dataToSend = {
      email: user.email,
      referees: refereeData,
    };
    console.log("refereeData: ", dataToSend);
    setTimeout(() => {
      console.log("refereeData: ", dataToSend);
    }, 1000);
    dispatch(actionUserAddReferees(dataToSend));
    console.log("ProfileSetup Info: ", profileSetup);
  };

  const checkLoggedInLocalStorage = () => {
    return localStorage.getItem("loggedIn") === "true";
  };

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
    mediaRecorderRef.current = mediaRecorder;
    let recordedChunks = [];

    mediaRecorder.addEventListener("dataavailable", (e) => {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    });

    mediaRecorder.addEventListener("start", () => {
      setIsRecording(true);
      setSeconds(0);
      intervalRef.current = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    });

    mediaRecorder.addEventListener("stop", () => {
      setIsRecording(false);
      clearInterval(intervalRef.current);
      const blob = new Blob(recordedChunks, { type: "audio/webm" });
      handleSubmit(blob);
    });

    mediaRecorder.start();
  };

  const handleStopRecording = () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) return;

    mediaRecorder.stop();
  };

  const handleSubmit = async (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.webm");
    const accessToken = sessionStorage.getItem("accessToken");
    const audioFile = formData.get("audio");
    const reader = new FileReader();
    reader.readAsArrayBuffer(audioFile);
    reader.onload = async () => {
      const buffer = Buffer.from(reader.result);

      let temp = {
        name: user.name + "-voice.webm",
        buffer: buffer,
      };
      let filesTemp = [temp];

      const data = {
        audio: filesTemp,
        email: user.email,
      };
      axiosPostInstance("/api/users/addVoice", accessToken, data)
        .then((response) => {
          response.status === 200
            ? message.success("Audio file uploaded successfully")
            : message.error("Error uploading audio file");
          setTimeout(() => {
            next();
          }, 1500);
        })
        .catch((err) => {
          console.error("Error uploading audio file: ", err);
          message.error("Error uploading audio file: ", err);
        });
    };
  };

  const steps = [
    {
      title: "Phone Verification",
      description: "Verify Phone Number through OTP",
      content: (
        <div className="steps-content">
          <div className="phone-input-description ">
            An OTP will be Sent via SMS to Verify your Phone Number
          </div>
          <div className="otpDiv">
            {!OTPSent ? (
              <div className="phone_number">
                <PhoneInput
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
                <br />
                <Checkbox onChange={onChange}>
                  Subscribe to the receive updates to mobile phone
                </Checkbox>

                <div>
                  {phoneNumberEmpty ? (
                    <p style={{ color: "red" }}>Phone number cannot be empty</p>
                  ) : (
                    ""
                  )}
                  {phoneNumberNotValid ? (
                    <p style={{ color: "red" }}>
                      Phone number cannot be InValid
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div>
                <br />
                <h3>OTP has been sent to {phoneNumber}</h3>
                <a
                  onClick={() => {
                    setOTPSent(false);
                  }}
                >
                  (Not your number? Change it here)
                </a>
                <br />
                <br />
                <OTPInput
                  className="otpinput"
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  inputStyles={{ width: "30px" }}
                  inputClassName="otpinputbox"
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
                <br />
              </div>
            )}
          </div>

          <br />
          <br />

          {/*<ResendOTP onResendClick={() => console.log("Resend clicked")}/>*/}
          {!OTPSent ? (
            <Button
              className="f-s-1_3 info-button color-white"
              type="primary"
              id="get-Otp-button"
              onClick={handlePhoneNumberSubmit}
              loading={OTPSentLoading}
            >
              Get OTP
            </Button>
          ) : (
            <>
              <Button
                className="f-s-1 info-button"
                type="primary"
                onClick={verifyOtp}
                loading={OTPVerified}
                style={{ color: "white" }}
              >
                Verify OTP
              </Button>
              <div id="get-Otp-button"></div>
              <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {timer > 0 ? (
                  <Button disabled>{`Resend OTP (${timer}s)`}</Button>
                ) : (
                  <Button onClick={handleResendOTP}>Resend OTP</Button>
                )}
              </div>
            </>
          )}
        </div>
      ),
    },
    // {
    //     title: "Voice Recognition",
    //     description: "Record Your Voice For Verification",
    //     content: (
    //         <div
    //             className=""
    //             style={{
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 height: "100%",
    //                 margin: "auto",
    //                 minHeight: "300px",
    //                 marginTop: "16px",
    //                 border: "1px dashed #e9e9e9",
    //                 backgroundColor: "#e1e8f5",
    //                 color: "#123962",
    //                 fontSize: "20px",
    //             }}
    //         >
    //             <img
    //                 onClick={!isRecording ? handleStartRecording : undefined}
    //                 src={microphone}
    //                 alt="microphone pic"
    //                 style={{
    //                     height: "80px",
    //                     width: "80px",
    //                     marginLeft: "auto",
    //                     marginRight: "auto",
    //                     cursor: "pointer",
    //                     marginTop: "40px",
    //                     opacity: isRecording ? 0.5 : 1,
    //                 }}
    //             />
    //             <button onClick={handleStopRecording} disabled={!isRecording}
    //                 style={{
    //                     height: "40px",
    //                     width: "100px",
    //                     marginLeft: "auto",
    //                     marginRight: "auto",
    //                     marginTop: "40px",
    //                     opacity: isRecording ? 1 : 0.5,
    //                     backgroundColor: "#123962",
    //                     color: "white",
    //                     borderRadius: "5px",
    //                 }}
    //             >
    //                 Stop
    //             </button>
    //             {isRecording ? (
    //                 <div style={{ marginTop: '10px', textAlign: 'center' }}>
    //                     Recording for {seconds} seconds
    //                 </div>
    //             ) : (
    //                 <>
    //                     <div style={{ marginTop: '10px', textAlign: 'center' }}>
    //                         Click to Start Recording Your Voice
    //                     </div>
    //                     <div style={{ textAlign: "center" }}>
    //                         This will be used as an asset to recognise your existense in life.
    //                     </div>
    //                     <div style={{ textAlign: "center" }}>
    //                         You can skip this step if you don't want to record your voice.
    //                         <br />
    //                         <button onClick={next} className="skip-btn">
    //                             Skip
    //                         </button>
    //                     </div>
    //                 </>
    //             )}
    //         </div>
    //     ),
    // },
    {
      title: "Trustees",
      description: "Add upto 3 Trustees",
      content: (
        <div style={{ backgroundColor: "white", color: "#123962" }}>
          <h3>
            Trustees are your TRUSTED PEOPLE you can rely on to confirm your
            passing and support your LOVED ONES in navigating the recovery of
            your digital assets.
          </h3>
          <Form {...layout} name="basic" onFinish={handleRefereeSubmit}>
            <Form.List
              name={"referees"}
              initialValue={[
                {
                  name: "",
                  email: "",
                  phoneNumber: "",
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center",
                      }}
                    >
                      <div className="credentials-card" key={key}>
                        <Row className="credentials-card-row" gutter={32}>
                          <Col span={12}>
                            <Form.Item
                              style={{ width: "100%" }}
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing name",
                                  type: "string",
                                },
                              ]}
                            >
                              <Input
                                rootClassName="ref-input"
                                placeholder={"Enter Trustee Name"}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              style={{ width: "100%" }}
                              name={[name, "email"]}
                              rules={[
                                {
                                  type: "email",
                                  required: true,
                                  message: "Missing email",
                                },
                              ]}
                            >
                              <Input
                                rootClassName="ref-input"
                                placeholder={"Enter Trustee Email"}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              style={{ width: "100%" }}
                              name={[name, "phoneNumber"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing phone number",
                                  type: "text",
                                  // type: "regexp",
                                  // pattern: new RegExp("/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/"),
                                },
                              ]}
                            >
                              <PhoneInput
                                required
                                defaultCountry="IN"
                                placeholder="Enter phone number"
                              />
                              {/* <Input
                                                                rootClassName="ref-input"
                                                                placeholder={"Enter Referee Phone Number"}
                                                            /> */}
                            </Form.Item>

                            {/* <div>
                                                            {phoneNumberEmpty ? (
                                                                <p style={{ color: "red" }}>Phone number cannot be empty</p>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {phoneNumberNotValid ? (
                                                                <p style={{ color: "red" }}>
                                                                    Phone number cannot be InValid
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div> */}
                          </Col>
                        </Row>
                      </div>
                      {fields.length > 1 && (
                        <div
                          onClick={() => remove(name)}
                          style={{ cursor: "pointer" }}
                        >
                          <MinusCircleOutlined
                            style={{
                              fontSize: "25px",
                              margin: "10px",
                              color: "#123962",
                            }}
                          />
                          <p style={{ color: "#123962" }}>Remove</p>
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Col>
                      <Row>
                        <Form.Item style={{ alignItems: "center" }}>
                          {fields.length < 3 && (
                            <Button
                              type="dashed"
                              style={{
                                alignItems: "center",
                                height: "50px",
                                justifyItems: "center",
                                padding: "0rem 4rem",
                              }}
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Trustee
                            </Button>
                          )}
                        </Form.Item>
                      </Row>

                      <Row>
                        <Form.Item>
                          <Button
                            style={{ fontSize: "1.2rem", fontWeight: 300 }}
                            className="info-button"
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Row>
                    </Col>
                  </div>
                </>
              )}
            </Form.List>

            {/* <Form.Item>
                            <Button
                                style={{ fontSize: "1.2rem", fontWeight: 300 }}
                                className="info-button"
                                type="primary"
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </Form.Item> */}
          </Form>
        </div>
      ),
    },
  ];

  return (
    <div style={{ overflowX: "hidden" }}>
      {/* <div style={{ width: "100%", margin: "30px 50px", marginRight:"0px" }}>
        <img alt="logo" src={logo} />
      </div> */}
      <div
        style={{
          width: "100%",
          margin: "25px 50px",
          marginRight: "0px",
        }}
      >
        {/* <div className="welcome f-d-3 f-w-200" style={{ width: "80%" }}>
          Welcome to Data Nominee
        </div>
        <p className="f-l-1_3 f-w-300" style={{ width: "80%" }}>
          A platform to safely pass your goods to ur loved and needy ones after
          you pass away.
        </p> */}
      </div>
      <h1 className="f-s-1_8 f-w-500" style={{ paddingLeft: "80px" }}>
        Complete your profile setup
      </h1>
      <div className="f-c" style={{ marginTop: "0.5rem" }}>
        <Card style={{ width: "80%", borderRadius: "30px", border: "none" }}>
          <br />
          <div className="nominee-process">
            <Steps
              direction="horizontal"
              size="small"
              current={current}
              items={steps}
            />
            <div className="">{steps[current].content}</div>
          </div>
        </Card>
      </div>
    </div>
  );
};
