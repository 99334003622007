import React from "react";
import check from "../images/check.svg";
import { Button, Card, Col, Row } from "antd";

export default function PlanCard(props) {
  return props.planName === "Gold Plan" || props.planName === "Gold" ? (
    <div className="planCardDiv">
      <Card>
        <Row>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Card style={{ backgroundColor: "#123962" }}>
              <p style={{ color: "#fff", fontSize: "1.8rem" }}>
                {props.planName}
              </p>
              <p style={{ color: "#fff" }}>Recommended</p>
              <p style={{ color: "#fff" }}>{props.amount}/Month</p>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="">
            <ul style={{ textAlign: "left" }}>
              {props.features.map((feature, index) => (
                <li key={index}>
                  <img src={check} /> {feature}
                </li>
              ))}
            </ul>
          </div>
        </Row>
        <Row
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={props.handleClick}>
              {props.buttonText}
            </Button>
          </div>
        </Row>
      </Card>
    </div>
  ) : props.planName === "Life Time" || props.planName === "Life" ? (
    <div className="planCardDiv">
      <Card>
        <Row>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Card style={{ backgroundColor: "#e1e8f5" }}>
              <p style={{ color: "black", fontSize: "1.8rem" }}>
                {props.planName}
              </p>
              <p style={{ color: "black" }}>{props.amount}</p>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="">
            <ul style={{ textAlign: "left" }}>
              {props.features.map((feature, index) => (
                <li key={index}>
                  <img src={check} /> {feature}
                </li>
              ))}
            </ul>
          </div>
        </Row>
        <Row
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={props.handleClick}>
              {props.buttonText}
            </Button>
          </div>
        </Row>
      </Card>
    </div>
  ) : (
    <div className="planCardDiv">
      <Card>
        <Row>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Card style={{ backgroundColor: "#e1e8f5" }}>
              <p style={{ color: "black", fontSize: "1.8rem" }}>
                {props.planName}
              </p>
              <p style={{ color: "black" }}>Free</p>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="" style={{ height: "auto" }}>
            <ul style={{ textAlign: "left" }}>
              {props.features.map((feature, index) => (
                <li key={index}>
                  <img src={check} /> {feature}
                </li>
              ))}
            </ul>
          </div>
        </Row>
        <Row
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Button type="primary" onClick={props.handleClick}>
              {props.buttonText}
            </Button>
          </div>
        </Row>
      </Card>
    </div>
  );
}
