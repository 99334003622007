import { routes } from "./routes";

export const signedOutNavLinks = [
  {
    name: "About",
    route: "#down",
  },
  {
    name: "Contact Us",
    route: "mailto:datanominee@datanominee.com",
  },
];
