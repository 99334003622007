export const shortenAddress = (address) => `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;

export const shortenName = (name) => `${name.slice(0, 12)}...`;


export const shortenEmail = email => {
    const [name, domain] = email.split("@");
    var shortenName = name
    if (name.length > 20) {
        shortenName = shortenAddress(name)
    }
    return `${shortenName}@${domain}`
    //return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
};
