import Navbar from "./Components/Navbar";
import FooterSection from "./Components/FooterSection";
import LandingPage from "./Pages/LandingPage";

function Landing() {
  return (
    <div className="App">
      <Navbar />
      <LandingPage />
      <FooterSection />
    </div>
  );
}

export default Landing;
