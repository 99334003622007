import React from "react";
import { useState } from "react";
import { Button, List, Modal } from "antd";

export default function FileListModal(props) {
  const [visible, setVisible] = useState(false);
  const [files, setFiles] = useState([]);

  const record = props.record;

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button onClick={showModal}>See Files</Button>
      <Modal
        centered
        width={420}
        open={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="f-s-1_3 f-w-600">Uploaded Files</p>
        <List
          size="small"
          dataSource={record.files}
          renderItem={(item) => (
            <List.Item className="f-s-1">{item.name}</List.Item>
          )}
          style={{ borderRadius: "10px" }}
        />
      </Modal>
    </div>
  );
}
