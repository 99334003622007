// import './index.css';

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";

import Dapp from "./Dapp";
import { FirebaseAuthContextProvider } from "./contexts/FirebaseVerification";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <FirebaseAuthContextProvider>
      <React.StrictMode>
        <Router>
          <Dapp />
        </Router>
      </React.StrictMode>
    </FirebaseAuthContextProvider>
  </Provider>
);
