import React from "react";
import src1 from "../../src/landing/assets/images/taxi.png";

export default function Error() {
  return (
    <div style={{ paddingTop: "10%" }}>
      <h1 style={{ textAlign: "center", fontFamily: "Inter" }}>
        You are lost!!
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          height: "100%",
        }}
      >
        <br />
        <a href="/">
          {" "}
          <img style={{ width: "350px" }} src={src1} alt="taxi"></img>{" "}
        </a>
        <br />
      </div>
      <h1 style={{ textAlign: "center", fontFamily: "Inter" }}>
        Take a cab to the homepage
      </h1>
    </div>
  );
}
