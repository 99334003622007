import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Progress,
  Result,
  Modal,
  AutoComplete,
  notification,
  Switch,
  Select,
  Divider,
  Tooltip,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AddNominee } from "../components/AddNominee";
import loading from "../images/uploading.gif";
import encrypting from "../images/Card.gif";
import blockchain from "../images/blockchain.gif";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function Credentials(props) {
  const [credentials, setCredentials] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [percent, setPercent] = useState(0);
  const [isEncrypting, setIsEncrypting] = useState(false);
  const [isStoredInBlockchain, setisStoredInBlockchain] = useState(false);
  const [isStartedToStoreInBlockchain, setIsStartedToStoreInBlockchain] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nomineeName, setNomineeName] = useState("");
  const [selectedNominee, setSelectedNominee] = useState([]);
  const [selectedNominees, setSelectedNominees] = useState([]);
  const [websiteOptions, setWebsiteOptions] = useState([]);
  const [notify, setNotify] = useState(false);
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [isAddNomineeModalOpen, setIsAddNomineeModalOpen] = useState(false);

  const location = useLocation();
  const heading = location.state?.heading;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const dataNomineeList = useSelector((state) => state.nominee.dataNomineeList);
  const user = useSelector((state) => state.account.user);
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description: "Please enter someone else.",
    });
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  useEffect(() => {
    const initialOptions = [];

    if (dataNomineeList.length > 0) {
      dataNomineeList.map((data, key) => {
        initialOptions.push({
          value: key,
          label: data.nomineeName + " ( " + data.nomineeEmail + " )",
          key: key,
        });
      });
      setOptionsSelect(initialOptions);
    }
  }, [dataNomineeList]);

  useEffect(() => {
    if (dataNomineeList.length > 0) {
      const filteredNominees = dataNomineeList?.filter(
        (data, key) => selectedNominee[key]
      );

      setNomineeName(filteredNominees.map((data) => data.nomineeName));
      setSelectedNominees(filteredNominees);
    }
  }, [selectedNominee]);

  const showAddNomineeModal = () => {
    setIsAddNomineeModalOpen(true);
  };
  const handleAddNomineeCancel = () => {
    setIsAddNomineeModalOpen(false);
  };

  const handleChange = (value) => {
    console.log(value);

    setSelectedNominee(() => ({
      [value]: !selectedNominee[value],
    }));
  };

  const handleSwitch = (value) => {
    setNotify(value);
  };

  const onFinish = async (values) => {
    console.log(values);
    setCredentials(values.credentials);
    await uploadToIPFS(values.credentials);
  };

  const uploadToIPFS = async (creds) => {
    if (folderName) {
      for (let i = 0; i < user.files.length; i++) {
        if (user.files[i].folder === folderName) {
          for (let j = 0; j < selectedNominees.length; j++) {
            if (selectedNominees[j].nomineeEmail === user.files[i].nominee) {
              message.error("Folder name already exists");
              return;
            }
          }
        }
      }
      if (creds.length > 0) {
        setIsModalOpen(true);

        const data = {
          filesData: JSON.stringify(creds),
          folderName: folderName,
          nominee: selectedNominees[0],
          user: user,
          type: "credentials",
          assetType: heading,
          notifyNominee: notify,
          description: creds.notes,
          website: creds.website,
          usernameOrEmail: creds.email,
          password: creds.password,
        };

        let response = null;

        try {
          const token = sessionStorage.getItem("accessToken");
          let intervalID;
          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let tempPercent = Math.floor((loaded * 100) / total);
              console.log("percentage value is", tempPercent);
              setPercent(tempPercent);
              if (tempPercent === 100) {
                let bool = false;
                setIsEncrypting(true);

                intervalID = setInterval(function () {
                  setIsStartedToStoreInBlockchain(!bool);
                  setisStoredInBlockchain(bool);
                  bool = !bool;
                }, 3000);
              }
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          response = await axios.post(
            "/api/users/uploadCredentialFiles",
            data,
            options
          );

          console.log("response in upload files: ", response.data);
          response = response.data;
          setIsStartedToStoreInBlockchain(true);
          setisStoredInBlockchain(true);
          clearInterval(intervalID);
          setFolderName("");
          form.resetFields();
        } catch (err) {
          if (err.response) {
            console.log("err: ", err.response.data);
          }
          console.log("only err:", err);
        }
        setIsStartedToStoreInBlockchain(true);
      } else {
        message.warning("No files selected");
        console.log("No Files Selected");
      }
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setisStoredInBlockchain(false);
    setIsStartedToStoreInBlockchain(false);
    setIsEncrypting(false);
  };

  const websites = [
    { value: "https://www.google.com", label: "https://www.google.com" },
    { value: "https://www.facebook.com", label: "https://www.facebook.com" },
    { value: "https://www.instagram.com", label: "https://www.instagram.com" },
    { value: "https://www.twitter.com", label: "https://www.twitter.com" },
    { value: "https://www.linkedin.com", label: "https://www.linkedin.com" },
    { value: "https://www.github.com", label: "https://www.github.com" },
    {
      value: "https://www.stackoverflow.com",
      label: "https://www.stackoverflow.com",
    },
    { value: "https://www.medium.com", label: "https://www.medium.com" },
    { value: "https://www.youtube.com", label: "https://www.youtube.com" },
    { value: "https://www.netflix.com", label: "https://www.netflix.com" },
    { value: "https://www.amazon.com", label: "https://www.amazon.com" },
    { value: "https://www.flipkart.com", label: "https://www.flipkart.com" },
    { value: "https://www.snapdeal.com", label: "https://www.snapdeal.com" },
    { value: "https://www.ebay.com", label: "https://www.ebay.com" },
    { value: "https://www.paytm.com", label: "https://www.paytm.com" },
    { value: "https://www.paypal.com", label: "https://www.paypal.com" },
    { value: "https://www.coursera.com", label: "https://www.coursera.com" },
    { value: "https://www.udemy.com", label: "https://www.udemy.com" },
    { value: "https://www.udacity.com", label: "https://www.udacity.com" },
    { value: "https://www.edx.org", label: "https://www.edx.org" },
    { value: "https://www.quora.com", label: "https://www.quora.com" },
    { value: "https://www.wiki.com", label: "https://www.wiki.com" },
    { value: "https://www.wikipedia.com", label: "https://www.wikipedia.com" },
    { value: "https://www.blogger.com", label: "https://www.blogger.com" },
    { value: "https://www.wordpress.com", label: "https://www.wordpress.com" },
    { value: "https://www.tumblr.com", label: "https://www.tumblr.com" },
    { value: "https://www.pinterest.com", label: "https://www.pinterest.com" },
  ];

  const onSearch = (value) => {
    setWebsiteOptions(websites);
  };

  return (
    <div className="in-app-layout">
      <div>
        <div>
          <div className="page-title">
            <h1 style={{ margin: "0" }}>Protect your Credentials</h1>
          </div>{" "}
          <p style={{ marginTop: "7px" }}>
            Selected Nominee:{" "}
            <span style={{ fontWeight: "bold" }}>{nomineeName}</span>
          </p>
          <br />
          <Button
            className="color-white"
            style={{
              marginTop: "15px",
              marginBottom: "15px",
            }}
            type="primary"
            onClick={() => {
              navigate("/choose");
            }}
          >
            Back
          </Button>
        </div>
        <div className="">
          <div className="">
            <Row>
              <Col span={12} style={{ padding: "1rem 1rem" }}>
                <p style={{ paddingBottom: "10px" }}>
                  Name{" "}
                  <Tooltip title="Enter asset name so that nominees identify the asset.">
                    <InfoCircleOutlined />{" "}
                  </Tooltip>
                </p>
                <Input
                  className="ref-input"
                  value={folderName}
                  placeholder="Enter Asset Name"
                  onChange={(e) => {
                    setFolderName(e.target.value);
                  }}
                  style={{ background: "#e0e8f5" }}
                />
              </Col>
              <Col span={12} style={{ padding: "1rem 1rem" }}>
                <p style={{ paddingBottom: "10px" }}>
                  Select Nominee{" "}
                  <Tooltip title="Select a nominee for your asset to be transferred">
                    <InfoCircleOutlined />{" "}
                  </Tooltip>
                </p>
                <Select
                  id="folderSelectInput"
                  mode="single"
                  style={{
                    fontSize: "1.2rem",
                    borderRadius: "10px",
                    color: "rgb(0, 0, 0)",
                    width: "100%",
                    border: "1px solid rgb(157, 157, 157)",
                  }}
                  size="large"
                  placeholder="Select one nominee"
                  onChange={handleChange}
                  optionLabelProp="label"
                  options={optionsSelect}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <div>
                        <Button
                          type="text"
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                          onClick={showAddNomineeModal}
                        >
                          Add Nominee
                        </Button>
                      </div>
                    </>
                  )}
                ></Select>
              </Col>
            </Row>
          </div>
          <Form
            {...layout}
            name="form"
            form={form}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.List
              name="credentials"
              initialValue={[
                {
                  website: "",
                  email: "",
                  password: "",
                  notes: "",
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center",
                      }}
                    >
                      <div className="credentials-card" key={key}>
                        <Row>
                          <Col span={12}>
                            <p
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              Website
                            </p>
                            <Form.Item
                              {...restField}
                              name={[name, "website"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing website",
                                  type: "url",
                                },
                              ]}
                            >
                              <AutoComplete
                                options={websiteOptions}
                                onSearch={onSearch}
                              >
                                <Input
                                  rootClassName="ref-input"
                                  placeholder={"Enter Website Address"}
                                />
                              </AutoComplete>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <p
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              Email/Username
                            </p>
                            <Form.Item
                              style={{ width: "100%" }}
                              name={[name, "email"]}
                              rules={[
                                {
                                  type: "email",
                                  required: true,
                                  message: "Missing email",
                                },
                              ]}
                            >
                              <Input
                                rootClassName="ref-input"
                                placeholder={
                                  "Enter your account Email / Username"
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <p
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              Notes{" "}
                              <Tooltip title="Enter a description for your nominee(s) for them easily inherit your assets">
                                <InfoCircleOutlined />{" "}
                              </Tooltip>
                            </p>
                            <Form.Item
                              style={{ width: "100%" }}
                              name={[name, "notes"]}
                              rules={[
                                {
                                  type: "text",
                                  required: false,
                                  message: "Missing notes",
                                },
                              ]}
                            >
                              <Input.TextArea
                                style={{
                                  width: "100%",
                                  borderRadius: "8px",
                                  border: "1px solid rgb(157, 157, 157)",
                                }}
                                rows={4}
                                placeholder="Enter any notes"
                                maxLength={50}
                                className="ref-textarea"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <p
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              Password
                            </p>
                            <Form.Item
                              style={{ width: "100%" }}
                              name={[name, "password"]}
                              rules={[
                                {
                                  type: "password",
                                  required: true,
                                  message: "Missing password",
                                },
                              ]}
                            >
                              <Input.Password
                                type="password"
                                placeholder={"Enter Password"}
                                className="ref-input"
                              />
                            </Form.Item>
                            <p
                              style={{
                                paddingBottom: "10px",
                              }}
                            >
                              Notify Nominee{" "}
                              <Tooltip title="Enable, this box to let your nominees know that you have assigned them this asset">
                                <InfoCircleOutlined />{" "}
                              </Tooltip>
                            </p>
                            <Switch checked={notify} onChange={handleSwitch} />
                          </Col>
                        </Row>
                      </div>
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          style={{
                            fontSize: "25px",
                            margin: "10px",
                            color: "#123962",
                          }}
                          onClick={() => remove(name)}
                        />
                      )}
                    </div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "20px",
                    }}
                  >
                    <Form.Item style={{ alignItems: "center", width: "100%" }}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        style={{
                          alignItems: "center",
                          height: "50px",
                          justifyItems: "center",
                          width: "100%",
                        }}
                      >
                        Add a new credential
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: 300,
                          marginRight: "20px",
                          backgroundColor: "#1CCC5B",
                        }}
                        className="info-button"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                  <Modal
                    title="Creation of Asset"
                    open={isModalOpen}
                    footer={null}
                    closable={true}
                  >
                    <div>
                      {!isStartedToStoreInBlockchain ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {!isEncrypting ? (
                            <div>
                              <img
                                src={loading}
                                width={200}
                                height={200}
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                alt="percent"
                              />
                              <Progress percent={percent} />
                            </div>
                          ) : (
                            <div>
                              <img
                                src={encrypting}
                                width={200}
                                height={200}
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                alt="encrypting pic"
                              />
                              <h3
                                style={{
                                  alignText: "center",
                                }}
                              >
                                Encrypting Asset
                              </h3>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            {!isStoredInBlockchain ? (
                              <div>
                                <img
                                  src={blockchain}
                                  width={200}
                                  height={200}
                                  alt="storing "
                                />
                                <h3
                                  style={{
                                    alignText: "center",
                                  }}
                                >
                                  Storing into Blockchain
                                </h3>
                              </div>
                            ) : (
                              <div>
                                <Result
                                  status="success"
                                  title="Asset Creation Successful"
                                  extra={[
                                    <Button
                                      className="modal-btn"
                                      type="primary"
                                      key="console"
                                      onClick={handleOk}
                                    >
                                      Okay
                                    </Button>,
                                  ]}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Modal>
                </>
              )}
            </Form.List>
          </Form>
        </div>
      </div>
      <>
        <AddNominee
          isAddNomineeModalOpen={isAddNomineeModalOpen}
          handleAddNomineeCancel={handleAddNomineeCancel}
          user={user}
        />
      </>
    </div>
  );
}
