export default function getPlans(plan) {
  const monthlyPlans = [
    {
      name: "Silver Plan",
      amount: "0 INR",
      features: [
        "Secure upto 3 assets",
        "Email notification to Nominees",
        "Designate Unlimited no. of Nominees",
        "Full encryption of all data, Blockchain Protection and IPFS secure storage",
      ],
      amountToCollect: 0,
      key: "Silver",
    },
    {
      name: "Gold Plan",
      amount: "499 INR",
      features: [
        "Secure Unlimited number of Assets",
        "Documents Upload Available",
        "Email and Phone verification",
        "Unlimited number of nominees and beneficiaries",
        "Full encryption of all data, Blockchain Protection and IPFS secure storage",
      ],
      amountToCollect: 499,
      key: "Gold",
    },
  ];

  const yearlyPlan = [
    {
      name: "Life Time",
      amount: "15,999 INR",
      features: [
        "Everything in Gold Plan",
        "Lifetime Gold Account",
        "Security monitoring",
        "24x7 chat support",
      ],
      amountToCollect: 15999,
      key: "Life",
    },
  ];

  console.log("In GetPlans: ", plan);

  if (plan === "Life") {
    return yearlyPlan[0];
  } else if (plan) {
    return monthlyPlans.filter((p) => p.key === plan)[0];
  }

  return { monthlyPlans, yearlyPlan };
}
