import React from "react";
import { Avatar } from "antd";
import { DownOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Space, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../config/Firebase";
import { actionUserLogout } from "../store/slices/accountSlice";
import { useDispatch } from "react-redux";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [url, setUrl] = React.useState("");
  const [name, setName] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");

  React.useEffect(() => {
    let user;
    user = JSON.parse(localStorage.getItem("user"));
    setName(user?.displayName.split("")[0].toUpperCase());
    setDisplayName(user?.displayName);
    const photoURL = user?.photoURL;
    setUrl(photoURL);
  }, []);

  const logout = () => {
    signOut(firebaseAuth)
      .then(() => {
        // Sign-out successful.
        console.log("Sign-out successful.");
        localStorage.setItem("loggedIn", "false");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("profilePic");
        localStorage.removeItem("user");
        sessionStorage.removeItem("accessToken");
        localStorage.removeItem("state");
        dispatch(actionUserLogout());
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
        console.log("error: ", error);
      });
  };

  const handleMenuClick = (e) => {};

  const items = [
    {
      label: "Logout",
      key: "1",
      icon: <LogoutOutlined />,
      onClick: logout,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="header-css">
      <div className="avatar-menu">
        <Space style={{ right: 0, justifyContent: "right" }}>
          <Avatar
            src={url}
            style={{ backgroundColor: "#fde3cf", color: "#2654ba" }}
          >
            {name}
          </Avatar>
          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                {displayName}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      </div>
    </div>
  );
}
