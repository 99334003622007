import {Link} from "react-router-dom";
import {Button} from "antd";

export default function VerificationPending() {
    return (
        <div className="f-ser">
            <h1 style={{color:"#0f2d4a"}}>Verification Pending</h1>
            <p style={{color:"#0f2d4a", fontSize:"15px", marginTop:"0px"}}>
                Your verification is pending. It will be done in 7 days. You can
                check the status of your verification in the claims page.
            </p>
            <Button style={{color:"white"}}>
                <Link to="/claims">Claims</Link>
            </Button>
        </div>
    )
}