import { Button, Layout, Progress } from "antd";
import { Row, Col, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import filesi from "../images/files.svg";
import folderi from "../images/folder.svg";
import nomAssignedi from "../images/nomAssigned.svg";
import nChoseni from "../images/nChosen.svg";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import { actionGetNominationsList } from "../store/slices/accountSlice";
import { actionGetFiles } from "../store/slices/uploadSlice";
import { actionDataNomineeList } from "../store/slices/nomineeSlice";
import ChoiceCard from "../components/ChoiceCard";
import liquidAssets from "../images/liquidAssets.svg";
import credential from "../images/passwords.svg";
import { uploadi } from "../images";
import { nominationsi } from "../images";
import { uploadedFilesi } from "../images";

export default function Dashboard() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [premium, setPremium] = useState("false");
  const [url, setUrl] = useState("");

  const user = useSelector((state) => state.account.user);
  const claimList = useSelector((state) => state.account.claimList);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cardData = [
    {
      image: uploadi,
      heading: "Upload",
      desc: "Upload important documents like your bank account statements, insurance policies, etc.",
      route: "/choose",
    },
    {
      image: uploadedFilesi,
      heading: "Assets",
      desc: "See the assets that you have assigned to your loved-ones, you can view and delete if needed",
      route: "/UploadedFiles",
    },
    {
      image: nominationsi,
      heading: "Nominations",
      desc: "See who has nominated you as their nominee and claim thier digital assets",
      route: "/nominations",
    },
    // {
    //   image: uploadedFilesi,
    //   heading: "Files",
    //   desc: "Upload important credentials like your social media account passwords.",
    //   route: "/UploadedFiles",
    // },
  ];

  useEffect(() => {
    let user;
    user = JSON.parse(localStorage.getItem("user"));
    const photoURL = user?.photoURL;
    setUrl(photoURL);
  }, []);

  useEffect(() => {
    console.log("User Db:", user);
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPremium(user.premiumAccount);
    }

    const userData = {
      email: user.email,
    };
    dispatch(actionGetNominationsList(userData));
    dispatch(actionGetFiles(userData));
    dispatch(actionDataNomineeList(userData));
  }, [user]);

  return (
    <div className="f-c">
      <Layout>
        <div className="dashboard-top">
          <div style={{ width: "100%" }}>
            <Row>
              <Col>
                <Avatar
                  size={50}
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "#2654ba",
                    fontSize: 30,
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                  src={url}
                >
                  {user.name.split("")[0].toUpperCase()}
                </Avatar>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <h1 className="welcome-header">Hello {user?.name}</h1>
              </Col>
            </Row>
          </div>
        </div>
        {/* <div className="dashboard-top">
          <Card
            className="welcome-card"
            bordered={false}
            style={{ width: "100%" }}
          >
            <div>
              <h1 className="welcome-header">Storage Used</h1>
              You have used {user.storage.usedStorage}MBs out of{" "}
              {user.storage.totalStorage}MBs storage of your{" "}
              {user.premiumAccount} account
              <br />
              <Progress
                percent={Math.round(
                  (user.storage.usedStorage / user.storage.totalStorage) * 100
                )}
              />
              <br />
              <Button type="primary" onClick={() => navigate("/subscription")}>
                {user.premiumAccount !== "Life"
                  ? "Upgrade Plan"
                  : "Manage Subscription"}
              </Button>
            </div>
          </Card>
        </div> */}
        <div className="dashboard-top">
          <Card
            className="welcome-card"
            bordered={false}
            style={{ width: "100%" }}
          >
            <h1 className="welcome-header" style={{ paddingBottom: "10px" }}>
              Quick shortcuts
            </h1>
            <Row
              className="dashthingtodo"
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                marginBottom: "0",
              }}
            >
              {cardData.map((card, index) => (
                <ChoiceCard
                  key={index}
                  image={card.image}
                  heading={card.heading}
                  desc={card.desc}
                  route={card.route}
                  square={true}
                  colorChange={true}
                />
              ))}
            </Row>
          </Card>
        </div>
        <div>
          <Row className="f-se">
            <div className="dashboard-card">
              <Row className="f-se">
                <Col span={12}>
                  <img width={150} alt="icon" src={filesi} />
                </Col>
                <Col span={12}>
                  <p className="dashboard-number">{user?.files?.length || 0}</p>
                  <p className="dashboard-text f-w-500">
                    Total Assets Uploaded
                  </p>
                </Col>
              </Row>
            </div>
            <div className="dashboard-card">
              <Row className="f-se">
                <Col span={12}>
                  <img width={150} alt="icon" src={folderi} />
                </Col>
                <Col span={12}>
                  <p className="dashboard-number">
                    {user?.storage?.usedStorage?.toFixed(1) || 0}
                  </p>

                  <p className="dashboard-text">Total Storage Used</p>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="f-se">
            <div className="dashboard-card">
              <Row className="f-se">
                <Col span={12}>
                  <img width={150} alt="icon" src={nomAssignedi} />
                </Col>
                <Col span={12}>
                  <p className="dashboard-number">
                    {user?.dataNominee?.length || 0}
                  </p>
                  <p className="dashboard-text">Nominees Assigned</p>
                </Col>
              </Row>
            </div>
            <div className="dashboard-card">
              <Row className="f-se">
                <Col span={12}>
                  <img width={150} alt="icon" src={nChoseni} />
                </Col>
                <Col span={12}>
                  <p className="dashboard-number">{claimList?.length || 0}</p>
                  <p className="dashboard-text">Nominators Chose You</p>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </Layout>
    </div>
  );
}
