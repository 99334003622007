import "./css/styles.css";
import "./css/custom.css";

import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import LoginSignup from "./pages/LoginSignup";
import UploadFiles from "./pages/UploadFiles";
import Nominee from "./pages/Nominee";
import NDapp from "./pages/NDapp";
import Error from "./pages/Error";
import Nominations from "./pages/Nominations";
import UserLayout from "./pages/UserLayout";
import { Info } from "./pages/Info";
import Files from "./pages/Files";
import UploadedFiles from "./pages/UploadedFiles";
import SignUp from "./pages/SignUp";
import Credentials from "./pages/Credentials";
import Landing from "./landing/landing";
import SocialMediaPosts from "./pages/SocialMediaPosts";
import Choose from "./pages/Choose";
import VerificationPending from "./pages/VerificationPending";
import Claims from "./pages/Claims";
import Subscription from "./pages/Subscription";
import Navbar from "./landing/Components/Navbar";
import ContactUs from "./pages/ContactUs";
import Settings from "./pages/Settings";
import PhysicalAssets from "./pages/PhysicalAssets";
import LiquidAssets from "./pages/LiquidAssets";
import ChooseCreds from "./pages/ChooseCreds";
import ChooseNotes from "./pages/ChooseNotes";
import Notes from "./pages/Notes";
import Adminpanal from "./pages/Adminpanal";
import AdminLogin from "./pages/AdminLogin";
import Vote from "./pages/Vote";
import EditRef from "./pages/EditRef";
import { ForgotPassword } from "./pages/ForgotPassword";

////

export default function Dapp() {
  return (
    <div className="containerA">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/login"
          element={
            <>
              <Navbar />
              <LoginSignup />
            </>
          }
        />
        <Route
          path="/register"
          element={
            <>
              <Navbar />
              <SignUp />
            </>
          }
        />
        <Route
          path="/info"
          element={
            <>
              <Info />
            </>
          }
        />
        <Route
          path="/contactus"
          element={
            <>
              <Navbar />
              <ContactUs />
            </>
          }
        />

        <Route path="/admin" element={<Adminpanal />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route
          path="/vote"
          element={
            <>
              <Navbar />
              <Vote />
            </>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <>
              <Navbar />
              <ForgotPassword />
            </>
          }
        />

        <Route element={<UserLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/choose" element={<Choose />} />
          <Route path="/upload" element={<UploadFiles />} />
          <Route path="/notes" element={<Notes />} />
          <Route path="/nomineelist" element={<Nominee />} />
          <Route path="/nominations" element={<Nominations />} />
          <Route path="/claim" element={<NDapp />} />
          <Route path="/editreferee" element={<EditRef />} />
          <Route path="/claims" element={<Claims />} />
          <Route path="/claim/download" element={<Files />} />
          <Route path="/UploadedFiles" element={<UploadedFiles />} />
          <Route path="/credentials" element={<Credentials />} />
          <Route path="/pending" element={<VerificationPending />} />

          <Route path="/socialMediaPosts" element={<SocialMediaPosts />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="physicalAssets" element={<PhysicalAssets />} />
          <Route path="liquidAssets" element={<LiquidAssets />} />
          <Route path="/chooseCredentials" element={<ChooseCreds />} />
          <Route path="/chooseNotes" element={<ChooseNotes />} />
        </Route>
        <Route path="/*" element={<Error />} />
      </Routes>
    </div>
  );
}
