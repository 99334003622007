import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPostInstance } from "../../utils/axiosPostInstance";
import axios from "axios";
export const actionDataNomineeList = createAsyncThunk(
  "nominee/actionDataNomineeList",
  async (props) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log("props: ", props);
      response = await axiosPostInstance(
        "/api/users/nomineeList",
        token,
        props
      );
      // console.log("response: ", response);
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
    // console.log("The resulting nominee list is :", response.data);
    return { response: response.data };
  }
);

export const actionAddDataNominee = createAsyncThunk(
  "nominee/actionAddDataNominee",
  async (props, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance("/api/users/addnominee", token, props);
      // console.log("response: ", response);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    return { response: response.data };
  }
);

export const actionRemoveDataNominee = createAsyncThunk(
  "nominee/actionRemoveDataNominee",
  async (props) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log("sessionStorage: ", props);
      response = await axiosPostInstance(
        "/api/users/removenominee",
        token,
        props
      );
      console.log("response: ", response);
      return { response: response.data };
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
  }
);

export const actionModifyNominee = createAsyncThunk(
  "nominee/actionModifyNominee",
  async (props) => {
    let response = null;
    var tempList = [];
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log("sessionStorage: ", props);
      response = await axiosPostInstance(
        "/api/users/modifyNominee",
        token,
        props
      );
      console.log("actionModifyNominee response: ", response);
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
    tempList.push(response.data.modifiedNewNominee);
    return { response: tempList };
  }
);

export const actionModifyNomineeCred = createAsyncThunk(
  "nominee/actionModifyNomineeCred",
  async (props) => {
    let response = null;
    var tempList = [];
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log("sessionStorage: ", props);
      response = await axiosPostInstance(
        "/api/users/modifyNomineeCred",
        token,
        props
      );
      console.log("actionModifyNominee response: ", response);
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
    tempList.push(response.data.modifiedNewNomineeCred);
    return { response: tempList };
  }
);

const initialState = {
  dataNomineeList: null,
  nomineeStatus: null,
  nomineeAdded: null,
  addNomineeError: null,
  nomineeRemoved: true,
  modifiedNominee: null,
  modifiedNewNomineeCred: null,
};

export const nomineeSlice = createSlice({
  name: "nominee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionDataNomineeList.pending, (state) => {})
      .addCase(actionDataNomineeList.fulfilled, (state, action) => {
        state.dataNomineeList = action.payload.response;
        state.nomineeStatus = true;
        state.nomineeAdded = false;
      })
      .addCase(actionDataNomineeList.rejected, (state, action) => {
        state.nomineeStatus = false;
        // state.uploadError = action.payload.error // TODO Error Handling
        console.log("error: ", action.payload);
      })

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionAddDataNominee.pending, (state) => {})
      .addCase(actionAddDataNominee.fulfilled, (state, action) => {
        state.nomineeAdded = true;
        state.dataNomineeList = action.payload.response.dataNominee;
      })
      .addCase(
        actionAddDataNominee.rejected,
        (state, { meta, payload, error }) => {
          state.addNomineeError = { error, payload };
          console.log("error: ", error, payload, meta);
        }
      )

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionRemoveDataNominee.pending, (state) => {})
      .addCase(actionRemoveDataNominee.fulfilled, (state, action) => {
        state.nomineeRemoved = true;
        state.dataNomineeList = action.payload.response;
      })
      .addCase(
        actionRemoveDataNominee.rejected,
        (state, { meta, payload, error }) => {
          state.addNomineeError = { error, payload };
          console.log("error: ", error, payload, meta);
        }
      )

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionModifyNominee.pending, (state) => {})
      .addCase(actionModifyNominee.fulfilled, (state, action) => {
        state.modifiedNominee = action.payload.response;
      })
      .addCase(
        actionModifyNominee.rejected,
        (state, { meta, payload, error }) => {
          console.log("error: ", error, payload, meta);
        }
      )

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionModifyNomineeCred.pending, (state) => {})
      .addCase(actionModifyNomineeCred.fulfilled, (state, action) => {
        state.modifiedNewNomineeCred = action.payload.response;
      })
      .addCase(
        actionModifyNomineeCred.rejected,
        (state, { meta, payload, error }) => {
          console.log("error: ", error, payload, meta);
        }
      );
  },
});

// Action creators are generated for each case reducer function
// export const { setUserData } = accountSlice.actions

export default nomineeSlice.reducer;
