import styles from "./OpeningSection.module.css";
import wave from "../../../../animations/wave.json";
import Lottie from "lottie-react";
import { Button } from "antd";
import { Link } from "react-router-dom";

function OpeningSection() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.opening_section}>
        <div className={styles.grid}>
          <div className={styles.header}>
            <h1 className={styles.heading}>
              Ever wondered what happens to your digital assets & data once you
              are no longer around?{" "}
            </h1>
            <Link to="/register">
              <Button
                style={{
                  borderRadius: "10px",
                  height: "3rem",
                  backgroundColor: "rgb(18,57,98)",
                  fontWeight: "700",
                  fontSize: "0.9rem",
                  color: "white",
                }}
              >
                Protect Your Assets Now
              </Button>
            </Link>
            <Lottie
              className={styles.lottie_mobile}
              animationData={wave}
              loop={true}
              style={{ height: "auto" }}
            />
          </div>
        </div>
      </div>

      <Lottie
        className={styles.lottie_desktop}
        animationData={wave}
        loop={true}
        style={{ height: "auto" }}
      />
    </div>
  );
}

export default OpeningSection;
