import React, { useState } from "react";
import { Row, Col, Avatar, Tooltip } from "antd";
import { shortenName, shortenEmail } from "../helpers/ShortenAddress";

import { CheckCircleOutlined } from "@ant-design/icons";

export default function SelectNominee(props) {
  const nomineeName = props.nomineeName;
  const nomineePhone = props.nomineePhone;
  const nomineeEmail = props.nomineeEmail;
  const isSelected = props.isSelected;
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardCss = {
    background: isSelected ? "#7caaff" : isHovered ? "#7caaff" : "#b4c5e1",
    width: 410,
    height: 210,
    // margin: 20,
    borderRadius: "10px",
    padding: 25,
    cursor: "pointer",
    transition:
      "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out",
    //boxShadow: isSelected ? '0px 0px 20px rgba(0, 0, 0, 0.5)' :isHovered ?'0px 0px 20px rgba(0, 82, 237, 0.5)':'0px 0px 10px rgba(0, 0, 0, 0.5)',
    border: isSelected
      ? "6px solid rgb(65, 72, 176)"
      : isHovered
      ? "6px solid #79a4f2"
      : "6px solid #b4c5e1",
  };

  return (
    <div className="choice-container">
      <div
        className="shadow"
        style={cardCss}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Row style={{ fontSize: "20px" }}>
          <div>
            {isSelected && (
              <div
                style={{
                  position: "absolute",
                  marginTop: "-10px",
                  marginLeft: "-10px",
                }}
              >
                <CheckCircleOutlined />
              </div>
            )}
          </div>
        </Row>
        <Row>
          <Col span={12}>
            <Avatar
              size={130}
              style={{
                backgroundColor: "#fde3cf",
                color: "#f56a00",
                fontSize: 80,
              }}
            >
              {nomineeName.split("")[0].toUpperCase()}
            </Avatar>
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              wordBreak: "break-word",
            }}
          >
            <div>
              <div className="big-name">
                <Tooltip title={nomineeName}>
                  {nomineeName.length > 12
                    ? shortenName(nomineeName)
                    : nomineeName}
                </Tooltip>
              </div>
              <div className="small-name">{nomineePhone}</div>
              <div className="small-name">
                <Tooltip title={nomineeEmail}>
                  {shortenEmail(nomineeEmail)}
                </Tooltip>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
