import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionGetCred, actionGetFiles } from "../store/slices/uploadSlice";
import {
  Button,
  Space,
  message,
  Drawer,
  Collapse,
  List,
  Checkbox,
  Modal,
  Form,
  notification,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
  Tabs,
  Avatar,
} from "antd";
import {
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
  CheckCircleTwoTone,
  CopyTwoTone,
} from "@ant-design/icons";
import PhoneInput from "react-phone-number-input";
import DeleteModal from "../components/DeleteModal";
import DeleteCredModal from "../components/DeleteCredModal";
import {
  actionModifyNominee,
  actionAddDataNominee,
  actionModifyNomineeCred,
} from "../store/slices/nomineeSlice";
import { AddNominee } from "../components/AddNominee";
import insuarances from "../images/insuarances.svg";
import fds from "../images/fds.svg";
import liquidGold from "../images/liquidGold.svg";
import realEstate from "../images/realEstate.svg";
import socialMedia from "../images/socialMedia.svg";
import banks from "../images/bank.svg";

import stocks from "../images/stocks.svg";
import mutualFunds from "../images/mutualFunds.svg";
import bonds from "../images/bonds.svg";
import { WarningFilled, CheckCircleFilled } from "@ant-design/icons";

export default function UploadedFiles() {
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerKey, setDrawerKey] = useState();
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [uploadedCredList, setUploadedCredList] = useState([]);
  const [openCredDrawer, setOpenCredDrawer] = useState(false);
  const [url, setUrl] = useState("");

  const files = useSelector((state) => state.upload.files);
  const cred = useSelector((state) => state.upload.cred);
  const user = useSelector((state) => state.account.user);
  const dataNomineeList = useSelector((state) => state.nominee.dataNomineeList);
  const modified = useSelector((state) => state.nominee.modifiedNominee);
  const modifiedNewNomineeCred = useSelector(
    (state) => state.nominee.modifiedNewNomineeCred
  );

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description: "",
    });
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  const labels = {
    label1: "Name",
    label2: "Email",
    label3: "Number",
  };

  const assetTypeImages = {
    Insurances: insuarances,
    FDs: fds,
    "Liquid Gold": liquidGold,
    "Real Estate": realEstate,
    Stocks: stocks,
    "Mutual Funds": mutualFunds,
    Bonds: bonds,
  };

  const credTypeImages = {
    "Social Media": socialMedia,
    Banks: banks,
  };

  const [isAddNomineeModalOpen, setIsAddNomineeModalOpen] = useState(false);
  const showAddNomineeModal = () => {
    setIsAddNomineeModalOpen(true);
  };
  const handleAddNomineeCancel = () => {
    setIsAddNomineeModalOpen(false);
  };

  const onFinish = (values) => {
    const accessToken = sessionStorage.getItem("accessToken");
    let nomineeData = {
      name: values.user.name,
      email: user.email,
      nomineeEmail: values.user.email,
      number: values.user.number,
      authorization: accessToken,
      relationship: values.user.relationship,
    };
    form.resetFields();
    if (
      nomineeData.nomineeEmail != nomineeData.email &&
      user.name != nomineeData.name
    ) {
      //JSON.parse(window.localStorage.user) can also be used
      dispatch(actionAddDataNominee(nomineeData));
    } else {
      openNotificationWithIcon("error", "Cannot add yourself as nominee");
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCheck = (value, dataFile) => {
    const newNominee = value.email;
    const folderID = dataFile.id;
    console.log("dataFile: ", dataFile);

    if (dataFile.Nname != newNominee) {
      console.log("value: ", value);
      const tempData = {
        email: user.email,
        folderID: folderID,
        newNominee: newNominee,
      };

      dispatch(actionModifyNominee(tempData));

      if (user) {
        console.log(user);
        var email = user.email;

        const data = {
          email: email,
        };
        dispatch(actionGetFiles(data));
      }
      console.log(tempData);
    } else {
      console.log("Unset Nominee");
    }
  };

  const handleCheckCred = (value, dataFile) => {
    const newNominee = value.email;
    const folderID = dataFile.id;
    console.log("dataFile: ", dataFile);

    if (dataFile.Nname != newNominee) {
      console.log("value: ", value);
      const tempData = {
        email: user.email,
        folderID: folderID,
        newNominee: newNominee,
      };

      dispatch(actionModifyNomineeCred(tempData));

      if (user) {
        console.log(user);
        var email = user.email;

        const data = {
          email: email,
        };
        dispatch(actionGetCred(data));
      }
      console.log(tempData);
    } else {
      console.log("Unset Nominee");
    }
  };

  const handleCollapse = (key) => {
    setOpenCollapse((prevActivePanels) => {
      if (prevActivePanels.includes(key)) {
        console.log("in if prevActivePanels: ", prevActivePanels, " ", key);
        return prevActivePanels.filter((panelKey) => panelKey !== key);
      } else {
        console.log("in else prevActivePanels: ", prevActivePanels, " ", key);
        return [key];
      }
    });
  };

  const mapToModifyNominee =
    dataNomineeList.length > 0
      ? dataNomineeList?.map((data, key) => {
          return { name: data.nomineeName, email: data.nomineeEmail, key: key };
        })
      : "";

  const showDrawer = (key) => {
    console.log("key: ", key);
    setOpen(true);
    setDrawerKey(key);
  };
  const showCredDrawer = (key) => {
    console.log("key: ", key);
    setOpenCredDrawer(true);
    setDrawerKey(key);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onCloseCredDrawer = () => {
    setOpenCredDrawer(false);
  };

  const uploadedFilesListTemp = [];

  const filesUpdate = () => {
    if (files && files.length !== 0) {
      files.forEach((data, key) => {
        if (data.deleted === true) return;
        uploadedFilesListTemp.push({
          key: key,
          Fname: data.folder,
          Nname: data.nominee,
          files: data.files,
          id: data.id || null,
          assetType: data.assetType || null,
        });
      });
      setUploadedFilesList(uploadedFilesListTemp);
    }
    console.log("uploadedFilesListTemp: ", uploadedFilesListTemp);
  };

  const uploadedCredListTemp = [];

  const credUpdate = () => {
    if (cred && cred.length !== 0) {
      cred.forEach((data, key) => {
        if (data.deleted === true) return;
        uploadedCredListTemp.push({
          key: key,
          Fname: data?.folder,
          Nname: data?.nominee,
          files: data?.files,
          id: data?.id || null,
          assetType: data?.assetType || null,
        });
      });
      setUploadedCredList(uploadedCredListTemp);
    }
    console.log("uploadedCredListTemp: ", uploadedCredListTemp);
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      var email = user.email;

      const data = {
        email: email,
      };
      dispatch(actionGetFiles(data));
      dispatch(actionGetCred(data));
      console.log("cred: ", cred);
    }
  }, []);

  useEffect(() => {
    let user;
    user = JSON.parse(localStorage.getItem("user"));
    const photoURL = user?.photoURL;
    setUrl(photoURL);
  }, []);

  useEffect(() => {
    if (user) {
      console.log(user);
      var email = user.email;

      const data = {
        email: email,
      };
      dispatch(actionGetFiles(data));
      dispatch(actionGetCred(data));
    }
  }, [modified, modifiedNewNomineeCred]);

  useEffect(() => {
    console.log("Files: ");
    filesUpdate();
    credUpdate();
    console.log("cred: ", cred);
  }, [files, cred]);

  return (
    <>
      <div className="in-app-layout">
        <div className="page-title">
          <h1 style={{ margin: "0" }}>Assets</h1>
        </div>
        <div className="page-title-desc">
          <p>List of all the assets that you have uploaded.</p>
        </div>

        <div
          className="row"
          style={{
            paddingTop: "10px",
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: "Assets",
                key: 1,
                children: (
                  <div
                    style={{
                      marginTop: "40px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1rem",
                    }}
                  >
                    {uploadedFilesList?.map((data, key) => (
                      <div>
                        <Space direction="vertical" key={key}>
                          <div
                            className="file-card"
                            key={key}
                            style={{
                              position: "relative",
                              transition: "height 0.3s",
                            }}
                          >
                            <Row>
                              <Col span={22}>
                                <h3>{data.Fname}</h3>
                              </Col>
                              <Col span={2}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    justifyContent: "right",
                                  }}
                                >
                                  {data.Nname ? (
                                    <CheckCircleFilled
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "green",
                                      }}
                                    />
                                  ) : (
                                    <Tooltip title="Add a Nominee">
                                      <WarningFilled
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "red",
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <p style={{ paddingBottom: "1rem" }}>
                              {" "}
                              <span className="color-cement">
                                Nominee:
                              </span>{" "}
                              {data.Nname
                                ? data.Nname
                                : "Nominee Not Assigned Yet"}
                            </p>
                            <div>
                              <Collapse
                                activeKey={openCollapse}
                                onChange={() => handleCollapse(key)}
                              >
                                <Collapse.Panel
                                  header="Assign Nominee"
                                  key={key.toString()}
                                  forceRender={true}
                                >
                                  <List
                                    size="small"
                                    footer={
                                      <Button onClick={showAddNomineeModal}>
                                        Add Nominee
                                      </Button>
                                    }
                                    bordered
                                    dataSource={mapToModifyNominee}
                                    renderItem={(item, index) => (
                                      <List.Item
                                        actions={[
                                          <Checkbox
                                            key={index}
                                            checked={data.Nname == item.email}
                                            onChange={() =>
                                              handleCheck(item, data)
                                            }
                                          />,
                                        ]}
                                      >
                                        {item.name}
                                      </List.Item>
                                    )}
                                  />
                                </Collapse.Panel>
                              </Collapse>
                            </div>
                            <div style={{ paddingTop: "1rem" }}>
                              <Row>
                                <Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    justifyContent: "left",
                                  }}
                                >
                                  <Row>
                                    <Space>
                                      <img
                                        src={assetTypeImages[data.assetType]}
                                        height={50}
                                      ></img>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          alignContent: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        <span className="color-cement">
                                          {data.assetType}
                                        </span>
                                      </p>
                                    </Space>
                                  </Row>
                                </Col>
                                <Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    justifyContent: "right",
                                  }}
                                >
                                  <Row>
                                    <Space direction="horizontal">
                                      <DeleteModal record={data} />
                                      <EyeTwoTone
                                        style={{ fontSize: "1.5rem" }}
                                        onClick={() => showDrawer(key)}
                                      />
                                    </Space>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Space>
                      </div>
                    ))}
                  </div>
                ),
              },
              {
                label: "Credentials",
                key: 2,
                children: (
                  <div
                    style={{
                      marginTop: "40px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1rem",
                    }}
                  >
                    {uploadedCredList?.map((data, key) => (
                      <div>
                        <Space direction="vertical" key={key}>
                          <div
                            className="file-card"
                            key={key}
                            width={100}
                            style={{
                              position: "relative",
                              transition: "height 0.3s",
                            }}
                          >
                            <Row>
                              <Col span={22}>
                                <h3>{data.Fname}</h3>
                              </Col>
                              <Col span={2}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    justifyContent: "right",
                                  }}
                                >
                                  {data.Nname ? (
                                    <CheckCircleFilled
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "green",
                                      }}
                                    />
                                  ) : (
                                    <Tooltip title="Add a Nominee">
                                      <WarningFilled
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "red",
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <p style={{ paddingBottom: "1rem" }}>
                              {" "}
                              <span className="color-cement">
                                Nominee:
                              </span>{" "}
                              {data.Nname
                                ? data.Nname
                                : "Nominee Not Assigned Yet"}
                            </p>
                            <div
                              style={{
                                position: "relative",
                                transition: "height 0.3s",
                              }}
                            >
                              <Collapse
                                activeKey={openCollapse}
                                onChange={() => handleCollapse(key)}
                              >
                                <Collapse.Panel
                                  header="Assign Nominee"
                                  key={key.toString()}
                                  forceRender={true}
                                >
                                  <List
                                    size="small"
                                    footer={
                                      <Button onClick={showModal}>
                                        Add Nominee
                                      </Button>
                                    }
                                    bordered
                                    dataSource={mapToModifyNominee}
                                    renderItem={(item, index) => (
                                      <List.Item
                                        actions={[
                                          <Checkbox
                                            key={index}
                                            checked={data.Nname == item.email}
                                            onChange={() =>
                                              handleCheckCred(item, data)
                                            }
                                          />,
                                        ]}
                                      >
                                        {item.name}
                                      </List.Item>
                                    )}
                                  />
                                </Collapse.Panel>
                              </Collapse>
                            </div>
                            <div style={{ paddingTop: "1rem" }}>
                              <Row>
                                <Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    justifyContent: "left",
                                  }}
                                >
                                  <Row>
                                    <Space>
                                      <img
                                        src={credTypeImages[data.assetType]}
                                        height={50}
                                      ></img>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          alignContent: "center",
                                          justifyContent: "left",
                                        }}
                                      >
                                        <span className="color-cement">
                                          {data.assetType}
                                        </span>
                                      </p>
                                    </Space>
                                  </Row>
                                </Col>
                                <Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    justifyContent: "right",
                                  }}
                                >
                                  <Row>
                                    <Space direction="horizontal">
                                      <DeleteCredModal record={data} />
                                      <EyeTwoTone
                                        style={{ fontSize: "1.5rem" }}
                                        onClick={() => showCredDrawer(key)}
                                      />
                                    </Space>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Space>
                      </div>
                    ))}
                    ,
                  </div>
                ),
              },
            ]}
          />
        </div>
        <Drawer
          title="Asset Information"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <p className="color-dull">Owner</p>
          <div className="f-l-g1">
            <Avatar
              size={50}
              style={{
                backgroundColor: "#fde3cf",
                color: "#2654ba",
                fontSize: 30,
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
              src={url}
            >
              {user.name.split("")[0].toUpperCase()}
            </Avatar>
            <h3>{user?.name}</h3>
          </div>
          <br />
          <p className="color-dull">Type</p>
          <div className="f-l-g1">
            <img
              src={assetTypeImages[uploadedFilesList[drawerKey]?.assetType]}
              height={50}
            ></img>
            <h3>{uploadedFilesList[drawerKey]?.assetType}</h3>
          </div>
          <br />
          <p className="color-dull">Asset Name</p>
          <div className="f-c-s">
            <h3 style={{ marginTop: "5px" }}>
              {uploadedFilesList[drawerKey]?.Fname}
            </h3>
            <CopyTwoTone
              style={{ fontSize: "1.5rem", marginTop: "-10px" }}
              onClick={() => {
                navigator.clipboard.writeText(
                  uploadedFilesList[drawerKey]?.Fname
                );
                message.success("Copied to clipboard!");
              }}
            />
          </div>
          <br />
          <p className="color-dull">Nominee</p>

          {uploadedFilesList[drawerKey] ? (
            uploadedFilesList[drawerKey].Nname ? (
              <div className="f-c-s">
                {" "}
                <h3 style={{ marginTop: "5px" }}>
                  {" "}
                  {uploadedFilesList[drawerKey]?.Nname}
                </h3>
                <CopyTwoTone
                  style={{ fontSize: "1.5rem", marginTop: "-10px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      uploadedFilesList[drawerKey]?.Nname
                    );
                    message.success("Copied to clipboard!");
                  }}
                />{" "}
              </div>
            ) : (
              <h3 style={{ color: "red" }}>No nominee</h3>
            )
          ) : (
            ""
          )}

          <br />
          <p className="color-dull">Files</p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {uploadedFilesList[drawerKey]?.files.map((data, key) => {
              return <h3 style={{ margin: "7px 0" }}>{data.name}</h3>;
            })}
          </div>
          <br />

          <Button onClick={onClose} style={{ width: "100%" }}>
            Close
          </Button>
        </Drawer>
        <Drawer
          title="Credentials Information"
          placement="right"
          onClose={onCloseCredDrawer}
          open={openCredDrawer}
        >
          <p className="color-dull">Owner</p>
          <div className="f-l-g1">
            <Avatar
              size={50}
              style={{
                backgroundColor: "#fde3cf",
                color: "#2654ba",
                fontSize: 30,
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
              src={url}
            >
              {user.name.split("")[0].toUpperCase()}
            </Avatar>
            <h3>{user?.name}</h3>
          </div>

          <br />
          <p className="color-dull">Type</p>
          <div className="f-l-g1">
            <img
              src={credTypeImages[uploadedCredList[drawerKey]?.assetType]}
              height={50}
            ></img>
            <h3>{uploadedCredList[drawerKey]?.assetType}</h3>
          </div>

          <br />
          <p className="color-dull">Asset Name</p>
          <div className="f-c-s">
            <h3 style={{ marginTop: "5px" }}>
              {uploadedCredList[drawerKey]?.Fname}
            </h3>
            <CopyTwoTone
              style={{ fontSize: "1.5rem", marginTop: "-10px" }}
              onClick={() => {
                navigator.clipboard.writeText(
                  uploadedCredList[drawerKey]?.Fname
                );
                message.success("Copied to clipboard!");
              }}
            />
          </div>

          <br />
          <p className="color-dull">Nominees</p>

          {uploadedCredList[drawerKey] ? (
            uploadedCredList[drawerKey].Nname ? (
              <div className="f-c-s">
                {" "}
                <h3 style={{ marginTop: "5px" }}>
                  {" "}
                  {uploadedCredList[drawerKey]?.Nname}
                </h3>
                <CopyTwoTone
                  style={{ fontSize: "1.5rem", marginTop: "-10px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      uploadedCredList[drawerKey]?.Nname
                    );
                    message.success("Copied to clipboard!");
                  }}
                />{" "}
              </div>
            ) : (
              <h3 style={{ color: "red" }}>No nominee</h3>
            )
          ) : (
            ""
          )}

          <br />
          <Button onClick={onCloseCredDrawer} style={{ width: "100%" }}>
            Close
          </Button>

          {/* <p className="color-dull">Credentials</p>
          <div className="f-c-s">
            {
              uploadedCredList[drawerKey] ?
                uploadedCredList[drawerKey].files ?
                  uploadedCredList[drawerKey]?.files.map((data, key) => {
                    console.log(":", data)
                    // return <h1>{data.name}</h1>;
                  }) : console.log("first:", uploadedCredList[drawerKey]) : ""
            }
          </div> */}
        </Drawer>
      </div>
      <AddNominee
        isAddNomineeModalOpen={isAddNomineeModalOpen}
        handleAddNomineeCancel={handleAddNomineeCancel}
        user={user}
      ></AddNominee>
    </>
  );
}
