import styles from "./SecondSection.module.css";
import people from "../../../assets/images/people.png";
import pig from "../../../assets/images/pig.png";
import file from "../../../assets/images/file.png";

const SecondSection = () => {
    return (
        <div className={styles.secondSection}>
            <h1 className={styles.heading2}>Secure your digital assets beyond life using block chain technology</h1>
            <div className={styles.gridContainer}>
                <div className={styles.card}>
                    <img height={85} src={pig} alt="piggy bank" />
                    <p className={styles.para}>Bank account details</p>
                </div>
                <div className={styles.card1}>
                    <img height={85} src={people} alt="people" />
                    <p className={styles.para}>Social media</p>
                </div>
                <div className={styles.card}>
                    <img height={85} src={file} alt="file" />
                    <p className={styles.para}>Confidential documents </p>
                </div>
            </div>
        </div>
    );
}

export default SecondSection;