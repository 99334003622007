import ChoiceCard from "../components/ChoiceCard";
import notes from "../images/notes.svg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
const cardData = [
  {
    image: notes,
    heading: "Letter",
    route: "/notes",
  },
];

export default function ChooseNotes() {
  const navigate = useNavigate();
  return (
    <div className="in-app-layout">
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Personal Notes</h1>
      </div>
      <div style={{ float: "right" }}>
        <Button
          type="primary"
          style={{ marginLeft: "10px", background: "rgb(18, 57, 98)" }}
          className="color-white"
          onClick={() => {
            navigate("/choose");
          }}
        >
          Back
        </Button>
      </div>
      <div className="page-title-desc">
        <p>Select the type of note you would like to write</p>
      </div>
      <div
        className="row"
        style={{ marginTop: "40px", display: "flex", flexWrap: "wrap" }}
      >
        {cardData.map((card, index) => (
          <ChoiceCard
            key={index}
            image={card.image}
            heading={card.heading}
            desc={card.desc}
            route={card.route}
            square={true}
          />
        ))}
      </div>
    </div>
  );
}
