import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Result, notification } from "antd";
import { actionRemoveDataNominee } from "../store/slices/nomineeSlice";
import { actionDataNomineeList } from "../store/slices/nomineeSlice";

export default function DeleteNomineeModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.account.user);
  const dataNomineeList = useSelector((state) => state.nominee.dataNomineeList);

  const record = props.record;

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description: "",
    });
  };

  const handleDelete = async () => {
    const nomineeToDelete = dataNomineeList[record?.key];

    var isNomineeAssigned = false;
    for (var element in user?.files) {
      if (user?.files[element].nominee === nomineeToDelete.nomineeEmail) {
        isNomineeAssigned = true;
        setIsModalOpen(false);
        break;
      }
    }

    if (isNomineeAssigned) {
      openNotificationWithIcon("error", "Nominee assigned to an asset");
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");

    const nomineeData = {
      username: user.name,
      email: user.email,
      nomineeEmail: nomineeToDelete.nomineeEmail,
      authorization: accessToken,
    };

    dispatch(actionRemoveDataNominee(nomineeData));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setDeleted(false);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button type="primary" danger onClick={showModal}>
        Delete
      </Button>
      <Modal
        title="Delete Nominee"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {deleted ? (
          <div className="fc">
            <Result
              status="success"
              title="Nominee Deleted Successful"
              extra={[
                <Button
                  className="modal-btn"
                  type="primary"
                  key="console"
                  onClick={handleCancel}
                >
                  Ok Close
                </Button>,
              ]}
            />
          </div>
        ) : (
          <div className="fc">
            <p>Are you sure you want to delete this nominee?</p>
            <br />
            <Button
              className="modal-btn"
              type="primary"
              danger
              key="console"
              onClick={handleDelete}
            >
              Confirm Delete
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}
