import styles from './ThirdSection.module.css';
import time from '../../../assets/icons/time.png';
import lock from '../../../assets/icons/lock.png';
import tick from '../../../assets/icons/tick.png';


const ThirdSection = () => {
    return (
        <div className={styles.ThirdSection}>
            <div className={styles.gridContainer}>
                <div>
                    <h1 className={styles.heading}>Protect The Future Of
                        Your Digital Assets Well
                        Beyond Lifetime With
                        Data Nominee</h1>
                </div>
                <div>
                    <div className={styles.gridContainer1}>
                        <div className={styles.gridItem}>
                            <img height={55} src={tick} alt="tick" />
                        </div>
                        <div>
                            <h3 className={styles.point}>Risk-free Nominee Verification </h3>
                        </div>
                    </div>
                    <div className={styles.gridContainer1}>
                        <div className={styles.gridItem1}>
                            <img height={55} src={lock} alt="lock" />
                        </div>
                        <div>
                            <h3 className={styles.point}>Secure Asset Authentication
                                and
                                Transaction
                            </h3>
                        </div>
                    </div>
                    <div className={styles.gridContainer1}>
                        <div className={styles.gridItem1}>
                            <img height={55} src={time} alt="time" />
                        </div>
                        <div>
                            <h3 className={styles.point}>Flexible & Time based Subscription Models
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdSection;