import { useSearchParams } from "react-router-dom";
import React from "react";
import { notification, Radio, Space } from "antd";
import { axiosPostInstance } from "../utils/axiosPostInstance";

export default function Vote() {
  const [queryParams] = useSearchParams();
  const id = queryParams.get("id");

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const [sending, setSending] = React.useState(false);
  const [details, setDetails] = React.useState({
    refereeEmail: "",
    vote: false,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      const token = sessionStorage.getItem("accessToken");

      const response = await axiosPostInstance("/api/users/vote", token, {
        refereeEmail: details.refereeEmail,
        vote: details.vote,
        id,
      });
      console.log("response: ", response);
      if (response.status === 200) {
        openNotificationWithIcon(
          "success",
          "Success",
          "Vote submitted successfully"
        );
      }
    } catch (e) {
      // console.log(e)
      openNotificationWithIcon("error", "Error", e.response.data.error);
    }
    setSending(false);
  };

  return (
    <>
      <div style={{ minHeight: "90vh", display: "flex", overflowY: "auto" }}>
        <div
          style={{
            display: "block",
            maxWidth: "40rem",
            justifyContent: "center",
            padding: "20px",
            borderRadius: "20px",
            margin: "auto",
            background: "white",
            border: "2px solid #165188",
            marginTop: "10rem",
          }}
        >
          <div
            style={{
              fontSize: "2rem",
              color: "#165188",
              fontWeight: "800",
              textAlign: "center",
            }}
          >
            Vote
          </div>
          <form style={{ marginTop: "1rem" }} onSubmit={handleSubmit}>
            <input
              className="w3a-text-field"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={details.refereeEmail}
              required
              onChange={(e) =>
                setDetails((prev) => ({
                  ...prev,
                  refereeEmail: e.target.value,
                }))
              }
            />
            <Radio.Group
              onChange={(e) =>
                setDetails((prev) => ({ ...prev, vote: e.target.value }))
              }
              value={details.vote}
            >
              <Space direction="vertical">
                <Radio value={true}>Claim is true</Radio>
                <Radio value={false}>Claim is false</Radio>
              </Space>
            </Radio.Group>
            <button
              className="login-button"
              style={{ marginTop: "20px" }}
              type="submit"
              disabled={details?.refereeEmail.trim().length === 0 || sending}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      {contextHolder}
    </>
  );
}
