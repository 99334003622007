import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import "../../src/css/style.css";

import loginVector from "../images/people-collaborating-with-tech- 1.svg";
import { AdminCard } from "../components/AdminCard";

export default function AdminLogin() {
  const navigate = useNavigate();

  return (
    <div className="login-page-bg">
      <Row style={{ marginTop: "3rem" }}>
        <Col
          className="horizontal-center loginimagediv"
          span={12}
          style={{ padding: "2rem" }}
        >
          <img height="100%" width="100%" src={loginVector} />
        </Col>
        <Col
          className="horizontal-center"
          id="logincol"
          span={12}
          style={{ padding: "3rem 6rem" }}
        >
          <AdminCard />
        </Col>
      </Row>
    </div>
  );
}
