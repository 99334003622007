import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionClaimCred, actionClaimFiles } from "../store/slices/uploadSlice";
import { Table, Tabs } from "antd";
import { shortenAddress } from "../helpers/ShortenAddress";
import FileListModal from "../components/FileListModal";

import { useLocation } from "react-router-dom";
import DownloadModal from "../components/DownloadModal";
import DownloadCredModal from "../components/DownloadCredModal";

export default function Files() {
  const location = useLocation();
  const { record } = location.state;
  const recordFromNominations = record;

  console.log("record: ", record);

  const dispatch = useDispatch();
  const claimFiles = useSelector((state) => state.upload.claimFiles);
  const claimCred = useSelector((state) => state.upload.claimCred);

  const user = useSelector((state) => state.account.user);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "See Files",
      dataIndex: "see",
      key: "see",
      align: "center",
      width: "5%",
      className: "column-title",
      render: (_, record) => <FileListModal btn="primary" record={record} />,
    },
    {
      title: "Download Asset",
      dataIndex: "download",
      key: "download",
      align: "center",
      width: "5%",
      className: "column-title",
      render: (_, record) => (
        <DownloadModal
          record={record}
          recordFromNominations={recordFromNominations}
        ></DownloadModal>
      ),
    },
  ];

  const columnsCred = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "See Files",
      dataIndex: "see",
      key: "see",
      align: "center",
      width: "5%",
      className: "column-title",
      render: (_, record) => <FileListModal btn="primary" record={record} />,
    },
    {
      title: "Download Asset",
      dataIndex: "download",
      key: "download",
      align: "center",
      width: "5%",
      className: "column-title",
      render: (_, record) => (
        <DownloadCredModal
          record={record}
          recordFromNominations={recordFromNominations}
        ></DownloadCredModal>
      ),
    },
  ];

  useEffect(() => {
    if (user) {
      console.log("User: ", user, location);
      var email = record.email;
      const data = {
        userEmail: email,
        nomineeEmail: user.email,
      };
      console.log("in effect email is: ", data);
      dispatch(actionClaimFiles(data));
      dispatch(actionClaimCred(data));
    }
  }, []);

  const uploadedFilesListTemp = [];
  const uploadedCredListTemp = [];

  const data = claimFiles
    ? claimFiles.map((data, key) => {
        if (data.deleted === true) return;
        let ID = shortenAddress(data.nominee);
        uploadedFilesListTemp.push({
          key: key,
          name: data?.folder,
          ID: ID,
          files: data?.files,
          recordEmail: record?.email,
        });
      })
    : "";

  const data2 = claimCred
    ? claimCred.map((data, key) => {
        if (data.deleted === true) return;
        let ID = shortenAddress(data.nominee);
        uploadedCredListTemp.push({
          key: key,
          name: data?.folder,
          ID: ID,
          files: data?.files,
          recordEmail: record?.email,
        });
      })
    : "";

  const tableProps = {
    scroll: { x: "max-content" }, // Enable horizontal scrolling for the table
  };

  return (
    <div className="in-app-layout">
      {/* <Link to="/claim/download">Back</Link> */}
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Download Files</h1>
      </div>
      <div>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Assets",
              key: 1,
              children: (
                <>
                  {" "}
                  <Table
                    className="table-container"
                    columns={columns}
                    dataSource={uploadedFilesListTemp}
                    pagination={false}
                    {...tableProps}
                  />
                </>
              ),
            },
            {
              label: "Credentials",
              key: 2,
              children: (
                <>
                  {" "}
                  <Table
                    className="table-container"
                    columns={columnsCred}
                    dataSource={uploadedCredListTemp}
                    pagination={false}
                    {...tableProps}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}
