import {signInWithEmailAndPassword} from "firebase/auth";
import {firebaseAuth} from "../config/Firebase";

//This needs work to be done, now with mongo

export const signInAdmin = async (name, password) => {
    try {
        const result = await signInWithEmailAndPassword(firebaseAuth, name, password)

        const name = result.user.displayName;
        const userEmail = result.user.email;
        const profilePic = result.user.photoURL;
        console.log("result: ", result)

        localStorage.setItem("name", name);
        localStorage.setItem("email", userEmail);
        localStorage.setItem("profilePic", profilePic);
        localStorage.setItem("user", JSON.stringify(firebaseAuth.currentUser))
        localStorage.setItem("loggedIn", "true");

        return {
            status: "success",
            code: 200,
            message: "Successfully logged in.",
            user: result.user
        }
    } catch (error) {
        console.log("error: ", error)
        return {
            status: "error",
            code: 500,
            message: error.message
        }
    }
}