import axios from "axios";

export const axiosDeleteInstance = (url, token, data) => {
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return axiosInstance.delete(url, {data: data});
};
