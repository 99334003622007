import "react-phone-number-input/style.css";

import { Button, Table } from "antd";
import { notification } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { actionDataNomineeList } from "../store/slices/nomineeSlice";
import DeleteNomineeModal from "../components/DeleteNomineeModal";
import {
  shortenAddress,
  shortenName,
  shortenEmail,
} from "../helpers/ShortenAddress";
import { AddNominee } from "../components/AddNominee";

export default function Nominee() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const dataNomineeList = useSelector((state) => state.nominee.dataNomineeList);
  const nomineeAdded = useSelector((state) => state.nominee.nomineeAdded);
  const addNomineeError = useSelector((state) => state.nominee.addNomineeError);
  const nomineeRemoved = useSelector((state) => state.nominee.nomineeRemoved);

  const user = useSelector((state) => state.account.user);

  // const triggerNomineeExists = (addNomineeError) => {
  //   openNotificationWithIcon("error", addNomineeError.payload.error);
  // };

  // useEffect(() => {
  //   console.log("addNomineeError: ", addNomineeError);
  //   if (addNomineeError) {
  //     triggerNomineeExists(addNomineeError);
  //   }
  // }, [addNomineeError]);

  const [isAddNomineeModalOpen, setIsAddNomineeModalOpen] = useState(false);
  const showAddNomineeModal = () => {
    setIsAddNomineeModalOpen(true);
  };
  const handleAddNomineeCancel = () => {
    setIsAddNomineeModalOpen(false);
  };

  const dataNomList = [];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Phone Number",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      align: "center  ",
      width: "5%",
      className: "column-title",
      render: (text, _record) => {
        return <DeleteNomineeModal record={_record} />;
      },
    },
  ];

  const data = dataNomineeList
    ? dataNomineeList.map((data, key) => {
        let ID = shortenAddress(data.nomineeId);
        let nName =
          data.nomineeName.length > 12
            ? shortenName(data.nomineeName)
            : data.nomineeName;
        let nEmail = shortenEmail(data.nomineeEmail);
        dataNomList.push({
          key: key,
          name: nName,
          email: nEmail,
          number: data.nomineePhone,
          ID: ID,
          relationship: data.nomineeRelationship,
        });
      })
    : "";

  useEffect(() => {
    if (user) {
      console.log("user: ", user);

      const data = {
        email: user.email,
      };
      //console.log("The empty useEffect");
      dispatch(actionDataNomineeList(data));
    }
  }, []);

  useEffect(() => {
    const data = {
      email: user.email,
    };
    //console.log("The useEffect with added");
    dispatch(actionDataNomineeList(data));
  }, [nomineeAdded]);

  useEffect(() => {
    const data = {
      email: user.email,
    };
    console.log("The useEffect with removed");
    dispatch(actionDataNomineeList(data));
  }, [nomineeRemoved]);

  useEffect(() => {
    setIsModalOpen(false);
  }, [dataNomineeList]);

  const tableProps = {
    scroll: { x: "max-content" }, // Enable horizontal scrolling for the table
  };

  return (
    <div className="in-app-layout">
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Nominees</h1>
      </div>
      <div className="page-title-desc">
        <p>
          Nominees are your loved ones, to whom you can transfer your digital
          assets.
        </p>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Table
          className="table-container"
          columns={columns}
          dataSource={dataNomList}
          pagination={false}
          {...tableProps}
        />
      </div>
      <div className="horizontal-center">
        <Button
          type="primary"
          className="color-white"
          onClick={showAddNomineeModal}
          style={{ marginTop: "30px" }}
        >
          Add nominee
        </Button>
        <AddNominee
          isAddNomineeModalOpen={isAddNomineeModalOpen}
          handleAddNomineeCancel={handleAddNomineeCancel}
          user={user}
        ></AddNominee>
      </div>
    </div>
  );
}
