import {
  Form,
  Input,
  Button,
  message,
  Row,
  Progress,
  Result,
  Modal,
} from "antd";
import {
  EyeInvisibleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { shortenAddress } from "../helpers/ShortenAddress";
import SelectNominee from "../components/SelectNominee";
import axios from "axios";
import { AddNominee } from "../components/AddNominee";
import { actionAddDataNominee } from "../store/slices/nomineeSlice";
import loading from "../images/uploading.gif";
import encrypting from "../images/Card.gif";
import blockchain from "../images/blockchain.gif";

const inputCss = {
  width: 500,
  height: 40,
};
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function Notes() {
  const [credentials, setCredentials] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [percent, setPercent] = useState(0);
  const [isEncrypting, setIsEncrypting] = useState(false);
  const [isStoredInBlockchain, setisStoredInBlockchain] = useState(false);
  const [isStartedToStoreInBlockchain, setIsStartedToStoreInBlockchain] =
    useState(false);
  const [isNomineeSelected, setIsNomineeSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nomineeName, setNomineeName] = useState("");
  const [nomineeModal, setNomineeModal] = useState(false); // This modal state is for Add nominee
  const [selectedNominee, setSelectedNominee] = useState({});
  const [selectedNominees, setSelectedNominees] = useState([{}]);

  const location = useLocation();
  const heading = location.state?.heading;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataNomineeList = useSelector((state) => state.nominee.dataNomineeList);
  const user = useSelector((state) => state.account.user);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };
  const labels = {
    label1: "Name",
    label2: "Email",
    label3: "Number",
  };

  const onFinish = async (values) => {
    console.log(values);
    await uploadToIPFS(values.note.content);

    // dispatch(actionAddDataNominee(nomineeData))
  };

  const uploadToIPFS = async (notes) => {
    if (folderName) {
      for (let i = 0; i < user.files.length; i++) {
        if (user.files[i].folder === folderName) {
          for (let j = 0; j < selectedNominees.length; j++) {
            if (selectedNominees[j].nomineeEmail === user.files[i].nominee) {
              message.error("Folder name already exists");
              return;
            }
          }
        }
      }
      if (notes.length > 0) {
        if (selectedNominees.length > 0) {
          setIsModalOpen(true);

          const data = selectedNominees.map((nominee) => ({
            filesData: notes,
            folderName: folderName,
            nominee: nominee,
            user: user,
            filesNames: "notes",
            assetType: heading,
          }));

          let response = null;
          let responseData;

          try {
            const token = sessionStorage.getItem("accessToken");

            const options = {
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let tempPercent = Math.floor((loaded * 100) / total);
                console.log("percentage value is", tempPercent);
                setPercent(tempPercent);
                if (tempPercent === 100) {
                  setIsEncrypting(true);
                }
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            for (let i = 0; i < data.length; i++) {
              response = await axios.post(
                "/api/users/uploadNotes",
                data[i],
                options
              );
            }
            console.log("response in upload files: ", response.data);
            response = response.data;
            responseData = {
              cid: response.cid,
              securityKey: response.securityKey,
              uploadedStatus: true,
              uploadError: null,
            };
            setisStoredInBlockchain(true);
          } catch (err) {
            if (err.response) {
              console.log("err: ", err.response.data);
              responseData = {
                cid: null,
                securityKey: null,
                uploadedStatus: false,
                uploadError: err.response.data,
              };
            }
            console.log("only err:", err);
          }

          // dispatch(actionDataUpload(responseData))
          setIsStartedToStoreInBlockchain(true);
        } else {
          message.error("No nominee selected", 5);
          console.log("Not Selected Nominee");
        }
      } else {
        message.warning("No files selected");
        console.log("No Files Selected");
      }
    }
  };

  const addNomineeModal = () => {
    setNomineeModal(!nomineeModal);
  };

  const handleNextButton = () => {
    console.log("clicked");
    setIsNomineeSelected(true);
    const filteredNominees = dataNomineeList.filter(
      (data, key) => selectedNominee[key]
    );
    setSelectedNominees(filteredNominees);
    setNomineeName(filteredNominees.map((data) => data.nomineeName).join(", "));
    console.log("selectedNominee: ", filteredNominees);
  };

  const mapToSelect = dataNomineeList ? (
    dataNomineeList.map((data, key) => {
      const nomineeId = shortenAddress(data.nomineeId);
      const nomineeName = data.nomineeName;
      const nomineeEmail = data.nomineeEmail;
      const nomineePhone = data.nomineePhone;

      return (
        <div
          key={key}
          onClick={() =>
            setSelectedNominee((prev) => ({
              ...prev,
              [key]: !selectedNominee[key],
            }))
          }
        >
          <Row className="f-se">
            <SelectNominee
              nomineeId={nomineeId}
              nomineeName={nomineeName}
              nomineeEmail={nomineeEmail}
              nomineePhone={nomineePhone}
              isSelected={selectedNominee[key]}
            />
          </Row>
        </div>
      );
    })
  ) : (
    <div
      style={{
        display: "grid",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          margin: "auto",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginTop: "250px", fontSize: "20px" }}>
          No Nominees Available/Added. Add a New Nominee to get Started
        </div>
        <div
          style={{
            marginTop: "10px",
            fontSize: "15px",
            color: "#2754BA",
            cursor: "pointer",
          }}
          onClick={addNomineeModal}
        >
          + Add a New Nominee
        </div>
      </div>
    </div>
  );

  const onFinishAddNominee = (values) => {
    const accessToken = sessionStorage.getItem("accessToken");
    let nomineeData = {
      name: values.user.name,
      email: user.email,
      nomineeEmail: values.user.email,
      number: values.user.number,
      authorization: accessToken,
    };
    message.success(`Nominee added successfully`);
    dispatch(actionAddDataNominee(nomineeData));
    setNomineeModal(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsNomineeSelected(false);
    setisStoredInBlockchain(false);
    setIsStartedToStoreInBlockchain(false);
    setIsEncrypting(false);
  };

  const addNominee = (e) => {
    console.log("e: ", e);
  };
  return (
    <div className="upload-layout page-name">
      <div>
        {!isNomineeSelected ? (
          <>
            <div
              style={{
                display: "grid",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ justifyContent: "center" }}>
                  <div style={{ fontSize: "25px" }}>Personal Notes</div>
                  <div style={{ fontSize: "18px", marginTop: "13px" }}>
                    Select your nominee(s)
                  </div>
                </div>
                <div style={{ justifyContent: "right", marginTop: "auto" }}>
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px", background: "#123962" }}
                    className="color-white"
                    onClick={addNomineeModal}
                  >
                    Add nominee
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px", background: "#123962" }}
                    className="color-white"
                    onClick={handleNextButton}
                  >
                    Next
                  </Button>
                  <Button
                    type="primary"
                    className="color-white"
                    style={{ marginLeft: "10px", background: "#123962" }}
                    onClick={() => {
                      navigate("/chooseNotes");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>

              {dataNomineeList?.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    margin: "auto",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginTop: "250px", fontSize: "20px" }}>
                    No Nominees Available/Added. Add a New Nominee to get
                    Started
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "15px",
                      color: "#2754BA",
                      cursor: "pointer",
                    }}
                    onClick={addNomineeModal}
                  >
                    + Add a New Nominee
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                overflowY: "hidden",
                flexWrap: "wrap",
                marginTop: "40px",
              }}
            >
              {mapToSelect}
            </div>
          </>
        ) : (
          <>
            <div>
              <p className="f-s-2 ">Enter your notes here</p>
              <p style={{ marginTop: "7px" }}>
                Selected Nominee:{" "}
                <span style={{ fontWeight: "bold" }}>{nomineeName}</span>
              </p>
              <br />

              <Button
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  setIsNomineeSelected(false);
                  navigate("/choose");
                }}
              >
                Back
              </Button>
            </div>
            <div className="f-sec s-b">
              <div className="f-c">
                <Input
                  id="folderNameInput"
                  value={folderName}
                  placeholder="Folder name"
                  onChange={(e) => {
                    setFolderName(e.target.value);
                  }}
                />
              </div>
              <div className="f-c" style={{ width: "85%" }}>
                <Form
                  {...layout}
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  style={{ width: "100%" }}
                  className="f-col"
                >
                  <Form.Item
                    className="text-area-input"
                    name={["note", "content"]}
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Missing input",
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{ width: "100%" }}
                      rows={4}
                      placeholder="Please Type Your Message"
                      maxLength={50}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: 300,
                        marginRight: "20px",
                        backgroundColor: "#1CCC5B",
                      }}
                      className="info-button"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <Modal
                title="Files uploading..."
                open={isModalOpen}
                footer={null}
                closable={true}
              >
                <div>
                  {!isStartedToStoreInBlockchain ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!isEncrypting ? (
                        <div>
                          <img
                            src={loading}
                            width={200}
                            height={200}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            alt="percent"
                          />
                          <Progress percent={percent} />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={encrypting}
                            width={200}
                            height={200}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            alt="encrypting pic"
                          />
                          <h3
                            style={{
                              alignText: "center",
                            }}
                          >
                            Encrypting Files...
                          </h3>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        {!isStoredInBlockchain ? (
                          <div>
                            <img
                              src={blockchain}
                              width={200}
                              height={200}
                              alt="storing "
                            />
                            <h3
                              style={{
                                alignText: "center",
                              }}
                            >
                              Storing CID into Blockchain...
                            </h3>
                          </div>
                        ) : (
                          <div>
                            <Result
                              status="success"
                              title="Files upload successful"
                              extra={[
                                <Button
                                  className="modal-btn"
                                  type="primary"
                                  key="console"
                                  onClick={handleOk}
                                >
                                  Okay
                                </Button>,
                              ]}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          </>
        )}
      </div>
      <>
        <AddNominee
          nomineeModal={nomineeModal}
          setNomineeModal={setNomineeModal}
          layout={layout}
          onFinish={onFinishAddNominee}
          validateMessages={validateMessages}
          labels={labels}
          addNominee={addNominee}
        />
      </>
    </div>
  );
}
