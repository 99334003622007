import React, { useState } from "react";
import { passwordResetForEmail } from "../services/passwordResetForEmail";
import { Form, Button, Input, Card } from "antd";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  const [form] = Form.useForm();

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };

  const onSubmit = async (val) => {
    console.log(val.user.email);
    if (!val) return;
    const res = await passwordResetForEmail(val.user.email);
    console.log(res);
    form.resetFields();
  };

  return (
    <div style={{ paddingTop: "10rem", textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          paddingRight: "10rem",
        }}
      >
        <Link to="/login">
          <Button primary>Back</Button>
        </Link>
      </div>
      <h2>Forgot Password</h2>
      <Card style={{ padding: "3rem 20rem" }}>
        <Form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "20px",
            justifyItems: "center",
          }}
          requiredMark={"optional"}
          colon={false}
          name="user"
          onFinish={onSubmit}
          validateMessages={validateMessages}
          form={form}
        >
          <Form.Item
            name={["user", "email"]}
            style={{ justifyContent: "center" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder="Enter Your Email"
              className="input-add-nominee"
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button className="color-white" type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
