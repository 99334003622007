// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA9_nZ7msKuV4WhimUowICJIRB5EJ6AyYE",
    authDomain: "datanominee.firebaseapp.com",
    projectId: "datanominee",
    storageBucket: "datanominee.appspot.com",
    messagingSenderId: "60604731533",
    appId: "1:60604731533:web:81eb85e133080e501752c4",
    measurementId: "G-VN1PRY36W5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
export const analytics = getAnalytics(app);

export const providerGoogle = new GoogleAuthProvider();
export const providerFacebook = new FacebookAuthProvider();
export const providerTwitter = new TwitterAuthProvider();
export const providerGithub = new GithubAuthProvider();