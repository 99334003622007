import axios from "axios";

export const axiosPostInstance = (url, token, data) => {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${token}`
    },
    //proxy: process.env.PROXY_URL || "http://localhost:8080",
  });
  return axiosInstance.post(url, data);
};
