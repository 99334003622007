import ChoiceCard from "../components/ChoiceCard";
import insuarances from "../images/insuarances.svg"
import fds from "../images/fds.svg"
import liquidGold from "../images/liquidGold.svg"
import realEstate from "../images/realEstate.svg"
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import stocks from "../images/stocks.svg"
import mutualFunds from "../images/mutualFunds.svg"
import bonds from "../images/bonds.svg"

const cardData = [
    {
        image: insuarances,
        heading: "Insurances",
        route: "/upload"
    },
    {
        image: fds,
        heading: "FDs",
        route: "/upload"
    },
    {
        image: liquidGold,
        heading: "Liquid Gold",
        route: "/upload"
    },
    {
        image: realEstate,
        heading: "Real Estate",
        route: "/upload"
    },
    {
        image: stocks,
        heading: "Stocks",
        route: "/upload"
    },
    {
        image: mutualFunds,
        heading: "Mutual Funds",
        route: "/upload"
    },
    {
        image: bonds,
        heading: "Bonds",
        route: "/upload"
    }
]

export default function LiquidAssets() {
    const navigate = useNavigate();
    return (
        <div className="in-app-layout">
            <div className="page-title">
                <h1 style={{ margin: "0" }}>Liquid Assets</h1>
            </div>
            <div style={{ float: 'right' }}>
                <Button type="primary" style={{ marginLeft: "10px", background: "rgb(18, 57, 98)" }} className="color-white"
                    onClick={() => {
                        navigate("/choose")
                    }}>
                    Back
                </Button>
            </div>
            <div className="page-title-desc">
                <p>Select Type of Liquid Asset</p>
            </div>
            <div className="row" style={{ marginTop: "40px", display: "flex", flexWrap: "wrap" }}>
                {cardData.map((card, index) => (

                    <ChoiceCard key={index} image={card.image} heading={card.heading} desc={card.desc}
                        route={card.route} prevPage={"/liquidAssets"} square={true} />
                ))}
            </div>
        </div>
    );
}