import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/sidebar.css";
import { SidebarData } from "./SidebarData";
import logo from "../images/logo.svg";

export default function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="sidebar">
      <ul className="sidebarList">
        <div className="top-div">
          <img src={logo} width="180px" alt="logo" />
        </div>
        {SidebarData.map((val, key) => {
          return (
            <li
              key={key}
              className="row"
              style={{
                paddingLeft: "0px",
                borderRadius: "18px 18px 18px 18px",
              }}
              id={window.location.pathname === val.link ? "active" : ""}
              onClick={() => navigate(val.link)}
            >
              <div id="icon">
                <img src={val.icon} />
              </div>
              <div style={{ fontSize: "16px", fontWeight: "700" }} id="title">
                {val.title}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
