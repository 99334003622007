import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Steps,
  Layout,
} from "antd";
import "react-phone-number-input/style.css";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { actionUserEditReferee } from "../store/slices/accountSlice";

export default function EditRef(props) {
  const user = useSelector((state) => state.account.user);
  const dispatch = useDispatch();
  let data = useLocation();
  const handleRefereeSubmit = async (values) => {
    console.log("values: ", values);
    const refereeData = values.referees;
    const dataToSend = {
      email: user.email,
      id: data.state.record.id,
      referee: refereeData,
    };
    dispatch(actionUserEditReferee(dataToSend));
  };

  return (
    <div>
      <Layout>
        <div className="page-title">
          <h1 style={{ margin: "0" }}>Edit Referee</h1>
        </div>
        <div className="page-title-desc">
          <p>Update details</p>
        </div>
        <Form name="basic" onFinish={handleRefereeSubmit}>
          <Form.List
            name={"referees"}
            initialValue={[
              {
                name: "",
                email: "",
                phoneNumber: "",
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <div className="credentials-card" key={key}>
                      <Row className="credentials-card-row" gutter={32}>
                        <Col span={12}>
                          <Form.Item
                            style={{ width: "100%" }}
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing name",
                                type: "string",
                              },
                            ]}
                          >
                            <Input
                              rootClassName="ref-input"
                              placeholder={"Enter Referee Name"}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            style={{ width: "100%" }}
                            name={[name, "email"]}
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Missing email",
                              },
                            ]}
                          >
                            <Input
                              rootClassName="ref-input"
                              placeholder={data.state.record.email}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            style={{ width: "100%" }}
                            name={[name, "phoneNumber"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing phone number",
                                type: "text",
                              },
                            ]}
                          >
                            <PhoneInput
                              required
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Col>
                    <Row>
                      <Form.Item>
                        <Button
                          style={{ fontSize: "1.2rem", fontWeight: 300 }}
                          className="info-button"
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Row>
                  </Col>
                </div>
              </>
            )}
          </Form.List>
        </Form>
      </Layout>
    </div>
  );
}
