import { Button, Divider, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { axiosPostInstance } from "../utils/axiosPostInstance";
import { actionGetPaymentResult } from "../store/slices/accountSlice";
import { useState } from "react";
import getPlans from "../helpers/getPlans";
import PlanCard from "../components/PlanCard";
import { useEffect } from "react";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default function Subscription() {
  const [monthlyPlansState, setMonthlyPlansState] = useState();
  const [yearlyPlansState, setYearlyPlansState] = useState();

  const user = useSelector((state) => state.account.user);
  const dispatch = useDispatch();

  const makePayment = async (code) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let response;
    const token = sessionStorage.getItem("accessToken");
    console.log("Setting token:", token);
    try {
      response = await axiosPostInstance("/api/users/orders", token, {
        email: user.email,
        code,
      });
      const { amount, id: order_id, currency, receipt } = response.data;

      const options = {
        key: "rzp_test_XJ6NCXmvrHxZpz", // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Datanominee",
        description: "Payment for subscription",
        order_id: order_id,
        handler: async function (response) {
          const data = {
            email: user.email,
            receipt: receipt,
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          dispatch(actionGetPaymentResult(data));
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.phone,
        },
        notes: {
          address: "Datanominee",
        },
        theme: {
          color: "#bacff7",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (err) {
      console.log("error:", err);
      message.error(`Error in getting order id`);
    }
  };

  useEffect(() => {
    var { monthlyPlans, yearlyPlan } = getPlans();

    setMonthlyPlansState(monthlyPlans);
    setYearlyPlansState(yearlyPlan);
    console.log("Plans: ", monthlyPlans, yearlyPlan);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        background: "white",
      }}
    >
      <div
        className="row"
        style={{
          backgroundColor: "#e1e8f5",
          borderRadius: 15,
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontWeight: "700",
            color: "#123962",
            textAlign: "center",
            paddingTop: "1rem",
            paddingBottom: "0.5rem",
          }}
        >
          <p>Subscription Plans</p>
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#123962",
            textAlign: "center",
            paddingBottom: "0.5rem",
          }}
        >
          <p>Current Plan: {user?.premiumAccount}</p>
        </div>
        <Divider>Yearly Plans</Divider>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            borderRadius: 20,
            gap: "1rem",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          {monthlyPlansState &&
            monthlyPlansState.length > 0 &&
            monthlyPlansState?.map((plan, index) => (
              <PlanCard
                key={index}
                planName={plan.name}
                amount={plan.amount}
                features={plan.features}
                handleClick={() => makePayment(plan?.amountToCollect)}
                buttonText={"Subscribe"}
              />
            ))}
        </div>
        <Divider>Life Time Plans</Divider>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            borderRadius: 20,
            gap: "1rem",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          {yearlyPlansState?.map((plan, index) => (
            <PlanCard
              key={index}
              planName={plan.name}
              amount={plan.amount}
              features={plan.features}
              handleClick={() => makePayment(plan?.amountToCollect)}
              buttonText={"Subscribe"}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
