import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPostInstance } from "../../utils/axiosPostInstance";
import { axiosDeleteInstance } from "../../utils/axiosDeleteInstance";

export const actionClaimFiles = createAsyncThunk(
  "upload/actionClaimFiles",
  async (data) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log(data);
      response = await axiosPostInstance("/api/users/claimfiles", token, data);
      // console.log("response: ", response.data);
      return { response: response.data };
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
  }
);

export const actionClaimCred = createAsyncThunk(
  "upload/actionClaimCred",
  async (data) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log(data);
      response = await axiosPostInstance("/api/users/claimCred", token, data);
      // console.log("response: ", response.data);
      return { response: response.data };
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
  }
);

export const actionDeleteFiles = createAsyncThunk(
  "upload/actionDeleteFiles",
  async (data) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosDeleteInstance(
        "/api/users/deletefiles",
        token,
        data
      );
      return { response: response.data.files };
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
  }
);

export const actionDeleteFilesCred = createAsyncThunk(
  "upload/actionDeleteFilesCred",
  async (data) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosDeleteInstance(
        "/api/users/deletefilesCred",
        token,
        data
      );
      return { response: response.data.cred };
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
  }
);

export const actionGetFiles = createAsyncThunk(
  "upload/actionGetFiles",
  async (data) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log("data", data);
      response = await axiosPostInstance(
        "/api/users/uploadedFilesList",
        token,
        data
      );
      // console.log("response uploadedFilesList: ", response.data);
      return { response: response.data };
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
  }
);

export const actionGetCred = createAsyncThunk(
  "upload/actionGetCred",
  async (data) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      // console.log("data", data);
      response = await axiosPostInstance(
        "/api/users/uploadedCredList",
        token,
        data
      );
      return { response: response.data };
    } catch (err) {
      console.log("err: ", err.response.data);
      return err.response.data;
    }
  }
);

const initialState = {
  cid: null,
  securityKey: null,
  uploadedStatus: null,
  files: null,
  uploadError: null,
  claimFiles: null,
  cred: null,
  claimCred: null,
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    actionDataUpload: (state, action) => {
      console.log("action payload is:", action.payload);
      state.cid = action.payload.cid;
      state.securityKey = action.payload.securityKey;
      state.uploadedStatus = action.payload.uploadedStatus;
      state.uploadError = action.payload.uploadError;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(actionGetFiles.pending, (state) => {})
      .addCase(actionGetFiles.fulfilled, (state, action) => {
        state.files = action.payload.response;
      })
      .addCase(actionGetFiles.rejected, (state, action) => {
        console.log("error: ", action.payload);
      })

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionDeleteFiles.pending, (state) => {})
      .addCase(actionDeleteFiles.fulfilled, (state, action) => {
        state.files = action.payload.response;
      })
      .addCase(actionDeleteFiles.rejected, (state, action) => {
        console.log("error: ", action.payload);
      })

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionClaimFiles.pending, (state) => {})
      .addCase(actionClaimFiles.fulfilled, (state, action) => {
        state.claimFiles = action.payload.response;
      })
      .addCase(actionClaimFiles.rejected, (state, action) => {
        console.log("error: ", action.payload);
      })

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionClaimCred.pending, (state) => {})
      .addCase(actionClaimCred.fulfilled, (state, action) => {
        state.claimCred = action.payload.response;
      })
      .addCase(actionClaimCred.rejected, (state, action) => {
        console.log("error: ", action.payload);
      })

      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionGetCred.pending, (state) => {})
      .addCase(actionGetCred.fulfilled, (state, action) => {
        state.cred = action.payload.response;
      })
      .addCase(actionGetCred.rejected, (state, action) => {
        console.log("error: ", action.payload);
      })
      /////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionDeleteFilesCred.pending, (state) => {})
      .addCase(actionDeleteFilesCred.fulfilled, (state, action) => {
        state.cred = action.payload.response;
      })
      .addCase(actionDeleteFilesCred.rejected, (state, action) => {
        console.log("error: ", action.payload);
      });
  },
});

// Action creators are generated for each case reducer function
// export const { setUserData } = accountSlice.actions

export const { actionDataUpload } = uploadSlice.actions;
export default uploadSlice.reducer;
