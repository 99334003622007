import React from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "../../src/css/style.css";

import loginVector from "../images/people-collaborating-with-tech- 1.svg";
import { LoginCard } from "../components/LoginCard";

export default function LoginSignup() {
  const navigate = useNavigate();
  // window.$crisp.push(['do', 'chat:hide']);
  const profileSetup = useSelector((state) => state.account.profileSetup);
  const user = useSelector((state) => state.account.user);

  const checkLoggedInLocalStorage = () => {
    return localStorage.getItem("loggedIn") === "true";
  };

  useEffect(() => {
    console.log("ProfileSetup:", profileSetup);
    const loggedIn = checkLoggedInLocalStorage();
    console.log("User:", user);
    console.log("loggedIn:", loggedIn);
    if (profileSetup && loggedIn && user) {
      navigate("/dashboard");
    }
    if (!profileSetup && loggedIn && user) {
      navigate("/info");
    }
  }, [profileSetup, checkLoggedInLocalStorage]);

  return (
    <div className="login-page-bg">
      <Row style={{ marginTop: "3rem" }}>
        <Col
          className="horizontal-center loginimagediv"
          span={12}
          style={{ padding: "2rem" }}
        >
          <img height="100%" width="100%" src={loginVector} />
        </Col>
        <Col
          className="horizontal-center"
          id="logincol"
          span={12}
          style={{ padding: "3rem 6rem" }}
        >
          <LoginCard />
        </Col>
      </Row>
    </div>
  );
}
