import React, { useEffect, useState } from "react";
import { Layout, Popover, Button, Space, Card, Row, Col } from "antd";
import {
  Link,
  Navigate,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../components/Sidebar";
import Headerx from "../components/Header";
import { Header } from "antd/lib/layout/layout";
import { Tabs } from "antd";
import { MessageOutlined, UserOutlined, MoreOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const { Content, Sider, Footer } = Layout;

export default function UserLayout() {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [activeKey, setActiveKey] = useState();

  const profileSetup = useSelector((state) => state.account.profileSetup);
  const user = useSelector((state) => state.account.user);

  const navigate = useNavigate();
  const location = useLocation();

  const contentForPopover = (
    <div>
      <Space direction="vertical" size={12} style={{ fontSize: "0.8rem" }}>
        <p
          onClick={() => {
            navigate("/nominations");
          }}
        >
          Assigned
        </p>
        <p
          onClick={() => {
            navigate("/claims");
          }}
        >
          Claims
        </p>
        <p
          onClick={() => {
            navigate("/settings");
          }}
        >
          Settings
        </p>
      </Space>
    </div>
  );

  const checkLoggedInLocalStorage = () => {
    return localStorage.getItem("loggedIn") === "true";
  };
  const loggedIn = checkLoggedInLocalStorage();

  const handleMoreTabClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  if (profileSetup && user && loggedIn) {
    return (
      <div>
        <Layout className="entireView">
          <Sider className="sidersidersider">
            <Sidebar />
          </Sider>
          <Layout>
            <Header>
              <Headerx />
            </Header>
            <Content className="container">
              <div className="content">
                <Outlet />
              </div>
              <div className="bottomNavbar">
                <Row
                  className="bottomRowBar"
                  style={{
                    paddingTop: "4px",
                    marginBottom: "2px",
                  }}
                >
                  <Space direction="horizontal">
                    <div
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                      className="bottomNavBarCard"
                    >
                      <MessageOutlined />
                      Home
                    </div>
                    <div
                      onClick={() => {
                        navigate("/choose");
                      }}
                      className="bottomNavBarCard"
                    >
                      <MessageOutlined />
                      Upload
                    </div>
                    <div
                      onClick={() => {
                        navigate("/UploadedFiles");
                      }}
                      className="bottomNavBarCard"
                    >
                      <UserOutlined />
                      Assets
                    </div>
                    <div
                      onClick={() => {
                        navigate("/nomineelist");
                      }}
                      className="bottomNavBarCard"
                    >
                      <UserOutlined />
                      Nominees
                    </div>
                    <Popover
                      content={contentForPopover}
                      trigger="click"
                      visible={isPopoverVisible}
                      onVisibleChange={handleMoreTabClick}
                      overlayStyle={{ minWidth: "6rem" }}
                    >
                      <div className="bottomNavBarCard">
                        {" "}
                        <MoreOutlined size={200} />
                        More
                      </div>
                    </Popover>
                  </Space>
                </Row>
              </div>
              {/* <div className="bottomNavbar">
                <Tabs
                  tabBarStyle={{ width: "100%" }}
                  type="card"
                  tabBarGutter={2} // Adjust the value as needed for spacing between tabs
                  tabBarClassName="customTabBar" // Add a custom class for styling
                >
                  <TabPane
                    tab={
                      <span
                        className="customTab"
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        <MessageOutlined />
                        Home
                      </span>
                    }
                    key="1"
                  ></TabPane>
                  <TabPane
                    tab={
                      <span
                        className="customTab"
                        onClick={() => {
                          navigate("/choose");
                        }}
                      >
                        <MessageOutlined />
                        Upload
                      </span>
                    }
                    key="2"
                  ></TabPane>
                  <TabPane
                    tab={
                      <span
                        className="customTab"
                        onClick={() => {
                          navigate("/UploadedFiles");
                        }}
                      >
                        <UserOutlined />
                        Assets
                      </span>
                    }
                    key="3"
                  >
                  </TabPane>
                  <TabPane
                    tab={
                      <span
                        className="customTab"
                        onClick={() => {
                          navigate("/nomineelist");
                        }}
                      >
                        <UserOutlined />
                        Nominees
                      </span>
                    }
                    key="4"
                  >
                  </TabPane>
                  <TabPane
                    tab={
                      <Popover
                        content={contentForPopover}
                        trigger="click"
                        visible={isPopoverVisible}
                        onVisibleChange={handleMoreTabClick}
                        overlayStyle={{ minWidth: "8rem", fontSize: "1.2rem" }}
                      >
                        <span className="customTab">
                          <MoreOutlined />
                          More
                        </span>
                      </Popover>
                    }
                    key="5"
                  ></TabPane>
                </Tabs>
              </div> */}
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  } else if (!profileSetup && user && loggedIn) {
    return <Navigate to="/info" />;
  } else {
    return <Navigate to="/login" />;
  }
}
