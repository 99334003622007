export const arrayBufferToString = (buf) => {
    console.log("In arrayBufferToString")
    var str = '';
    var bytes = new Uint8Array(buf);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }

    return str;
}
