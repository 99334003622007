import React, { useState } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import axios from "axios";
import { Button, message, Modal, Input, Result } from "antd";

import { stringToArrayBuffer } from "../helpers/stringToArrayBuffer";
import { axiosPostInstance } from "../utils/axiosPostInstance";

export default function DownloadCredModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initVector, setInitVector] = useState();
  const [downloaded, setDownloaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.account.user);

  const record = props.record;

  const onChangeInitVector = (e) => {
    setInitVector(e.target.value);
  };

  const download = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!initVector && !user) return;
    console.log("Record: ", record);
    var email = record.recordEmail;
    var nomineeEmail = user.email;
    const keys = {
      initVector: initVector,
      nominatorEmail: email,
      email: email,
      nomineeEmail: nomineeEmail,
      folderName: record.name,
    };
    console.log("keys are:", keys);
    let response;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance(
        "/api/users/downloadCred",
        token,
        keys
      );
    } catch (err) {
      message.error(`Incorrect PIN`);
      console.log("error:", err);
      return;
    }
    console.log("response: ", response);

    const buf2 = stringToArrayBuffer(response.data.decryptedData[0]);
    const extension = response.data.extension;
    const blob = new Blob([buf2], { type: "application/zip" }); // JavaScript Blob
    console.log("buff: ", buf2, " blob: ", blob);
    saveAs(blob, record.name + extension);

    message.success(`Asset Downloaded Successfully`);
    console.log("response:", response);
    setDownloaded(true);
    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setDownloaded(false);
    setIsModalOpen(false);
    setLoading(false);
  };

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Download
      </Button>

      <Modal
        title="Download files"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {downloaded ? (
          <div className="fc">
            <Result
              status="success"
              title="Asset Download Successful"
              extra={[
                <Button
                  className="modal-btn"
                  type="primary"
                  key="console"
                  onClick={handleCancel}
                >
                  Ok Close
                </Button>,
              ]}
            />
          </div>
        ) : (
          <div className="fc">
            <p>Are you sure you want to download the files?</p>
            <br />
            <Button
              loading={loading}
              className="modal-btn"
              type="primary"
              key="console"
              onClick={download}
            >
              Submit
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}
