import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPostInstance } from "../../utils/axiosPostInstance";

console.log(sessionStorage.getItem("accessToken"));

export const actionUserLog = createAsyncThunk(
  "account/actionUserLog",
  async (userData, { rejectWithValue }) => {
    let response = null;
    console.log("Inside log");
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance("/api/users/log", token, userData);
      console.log("Response login: ", response);
    } catch (err) {
      console.log("err: ", err);
      return rejectWithValue(err.response.data);
    }
    console.log("response: ", response);
    return { response: response.data };
  }
);

export const actionUserSetup = createAsyncThunk(
  "account/actionUserSetup",
  async (userData, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance("/api/users/setup", token, userData);
      console.log("Response: ", response);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    console.log("response: ", response);
    return { response: response.data };
  }
);

export const actionDeleteReferee = createAsyncThunk(
  "account/actionDeleteReferee",
  async (data, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance(
        "/api/users/deleteReferees",
        token,
        data
      );
      console.log("Response: ", response.data);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    return { response: response.data };
  }
);

export const actionUserAddReferees = createAsyncThunk(
  "account/actionUserAddReferees",
  async (userData, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance(
        "/api/users/addReferees",
        token,
        userData
      );
      console.log("Response: ", response);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    console.log("response: ", response);
    return { response: response.data };
  }
);

export const actionUserEditReferee = createAsyncThunk(
  "account/actionUserEditReferee",
  async (userData, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance(
        "/api/users/editReferees",
        token,
        userData
      );
      console.log("Response: ", response);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    console.log("response: ", response);
    return { response: response.data };
  }
);

export const actionUserLogout = createAsyncThunk(
  "account/actionUserLogout",
  async (userData, { rejectWithValue }) => {
    let response = {
      data: {
        profileSetup: null,
        user: null,
      },
    };
    console.log("response: ", response);
    return { response: response.data };
  }
);

export const actionGetNominationsList = createAsyncThunk(
  "account/actionGetNominationsList",
  async (userData, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance(
        "/api/users/nominations",
        token,
        userData
      );
      console.log("Response: ", response.data);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    return { response: response.data };
  }
);

export const actionGetClaimsList = createAsyncThunk(
  "account/actionGetClaimsList",
  async (userData, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance("/api/users/claims", token, userData);
      console.log("Response: ", response.data);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    return { response: response.data };
  }
);

export const actionGetPaymentResult = createAsyncThunk(
  "account/actionGetPaymentResult",
  async (data, { rejectWithValue }) => {
    let response = null;
    try {
      const token = sessionStorage.getItem("accessToken");
      response = await axiosPostInstance("/api/users/success", token, data);
      console.log("Response: ", response.data);
    } catch (err) {
      console.log("err: ", err.response.data);
      return rejectWithValue(err.response.data);
    }
    return { response: response.data };
  }
);

const initialState = {
  error: null,
  profileSetup: null,
  nominationsList: null,
  claimList: null,
  user: null,
  paymentDetails: null,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    actionUpdateUser: (state, action) => {
      console.log("action payload accountSlice is:", action.payload);
      state.user = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionGetNominationsList.pending, (state) => {
        state.nominationsList = [];
      })
      .addCase(actionGetNominationsList.fulfilled, (state, action) => {
        console.log(
          "action get nominations list success:",
          action.payload.response
        );
        state.nominationsList = action.payload.response;
        console.log("in state data is:", state.nominationsList);
      })
      .addCase(
        actionGetNominationsList.rejected,
        (state, { meta, payload, error }) => {
          state.error = error;
          console.log("error: ", error, payload, meta);
        }
      )

      ///////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionGetClaimsList.pending, (state) => {
        state.claimList = [];
      })
      .addCase(actionGetClaimsList.fulfilled, (state, action) => {
        console.log(
          "action get nominations list success:",
          action.payload.response
        );
        state.claimList = action.payload.response;
        console.log("in state data is:", state.claimList);
      })
      .addCase(
        actionGetClaimsList.rejected,
        (state, { meta, payload, error }) => {
          state.error = error;
          console.log("error: ", error, payload, meta);
        }
      )

      ///////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionGetPaymentResult.pending, (state) => {})
      .addCase(actionGetPaymentResult.fulfilled, (state, action) => {
        state.user = action.payload.response.user;
        console.log("actionGetPaymentResult success:", action.payload.response);
      })
      .addCase(
        actionGetPaymentResult.rejected,
        (state, { meta, payload, error }) => {
          state.error = error;
          console.log("error: ", error, payload, meta);
        }
      )

      ///////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionUserLog.pending, (state) => {})
      .addCase(actionUserLog.fulfilled, (state, action) => {
        state.profileSetup = action.payload.response.profileSetup;
        state.user = action.payload.response.user;
        console.log("actionUserLog success:", action.payload.response);
      })
      .addCase(actionUserLog.rejected, (state, { meta, payload, error }) => {
        state.profileSetup = false;
        state.error = { error, payload };
        console.log("error: ", error, payload, meta);
      })

      ///////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionUserSetup.pending, (state) => {})
      .addCase(actionUserSetup.fulfilled, (state, action) => {
        state.profileSetup = action.payload.response.profileSetup;
        state.user = action.payload.response.user;
      })
      .addCase(actionUserSetup.rejected, (state, { meta, payload, error }) => {
        state.profileSetup = false;
        state.error = { error, payload };
        console.log("error: ", error, payload, meta);
      })

      ///////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionDeleteReferee.pending, (state) => {})
      .addCase(actionDeleteReferee.fulfilled, (state, action) => {
        state.profileSetup = action.payload.response.profileSetup;
        state.user = action.payload.response.user;
      })
      .addCase(
        actionDeleteReferee.rejected,
        (state, { meta, payload, error }) => {
          console.log("error: ", error, payload, meta);
        }
      )
      ///////////////////////////////////////////////////////////////////////////////////////////

      .addCase(actionUserAddReferees.pending, (state) => {})
      .addCase(actionUserAddReferees.fulfilled, (state, action) => {
        state.profileSetup = action.payload.response.profileSetup;
        state.user = action.payload.response.user;
      })
      .addCase(
        actionUserAddReferees.rejected,
        (state, { meta, payload, error }) => {
          state.profileSetup = false;
          state.error = { error, payload };
          console.log("error: ", error, payload, meta);
        }
      )

      ///////////////////////////////////////////////////////////////////////////////////////////
      .addCase(actionUserEditReferee.pending, (state) => {})
      .addCase(actionUserEditReferee.fulfilled, (state, action) => {
        state.profileSetup = action.payload.response.profileSetup;
        state.user = action.payload.response.user;
      })
      .addCase(
        actionUserEditReferee.rejected,
        (state, { meta, payload, error }) => {
          state.profileSetup = false;
          state.error = { error, payload };
          console.log("error: ", error, payload, meta);
        }
      )

      ///////////////////////////////////////////////////////////////////////////////////////////
      .addCase(actionUserLogout.pending, (state) => {})
      .addCase(actionUserLogout.fulfilled, (state, action) => {
        state.profileSetup = false;
        state.user = action.payload.response.user;
      })
      .addCase(actionUserLogout.rejected, (state, { meta, payload, error }) => {
        state.profileSetup = false;
        state.error = { error, payload };
        console.log("error: ", error, payload, meta);
      });
  },
});

// Action creators are generated for each case reducer function
export const { actionUpdateUser } = accountSlice.actions;

export default accountSlice.reducer;
