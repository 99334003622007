import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

export default function ChoiceCard(props) {
  const navigate = useNavigate();
  let c = "choice-card";

  if (props.colorChange) {
    c = "choice-card-square-colorChange";
  } else if (props.square) {
    c = "choice-card-square";
  }

  return (
    <div
      className={c}
      onClick={() => {
        navigate(props.route, { state: { heading: props.heading } });
      }}
    >
      <Col>
        <Row className="choice-card-image" span={12}>
          <img src={props.image} />
        </Row>
        <Row className="choice-card-image" span={12}>
          <p className="big-name">{props.heading}</p>
          <p style={{ padding: 10 }}>{props.desc || ""}</p>
        </Row>
      </Col>
    </div>
  );
}
