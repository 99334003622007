import { Button, Form, Input, InputNumber, Table } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actionGetNominationsList } from "../store/slices/accountSlice";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function Nominations() {
  const user = useSelector((state) => state.account.user);
  const nominationsList = useSelector((state) => state.account.nominationsList);
  const dispatch = useDispatch();
  const nominationsListArray = [];

  const columns = [
    {
      title: "Nominator Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "",
      dataIndex: "claim",
      key: "claim",
      align: "center",
      width: "5%",
      className: "column-title",
      render: (text, _record) => {
        console.log("record: ", _record);
        return (
          <div>
            <Button style={{ background: "#feba02" }}>
              <Link to="/claim" state={{ record: _record }}>
                Claim
              </Link>
            </Button>
          </div>
        );
      },
    },
  ];

  const data =
    nominationsList.length > 0
      ? nominationsList.map((data, key) => {
          nominationsListArray.push({
            key: key,
            name: data.nominatorName,
            email: data.nominatorEmail,
            phone: data.nominatorPhone,
          });
        })
      : "";

  useEffect(() => {
    if (user) {
      const userData = {
        email: user.email,
      };
      dispatch(actionGetNominationsList(userData));
      console.log("in effect: ", nominationsList);
    }
  }, []);

  const tableProps = {
    scroll: { x: "max-content" }, // Enable horizontal scrolling for the table
  };

  return (
    <div className="in-app-layout">
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Assigned To Me</h1>
      </div>
      <div className="page-title-desc">
        <p>
          Nominators are the people who chose you as their Nominee. In case of
          Nominators death, please collect their digital assets by verifying
          their death.
        </p>
      </div>

      <div style={{ marginTop: "30px" }}>
        <Table
          className="table-container"
          columns={columns}
          dataSource={nominationsListArray}
          pagination={false}
          {...tableProps}
        />
      </div>
    </div>
  );
}
