import React from "react";
import { dashboardi } from "../images";
import { uploadi } from "../images";
import { nomineesi } from "../images";
import { uploadedFilesi } from "../images";
import { settingsi } from "../images";
import { nominationsi } from "../images";
import { claimsi } from "../images";
// import { logOuti } from "../images";

export const SidebarData = [
  {
    title: "Dashboard",
    icon: dashboardi,
    link: "/dashboard",
  },
  {
    title: "Upload",
    icon: uploadi,
    link: "/choose",
  },
  {
    title: "Nominees",
    icon: nomineesi,
    link: "/nomineelist",
  },
  {
    title: "Assigned To Me",
    icon: nominationsi,
    link: "/nominations",
  },
  {
    title: "Claims",
    icon: claimsi,
    link: "/claims",
  },
  {
    title: "Assets",
    icon: uploadedFilesi,
    link: "/UploadedFiles",
  },
  {
    title: "Settings",
    icon: settingsi,
    link: "/settings",
  },
];
