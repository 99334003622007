import "../css/style.css";

import React, { useEffect, useState } from "react";

import { signInWithGoogle } from "../services/signInGoogle";
import { signInWithFacebook } from "../services/signInFacebook";
import { signInWithTwitter } from "../services/signInTwitter";
import { signInWithGithub } from "../services/signInGithub";
import { signInWithEmail } from "../services/signInWithEmail";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actionUserLog } from "../store/slices/accountSlice";
import { useFirebaseAuthContext } from "../contexts/FirebaseVerification";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const LoginCard = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUserLogin } = useFirebaseAuthContext();
  const profileSetup = useSelector((state) => state.account.profileSetup);
  const error = useSelector((state) => state.account.error);
  const user = useSelector((state) => state.account.user);

  useEffect(() => {
    if (profileSetup) {
      setUserLogin(user);
    }
    if (error) {
      console.log("error", error);
      // openNotificationWithIcon("error", error.payload.error);
    }
  }, [profileSetup, error, user]);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: "Error logging in.",
      description: message,
    });
  };

  async function handleEmailSubmit(event) {
    event.preventDefault();
    const res = await signInWithEmail(email, password);
    console.log("res: ", res);

    if (res.code === 200 && res.user.accessToken) {
      const accessToken = res.user.accessToken;
      // set access token in session storage
      sessionStorage.setItem("accessToken", accessToken);

      const userData = {
        email: res.user.email,
        uid: res.user.uid,
        name: res.user.displayName,
        photoURL: res.user.photoURL,
      };
      dispatch(actionUserLog(userData));
      setUserLogin(res.user);
    } else {
      openNotificationWithIcon("error", res.message);
    }
  }

  async function signInWithProvider(provider) {
    let response = null;
    if (provider === "google") response = await signInWithGoogle();
    if (provider === "twitter") response = await signInWithTwitter();
    if (provider === "github") response = await signInWithGithub();
    if (provider === "facebook") response = await signInWithFacebook();
    console.log("response res", response);
    const res = response.result;
    if (response.code === 200) {
      const accessToken = res.user.accessToken;
      // set access token in session storage
      console.log("accessToken", accessToken);
      sessionStorage.setItem("accessToken", accessToken);

      const userData = {
        email: res.user.email,
        uid: res.user.uid,
        name: res.user.displayName,
        photoURL: res.user.photoURL,
      };
      dispatch(actionUserLog(userData));
      setUserLogin(res.user);
      console.log(profileSetup);
    } else {
      openNotificationWithIcon("error", response.message);
    }
  }

  function handleEmailChange(event) {
    setEmail(event.currentTarget.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.currentTarget.value);
  }

  return (
    <div className="login-card">
      <div>
        <div className="w3a-modal__header">
          <div className="w3a-header">
            <div>
              <div className="w3a-header__title">Welcome to Data Nominee</div>
              <div className="w3a-header__title">Sign In</div>
            </div>
          </div>
        </div>

        <div className="w3a-modal__content">
          <div className="w3ajs-social-logins w3a-group">
            <ul className="w3a-adapter-list">
              <li className="w3a-adapter-item">
                <button
                  type="button"
                  className="w3a-button w3a-button--icon"
                  onClick={() => signInWithProvider("google")}
                >
                  <img
                    src="https://images.web3auth.io/login-google.svg"
                    height="auto"
                    width="auto"
                    alt="login-google"
                  />
                </button>
              </li>
              <li className="w3a-adapter-item">
                <button
                  type="button"
                  className="w3a-button w3a-button--icon"
                  onClick={() => signInWithProvider("facebook")}
                >
                  <img
                    src="https://images.web3auth.io/login-facebook.svg"
                    height="auto"
                    width="auto"
                    alt="login-facebook"
                  />
                </button>
              </li>
            </ul>
          </div>
          <div className="w3ajs-email-passwordless w3a-group w3a-group--email">
            <form
              className="w3ajs-email-passwordless-form"
              onSubmit={handleEmailSubmit}
            >
              <input
                className="w3a-text-field"
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                required
                onChange={handleEmailChange}
              />
              <input
                className="w3a-text-field"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                required
                onChange={handlePasswordChange}
              />
              <p
                className="w3a-fp"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <span> </span>
                <Link to="/resetpassword"> Forgot password ?</Link>
                <a className="w3a-fp-a" href={"/register"}></a>
              </p>
              <button className="login-button" type="submit">
                Continue with Email
              </button>
              <button
                className="register-button"
                onClick={() => navigate("/register")}
              >
                New here? Sign Up
              </button>
              {contextHolder}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
