import loading from "../images/uploading.gif";
import encrypting from "../images/Card.gif";
import blockchain from "../images/blockchain.gif";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOutlined,
  FileTextOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  List,
  Col,
  Row,
  message,
  Upload,
  Result,
  Modal,
  Progress,
  notification,
  Select,
  Tooltip,
  Switch,
  Divider,
  Popover,
} from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";

import { actionDataUpload } from "../store/slices/uploadSlice";
import { arrayBufferToString } from "../helpers/arrayBufferToString";
import { actionUpdateUser } from "../store/slices/accountSlice";
import { AddNominee } from "../components/AddNominee";
import { UpgradeToPremium } from "../components/UpgradeToPremium";

const JSZip = require("jszip");
const { Dragger } = Upload;
const popoverContent = (
  <div>
    <Link to="/subscription">
      <Button type="primary">Upgrade</Button>
    </Link>
  </div>
);

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export default function UploadFiles() {
  const [files, setFiles] = useState([]);
  const [filesNames, setFilesNames] = useState([]);
  const [folderName, setFolderName] = useState();
  const [percent, setPercent] = useState(0);
  const [isEncrypting, setIsEncrypting] = useState(false);
  const [isStoredInBlockchain, setisStoredInBlockchain] = useState(false);
  const [isStartedToStoreInBlockchain, setIsStartedToStoreInBlockchain] =
    useState(false);
  const [nomineeName, setNomineeName] = useState([""]);
  const [description, setDescription] = useState();
  const [notify, setNotify] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNominee, setSelectedNominee] = useState([]);
  const [selectedNominees, setSelectedNominees] = useState([]);
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [openUpgrade, setOpenUpgrade] = useState(false);

  const user = useSelector((state) => state.account.user);
  const dataNomineeList = useSelector((state) => state.nominee.dataNomineeList);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const heading = location.state?.heading;
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description: "",
    });
  };

  useEffect(() => {
    const initialOptions = [];

    if (dataNomineeList.length > 0) {
      dataNomineeList.map((data, key) => {
        initialOptions.push({
          value: key,
          label: data.nomineeName + " ( " + data.nomineeEmail + " )",
          key: key,
        });
      });
      setOptionsSelect(initialOptions);
    }
  }, [dataNomineeList]);

  useEffect(() => {
    if (dataNomineeList.length > 0) {
      const filteredNominees = dataNomineeList?.filter(
        (data, key) => selectedNominee[key]
      );

      setNomineeName(
        filteredNominees.map((data) => data.nomineeName).join(", ")
      );
      setSelectedNominees(filteredNominees);
    }
  }, [selectedNominee]);

  const showModalUpgrade = () => {
    setOpenUpgrade(true);
  };

  const handleCancelUpgrade = () => {
    setOpenUpgrade(false);
  };

  const [isAddNomineeModalOpen, setIsAddNomineeModalOpen] = useState(false);
  const showAddNomineeModal = () => {
    setIsAddNomineeModalOpen(true);
  };
  const handleAddNomineeCancel = () => {
    setIsAddNomineeModalOpen(false);
  };

  const handleSwitch = (value) => {
    setNotify(value);
  };

  const handleChange = (value) => {
    console.log(value);

    setSelectedNominee(() => ({
      [value]: !selectedNominee[value],
    }));
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setisStoredInBlockchain(false);
    setIsStartedToStoreInBlockchain(false);
    setIsEncrypting(false);
    navigate("/choose");
  };

  const propsf = {
    name: "file",
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log("info: ", info);

        let filesData = {
          name: info.file.originFileObj.name,
          buffer: info.file.originFileObj,
          type: info.file.type,
        };
        let filesNamesTemp = {
          name: info.file.originFileObj.name,
        };

        console.log("filesData: ", filesData);
        setFiles([...files, filesData]);
        setFilesNames([...filesNames, filesNamesTemp]);
      }

      if (status === "done") {
        message.success(`${info.file.name} file selected.`);
      } else if (status === "error") {
        openNotificationWithIcon(
          "error",
          `${info.file.name} file upload failed.`
        );
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const uploadToIPFS = async () => {
    console.log("selectedNominees: ", selectedNominees, "notify: ", notify);

    const zip = new JSZip();

    var filesRealLength = 0;
    if (folderName) {
      for (let i = 0; i < user.files.length; i++) {
        if (user.files[i].folder.toLowerCase() == folderName.toLowerCase()) {
          openNotificationWithIcon("error", "Folder name already exists");
          return;
        }
        if (!user.files[i].deleted) {
          filesRealLength += 1;
        }
      }

      console.log("filesRealLength: ", filesRealLength);

      if (files.length == 0) {
        if (user.premiumAccount === "Silver" && filesRealLength >= 3) {
          console.log("Please upgrade");
          showModalUpgrade();
          return;
        }
        setIsModalOpen(true);

        var filesTemp = [];
        var filesNamesTemp = [{ name: "Readme_" + folderName + ".txt" }];
        const tempString =
          "Name : " +
          folderName +
          "\nDescription : " +
          description +
          "\nAsset Type : " +
          heading;
        zip.file("Readme_" + folderName + ".txt", tempString);
        const content = await zip.generateAsync({ type: "blob" });
        const arrayBuffer = await content.arrayBuffer();
        const buf = new Buffer.from(arrayBuffer);
        var string = arrayBufferToString(buf);
        let temp = {
          name: folderName + ".zip",
          buffer: string,
        };
        filesTemp.push(temp);

        const data = {
          filesData: filesTemp,
          folderName: folderName,
          nominee: selectedNominees[0],
          user: user,
          filesNames: filesNamesTemp,
          notify: notify,
          assetType: heading,
          description: description,
        };

        let response = null;
        let responseData;
        let updatedUser;
        var intervalID;
        try {
          const token = sessionStorage.getItem("accessToken");
          let bool = false;
          setIsEncrypting(true);

          intervalID = setInterval(function () {
            setIsStartedToStoreInBlockchain(!bool);
            setisStoredInBlockchain(bool);
            bool = !bool;
          }, 3000);

          const options = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          response = await axios.post("/api/users/uploadfiles", data, options);

          console.log("response in upload files: ", response.data);
          response = response.data;
          updatedUser = {
            user: response.user,
          };
          responseData = {
            cid: response.cid,
            uploadedStatus: true,
            uploadError: null,
          };
        } catch (err) {
          if (err.response) {
            console.log("err: ", err.response.data);
            responseData = {
              cid: null,
              securityKey: null,
              uploadedStatus: false,
              uploadError: err.response.data,
            };
            updatedUser = user;
          }
          console.log("only err:", err);
        }
        dispatch(actionDataUpload(responseData));
        dispatch(actionUpdateUser(updatedUser));
        setIsStartedToStoreInBlockchain(true);
        setisStoredInBlockchain(true);
        clearInterval(intervalID);

        setDescription("");
        setFolderName("");
        setFiles([]);
        setFilesNames([]);
      } else if (files.length > 0) {
        const size = files.map((file) => file.buffer.size);
        const totalSize = size.reduce((a, b) => a + b, 0);
        const totalSizeMb = totalSize / 1000000;
        const remainingStorage =
          user.storage.totalStorage - user.storage.usedStorage;
        if (totalSizeMb > 30) {
          openNotificationWithIcon(
            "error",
            "File cannot be greater than 30 MB"
          );
          return;
        }
        if (totalSizeMb > remainingStorage) {
          openNotificationWithIcon(
            "error",
            "File cannot be greater than remaining storage"
          );
          return;
        }

        if (user.premiumAccount === "Silver" && filesRealLength >= 3) {
          console.log("Please upgrade");
          showModalUpgrade();
          return;
        }

        setIsModalOpen(true);
        var filesTemp = [];

        files.forEach((data) => {
          console.log("Data Zipped: ", data);
          zip.file(data.name, data.buffer);
        });

        const tempString =
          "Name : " +
          folderName +
          "\nDescription : " +
          description +
          "\nAsset Type : " +
          heading;
        zip.file("Readme_" + folderName + ".txt", tempString);

        const content = await zip.generateAsync({ type: "blob" });
        const arrayBuffer = await content.arrayBuffer();
        const buf = new Buffer.from(arrayBuffer);
        var string = arrayBufferToString(buf);
        let temp = {
          name: folderName + ".zip",
          buffer: string,
        };
        filesTemp.push(temp);

        filesNames.push({ name: "Readme_" + folderName + ".txt" });

        console.log(
          "files: ",
          filesTemp,
          "selectedNominees: ",
          selectedNominees
        );

        const data = {
          filesData: filesTemp,
          folderName: folderName,
          nominee: selectedNominees[0],
          user: user,
          filesNames: filesNames,
          notify: notify,
          assetType: heading,
          description: description,
        };

        let response = null;
        let responseData;
        let updatedUser;
        var intervalID;
        try {
          const token = sessionStorage.getItem("accessToken");

          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let tempPercent = Math.floor((loaded * 100) / total);
              console.log("percentage value is", tempPercent);
              setPercent(tempPercent);
              if (tempPercent === 100) {
                let bool = false;
                setIsEncrypting(true);

                intervalID = setInterval(function () {
                  setIsStartedToStoreInBlockchain(!bool);
                  setisStoredInBlockchain(bool);
                  bool = !bool;
                }, 3000);
              }
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          response = await axios.post("/api/users/uploadfiles", data, options);

          console.log("response in upload files: ", response.data);
          response = response.data;
          updatedUser = {
            user: response.user,
          };
          responseData = {
            cid: response.cid,
            uploadedStatus: true,
            uploadError: null,
          };
        } catch (err) {
          if (err.response) {
            console.log("err: ", err.response.data);
            responseData = {
              cid: null,
              securityKey: null,
              uploadedStatus: false,
              uploadError: err.response.data,
            };
            updatedUser = user;
          }
          console.log("only err:", err);
        }
        dispatch(actionDataUpload(responseData));
        dispatch(actionUpdateUser(updatedUser));
        setIsStartedToStoreInBlockchain(true);
        setisStoredInBlockchain(true);
        clearInterval(intervalID);

        setDescription("");
        setFolderName("");
        setFiles([]);
        setFilesNames([]);
      } else {
        openNotificationWithIcon("warning", "No files selected");
        console.log("No Files Selected");
      }
    }
  };

  return (
    <div className="in-app-layout">
      <Row>
        <Col span={20}>
          <div>
            <div className="page-title">
              <h1 style={{ margin: "0" }}>Protect your {heading}</h1>
            </div>
            <p style={{ paddingTop: "7px" }}>
              Selected Nominee(s):{" "}
              <span style={{ fontWeight: "bold" }}>{nomineeName}</span>
            </p>
            <br />
          </div>
        </Col>
        <Col span={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <Button
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <div style={{ height: 600, maxHeight: 750 }}>
        <Row gutter={20}>
          <Col span={12}>
            <p style={{ paddingBottom: "10px" }}>
              Asset Name{" "}
              <Tooltip title="Enter asset name so that nominees identify the asset.">
                <InfoCircleOutlined />{" "}
              </Tooltip>
            </p>
            <div className="f-c">
              <Input
                id="folderNameInput"
                value={folderName}
                placeholder="Enter a Name"
                onChange={(e) => {
                  setFolderName(e.target.value);
                }}
              />
            </div>
            <p style={{ paddingBottom: "10px", paddingTop: "1rem" }}>
              Description{" "}
              <Tooltip title="Enter a description for your nominee(s) for them easily inherit your assets">
                <InfoCircleOutlined />{" "}
              </Tooltip>
            </p>
            <div className="f-c">
              <Input.TextArea
                id="folderDescInput"
                placeholder="Enter a Description for your nominee(s)"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                rows={4}
              />
            </div>
          </Col>
          <Col style={{ maxHeight: 550 }} span={12}>
            <p
              className="mobileViewSelectLabel"
              style={{ paddingBottom: "10px" }}
            >
              Select Nominee{" "}
              <Tooltip title="Select a nominee for your asset to be transferred">
                <InfoCircleOutlined />{" "}
              </Tooltip>
            </p>
            <Select
              id="folderSelectInput"
              mode="single"
              style={{
                fontSize: "1.2rem",
                border: "none",
                borderRadius: "10px",
                color: "rgb(0, 0, 0)",
                width: "100%",
                border: "1px solid rgb(157, 157, 157)",
              }}
              size="large"
              placeholder="Select one nominee"
              onChange={handleChange}
              optionLabelProp="label"
              options={optionsSelect}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <div>
                    <Button
                      type="text"
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                      onClick={showAddNomineeModal}
                    >
                      Add Nominee
                    </Button>
                  </div>
                </>
              )}
            ></Select>
            <p style={{ paddingBottom: "10px", paddingTop: "1rem" }}>
              Notify Nominee{" "}
              <Tooltip title="Enable, this box to let your nominees know that you have assigned them this asset">
                <InfoCircleOutlined />{" "}
              </Tooltip>
            </p>
            <Switch checked={notify} onChange={handleSwitch} />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <p style={{ paddingBottom: "10px", paddingTop: "1rem" }}>
              Upload Files{" "}
              <Tooltip title="Upload any relevant documents concerning the asset for your nominee">
                <InfoCircleOutlined />{" "}
              </Tooltip>
            </p>
            {user.premiumAccount != "Silver" ? (
              <div>
                <Dragger
                  className="box-color"
                  multiple
                  disabled={user.premiumAccount === "Silver"}
                  {...propsf}
                  showUploadList={false}
                  listType="picture"
                  iconRender={() => {
                    return <h1>loading</h1>;
                  }}
                  style={{
                    maxHeight: "300px",
                    borderRadius: "10px",
                    backgroundColor: "#e1e8f5",
                  }}
                  height={250}
                >
                  <p className="ant-upload-drag-icon">
                    <FileTextOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    You can add or select single or multiple files.
                  </p>
                </Dragger>
              </div>
            ) : (
              <Popover
                content={popoverContent}
                title="Please Upgrade to Upload Documents"
                trigger="hover"
              >
                <div>
                  <Dragger
                    className="box-color"
                    multiple
                    disabled={user.premiumAccount === "Silver"}
                    {...propsf}
                    showUploadList={false}
                    listType="picture"
                    iconRender={() => {
                      return <h1>loading</h1>;
                    }}
                    style={{
                      maxHeight: "300px",
                      borderRadius: "10px",
                      backgroundColor: "#e1e8f5",
                    }}
                    height={250}
                  >
                    <p className="ant-upload-drag-icon">
                      <FileTextOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      You can add or select single or multiple files.
                    </p>
                  </Dragger>
                </div>
              </Popover>
            )}
          </Col>
          <Col span={12}>
            <p style={{ paddingBottom: "10px", paddingTop: "1rem" }}>
              Selected Files{" "}
              <Tooltip title="Your selected and uploaded documents will appear here">
                <InfoCircleOutlined />{" "}
              </Tooltip>
            </p>
            <List
              className="box-color"
              size="large"
              bordered
              dataSource={files}
              renderItem={(item) => (
                <List.Item>
                  <div className="f-c-s">
                    <div className="f-s-1">{item.name}</div>
                    <div
                      onClick={() => {
                        setFiles(
                          files.filter((file) => file.name !== item.name)
                        );
                      }}
                    >
                      <DeleteOutlined className="icon-hover" />
                    </div>
                  </div>
                </List.Item>
              )}
              style={{
                borderRadius: "10px",
                overflowY: "auto",
                maxHeight: "300px",
                height: "250px",
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            paddingTop: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "#123962",
              color: "white",
              height: "45px",
              fontSize: "1rem",
              textAlign: "center",
              alignItems: "center",
              borderRadius: "12px",
              width: "300px",
            }}
            onClick={uploadToIPFS}
          >
            Submit
          </Button>
        </Row>
        <Modal
          title="Creation of Assets"
          open={isModalOpen}
          footer={null}
          closable={true}
        >
          <div>
            {!isStartedToStoreInBlockchain ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!isEncrypting ? (
                  <div>
                    <img
                      src={loading}
                      width={200}
                      height={200}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      alt="percent"
                    />
                    <Progress percent={percent} />
                  </div>
                ) : (
                  <div>
                    <img
                      src={encrypting}
                      width={200}
                      height={200}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      alt="encrypting pic"
                    />
                    <h3
                      style={{
                        alignText: "center",
                      }}
                    >
                      Encrypting Asset
                    </h3>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  {!isStoredInBlockchain ? (
                    <div>
                      <img
                        src={blockchain}
                        width={200}
                        height={200}
                        alt="storing "
                      />
                      <h3
                        style={{
                          alignText: "center",
                        }}
                      >
                        Storing into Blockchain
                      </h3>
                    </div>
                  ) : (
                    <div>
                      <Result
                        status="success"
                        title="Asset Creation Successful"
                        extra={[
                          <Button
                            className="modal-btn"
                            type="primary"
                            key="console"
                            onClick={handleOk}
                          >
                            Okay
                          </Button>,
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
      <AddNominee
        isAddNomineeModalOpen={isAddNomineeModalOpen}
        handleAddNomineeCancel={handleAddNomineeCancel}
        user={user}
      ></AddNominee>
      <UpgradeToPremium
        openUpgrade={openUpgrade}
        handleCancelUpgrade={handleCancelUpgrade}
      ></UpgradeToPremium>
    </div>
  );
}
