import React, {useState} from 'react'
import {init, send} from "emailjs-com";
import {message} from "antd";

function ContactUs() {
    const [sending, setSending] = useState(false)
    const [details, setDetails] = useState({from_name: "", email: "", message: ""})
    init('4cW74CVejG3N_WYGC')

    function handleSubmit(e) {
        e.preventDefault()
        setSending(true)
        send(
            'service_ns0eibb',
            'template_xbxbhck',
            details,
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                message.success('Email sent successfully!')
                setSending(false)
            })
            .catch((err) => {
                message.error('Email sending failed! Please try again later.')
                console.log('FAILED...', err);
                setSending(false)
            });
    }

    return (
        <div style={{minHeight: "90vh", display: "flex", overflowY: "auto"}}>
            <div style={{
                display: "block",
                maxWidth: "40rem",
                justifyContent: "center",
                padding: "20px",
                borderRadius: "20px",
                margin: "auto",
                background: "white",
                border: "2px solid #165188",
                marginTop: "10rem"
            }}>
                <div style={{fontSize: "2rem", color: "#165188", fontWeight: "800", textAlign: "center"}}>Want to talk
                    with us?
                </div>
                <form
                    style={{marginTop: "1rem"}}
                    onSubmit={handleSubmit}
                >
                    <input
                        className="w3a-text-field"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={details.email}
                        required
                        onChange={(e) => setDetails((prev) => ({...prev, email: e.target.value}))}
                    />
                    <input
                        className="w3a-text-field"
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        value={details.from_name}
                        required
                        onChange={(e) => setDetails((prev) => ({...prev, from_name: e.target.value}))}
                    />
                    <textarea
                        className="w3a-text-field"
                        type="text"
                        name="query"
                        placeholder="Describe your issue "
                        value={details.message}
                        required
                        style={{
                            maxWidth: "40rem",
                            minWidth: "40rem",
                            padding: "1.5rem",
                            margin: "auto",
                            minHeight: "10rem"
                        }}
                        onChange={(e) => setDetails((prev) => ({...prev, message: e.target.value}))}
                    />
                    <p style={{fontStyle: "italic", textAlign: "end", textDecoration: "underline"}}>
                        <a href="mailto:akshaysp2090@gmail.com">Contact us via mail </a>
                    </p>
                    <button className='login-button' style={{marginTop: "20px"}} type='submit'
                            disabled={(details?.from_name.trim().length === 0) || (details?.email.trim().length === 0) || (details?.message.trim().length === 0) || sending}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ContactUs