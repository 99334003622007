import React from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "../../src/css/style.css"

import LoginVector from "../images/people-collaborating-with-tech- 1.svg";
import { SignupCard } from "../components/SignupCard";

export default function SignUp() {
  const profileSetup = useSelector((state) => state.account.profileSetup);
  const user = useSelector((state) => state.account.user);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("ProfileSetup signUp:", profileSetup);
    console.log("User signUp:", user);
    if (!profileSetup && user) {
      console.log("triggered info inside signup");
      navigate("/info");
    }
  }, [profileSetup]);

  return (
    <div className="signup-page-bg">
      <Row style={{ marginTop: "3rem" }}>
        <Col
          className="horizontal-center signimagediv"
          span={12}
          style={{ padding: "2rem" }}
        >
          <img className="loginimage" src={LoginVector} />
        </Col>
        <Col
          className="horizontal-center "
          id="logincol"
          span={12}
          style={{ padding: "3rem 6rem" }}
        >
          <SignupCard />
        </Col>
      </Row>
    </div>
  );
}
