import { Button, Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const UpgradeToPremium = ({ openUpgrade, handleCancelUpgrade }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleOk = () => {
    navigate("/subscription");
  };

  return (
    <>
      <Modal
        open={openUpgrade}
        title="Title"
        onCancel={handleCancelUpgrade}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Upgrade
          </Button>,
        ]}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
};
