import physicalAssets from "../images/physicalAssets.svg";
import liquidAssets from "../images/liquidAssets.svg";
import credential from "../images/passwords.svg";
import notes from "../images/notes.svg";
import ChoiceCard from "../components/ChoiceCard";

const cardData = [
  {
    image: liquidAssets,
    heading: "Liquid Assets",
    desc: "Upload important documents like your bank account statements, insurance policies, etc.",
    route: "/liquidAssets",
  },
  {
    image: credential,
    heading: "Credentials",
    desc: "Upload important credentials like your social media account passwords.",
    route: "/chooseCredentials",
  },
  // {
  //     image: physicalAssets,
  //     heading: "Physical Assets",
  //     desc: "Upload your physical like your bank account statements, insurance policies, etc.",
  //     route: "/physicalAssets",
  // },
  // {
  //     image: notes,
  //     heading: "Personal Notes",
  //     desc: "Write a note or a letter that you want to pass on to your loved ones.",
  //     route: "/chooseNotes"
  // }
];

//what why where

export default function ChooseUploadType() {
  return (
    <div className="in-app-layout">
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Upload</h1>
      </div>
      <div className="page-title-desc">
        <p>Select Type of Files that you want to upload.</p>
      </div>
      <div
        className="row"
        style={{ marginTop: "40px", display: "flex", flexWrap: "wrap" }}
      >
        {cardData.map((card, index) => (
          <ChoiceCard
            key={index}
            image={card.image}
            heading={card.heading}
            desc={card.desc}
            route={card.route}
          />
        ))}
      </div>
    </div>
  );
}
