import {signInWithPopup} from "firebase/auth";
import {firebaseAuth, providerGithub} from "../config/Firebase";

export const signInWithGithub = async () => {
    try {
        const result = await signInWithPopup(firebaseAuth, providerGithub)
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;
        console.log("result: ", result)

        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("profilePic", profilePic);
        localStorage.setItem("user", JSON.stringify(firebaseAuth.currentUser))
        localStorage.setItem("loggedIn", "true");

        return {
            status: "success",
            code: 200,
            message: "Successfully logged in.",
            result: result
        }
    } catch (error) {
        console.log("error: ", error)
        return {
            status: "error",
            code: 500,
            message: error.message
        }
    }
};