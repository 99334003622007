import {configureStore} from '@reduxjs/toolkit'
import accountReducer from './slices/accountSlice'
import uploadReducer from './slices/uploadSlice'
import nomineeReducer from './slices/nomineeSlice'

const saveToLocalStorage = (state) => {
    try {
        localStorage.setItem('state', JSON.stringify(state));
    } catch (e) {
        console.error(e);
    }
};

const loadFromLocalStorage = () => {
    try {
        const stateStr = localStorage.getItem('state');
        return stateStr ? JSON.parse(stateStr) : undefined;
    } catch (e) {
        console.error(e);
        return undefined;
    }
};

const persistedStore = loadFromLocalStorage();

export const store = configureStore({
    reducer: {
        account: accountReducer,
        upload: uploadReducer,
        nominee: nomineeReducer
    },
    preloadedState: persistedStore
})

store.subscribe(() => {
    saveToLocalStorage(store.getState())
})