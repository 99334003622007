import styles from "./FooterSection.module.css";
import logo from "../../assets/images/logo.png";
import fb from "../../assets/icons/facebook.png";
import twitter from "../../assets/icons/twitter.png";
import linkedin from "../../assets/icons/linkedin.png";

const FooterSection = () => {
  return (
    <div className={styles.footerSection}>
      <div className={styles.container}>
        <div className={styles.footerLogo}>
          <img src={logo} alt="footer logo" />
        </div>
        <div className={styles.gridContainer}>
          <div className={styles.gridItem}></div>
          <div className={styles.gridItem}></div>
          <div className={styles.gridItem}></div>
          <div className={styles.gridItem}>
            <h3>Company</h3>
            <ul>
              <li>About</li>
              <li
                onClick={() => window.open("https://medium.com/@datanominee")}
              >
                Blog
              </li>

              <li
                onClick={() =>
                  (window.location.href = "mailto:support@datanominee.com")
                }
              >
                Contact
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.lastSection}>
        <div className={styles.copyright}>
          <div>
            <h1>Contacts</h1>

            <div className={styles.grid}>
              <div>datanominee@datanominee.com</div>
              <div>Bengalore, India</div>
            </div>
          </div>
          <div>
            <h1 id="down">Socials</h1>
            <div className={styles.grid}>
              <a href="https://www.facebook.com/profile.php?id=100091663034907">
                <img className={styles.img} src={fb} alt="fb" />
              </a>
              <a href="https://twitter.com/datanominee">
                <img className={styles.img} src={twitter} alt="twitter" />
              </a>
              <a href="https://www.linkedin.com/company/datanominee/">
                <img className={styles.img} src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
