import ChoiceCard from "../components/ChoiceCard";
import insuarances from "../images/insuarances.svg";
import fds from "../images/fds.svg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import socialMedia from "../images/socialMedia.svg";
import banks from "../images/bank.svg";

const cardData = [
  {
    image: socialMedia,
    heading: "Social Media",
    route: "/credentials",
  },
  {
    image: banks,
    heading: (
      <div style={{ textAlign: "center" }}>
        <p>Banks</p>
        <p>(Coming Soon)</p>
      </div>
    ),
    route: "/chooseCredentials",
  },
];

export default function ChooseCreds() {

  const navigate = useNavigate();
  return (
    <div className="in-app-layout">
      <div className="page-title">
        <h1 style={{ margin: "0" }}>Credentials</h1>
      </div>
      <div style={{ float: "right" }}>
        <Button
          type="primary"
          style={{ marginLeft: "10px", background: "rgb(18, 57, 98)" }}
          className="color-white"
          onClick={() => {
            navigate("/choose");
          }}
        >
          Back
        </Button>
      </div>
      <div className="page-title-desc">
        <p>Select Type of Credential</p>
      </div>
      <div
        className="row"
        style={{ marginTop: "40px", display: "flex", flexWrap: "wrap" }}
      >
        {cardData.map((card, index) => (
          <ChoiceCard
            key={index}
            image={card.image}
            heading={card.heading}
            desc={card.desc}
            route={card.route}
            square={true}
          />
        ))}
      </div>
    </div>
  );
}
