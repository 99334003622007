import "../css/style.css";
import { Spin, notification } from "antd";
import { Checkbox } from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionUserSetup } from "../store/slices/accountSlice";

import { signInWithGoogle } from "../services/signInGoogle";
import { signInWithFacebook } from "../services/signInFacebook";
import { signInWithTwitter } from "../services/signInTwitter";
import { signInWithGithub } from "../services/signInGithub";
import { signUpWithEmail } from "../services/signUpWithEmail";
import { useFirebaseAuthContext } from "../contexts/FirebaseVerification";
import { useNavigate } from "react-router-dom";
import axios from "axios";
export const SignupCard = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatched, setPasswordMatched] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [sub, setSub] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setUserLogin } = useFirebaseAuthContext();

  const error = useSelector((state) => state?.account?.error);
  const profileSetup = useSelector((state) => state?.account?.profileSetup);

  // useEffect(() => {
  //   console.log("profileSetup", profileSetup);
  //   if (error) {
  //     console.log("error", error);
  //     openNotificationWithIcon("error", error?.payload?.error);
  //   }
  // }, [error, profileSetup]);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: "Error signing up.",
      description: message,
    });
  };

  async function signInWithProvider(provider) {
    let response = {};
    if (provider === "google") response = await signInWithGoogle();
    if (provider === "twitter") response = await signInWithTwitter();
    if (provider === "github") response = await signInWithGithub();
    if (provider === "facebook") response = await signInWithFacebook();
    console.log("response", response);
    const res = response.result;
    if (response.code === 200) {
      const accessToken = res.user.accessToken;
      // set access token in session storage
      console.log("accessToken", accessToken);
      sessionStorage.setItem("accessToken", accessToken);
      const userData = {
        email: res.user.email,
        uid: res.user.uid,
        name: res.user.displayName,
        photoURL: res.user.photoURL,
      };
      try {
        dispatch(actionUserSetup(userData));
        setUserLogin(response.user);
        navigate("/info");
      } catch (err) {
        console.log("err", err);
        openNotificationWithIcon("error", err.message);
      }
    } else {
      openNotificationWithIcon("error", response.message);
    }
  }

  async function handleEmailSubmit(event) {
    setSubmitting((prevState) => !prevState);
    console.log(submitting);
    event.preventDefault();
    console.log(name, email, password);
    const res = await signUpWithEmail(name, email, password, sub);
    console.log(res);
    if (res.code === 200) {
      const accessToken = res.user.accessToken;
      // set access token in session storage
      console.log("accessToken", accessToken);
      sessionStorage.setItem("accessToken", accessToken);
      setSubmitting((prevState) => !prevState);
      const userData = {
        email: res.user.email,
        uid: res.user.uid,
        name: res.user.displayName,
        photoURL: res.user.photoURL,
      };
      dispatch(actionUserSetup(userData));
      setUserLogin(userData);
      navigate("/info");
    } else {
      setSubmitting((prevState) => !prevState);
      openNotificationWithIcon("error", res.message);
    }
  }

  function handleEmailChange(event) {
    setEmail(event.currentTarget.value);
  }

  function handleNameChange(event) {
    setName(event.currentTarget.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.currentTarget.value);
  }

  const onChange = (e) => {
    setSub(!e.target.checked);
    console.log(sub);
  };

  function handleConfirmPasswordChange(event) {
    setConfirmPassword(event.currentTarget.value);
    if (event.currentTarget.value !== password) {
      setPasswordMatched(false);
    } else {
      setPasswordMatched(true);
    }
  }

  return (
    <div className="login-card">
      <div className="fc">
        <div className="w3a-modal__header">
          <div className="w3a-header">
            <div>
              <div className="w3a-header__title">Welcome to Data Nominee</div>
              <div className="w3a-header__title">Sign Up</div>
            </div>
          </div>
        </div>

        <div className="w3a-modal__content" style={{ paddingTop: 0 }}>
          <div className="w3ajs-social-logins w3a-group">
            {/* <div className="w3a-group__title">CONTINUE WITH</div> */}
            <ul className="w3a-adapter-list">
              <li className="w3a-adapter-item">
                <button
                  type="button"
                  className="w3a-button w3a-button--icon"
                  onClick={() => signInWithProvider("google")}
                >
                  <img
                    src="https://images.web3auth.io/login-google.svg"
                    height="auto"
                    width="auto"
                    alt="login-google"
                  />
                </button>
              </li>
              <li className="w3a-adapter-item">
                <button
                  type="button"
                  className="w3a-button w3a-button--icon"
                  onClick={() => signInWithProvider("facebook")}
                >
                  <img
                    src="https://images.web3auth.io/login-facebook.svg"
                    height="auto"
                    width="auto"
                    alt="login-facebook"
                  />
                </button>
              </li>
            </ul>
          </div>
          <div className="w3ajs-email-passwordless w3a-group w3a-group--email">
            <form
              className="w3ajs-email-passwordless-form"
              onSubmit={handleEmailSubmit}
            >
              <input
                className="w3a-text-field"
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                required
                onChange={handleNameChange}
              />
              <input
                className="w3a-text-field"
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                required
                onChange={handleEmailChange}
              />
              <input
                className="w3a-text-field"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                required
                onChange={handlePasswordChange}
              />
              <input
                className="w3a-text-field"
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                required
                onChange={handleConfirmPasswordChange}
              />
              {!passwordMatched && (
                <p className="required w3a-pw-margin">
                  Passwords do not match!
                </p>
              )}
              {passwordMatched &&
                password.length < 6 &&
                password.length > 1 && (
                  <p className="required w3a-pw-margin">
                    Password length should be atleast 6!
                  </p>
                )}
              <Checkbox onChange={onChange}>
                Subscribe to the newsletter
              </Checkbox>
              {!submitting && (
                <button
                  disabled={!passwordMatched}
                  className="login-button"
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  Continue with Email
                </button>
              )}
              {submitting && (
                <Spin>
                  <button
                    disabled={!passwordMatched}
                    className="login-button"
                    type="submit"
                    style={{ marginTop: "40px" }}
                  >
                    Continue with Email
                  </button>
                </Spin>
              )}
              <button
                className="login-button"
                type="submit"
                style={{ marginTop: "20px", background: "#123962" }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Already have an Account? Login
              </button>
              {contextHolder}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
