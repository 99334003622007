import React, { useContext, useEffect, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { firebaseAuth } from "../config/Firebase";

export const FirebaseAuthContext = React.createContext();

export const useFirebaseAuthContext = () => useContext(FirebaseAuthContext);

export function FirebaseAuthContextProvider({ children }) {
  const tempUser = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(tempUser);

  function setUserLogin(user) {
    setUser(user);
  }

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((changedUser) => {
      if (changedUser && !user) {
        setUser(changedUser);
        console.log("changedUser: ", changedUser);
      } else if (changedUser) {
        if (
          changedUser.displayName &&
          changedUser.email &&
          changedUser.photoURL
        ) {
          setUser(changedUser);
          console.log("changedUser: ", changedUser);
        } else {
          if (changedUser.phoneNumber) {
            console.log("changedUser: ", changedUser);
            setUser((prev) => {
              return {
                ...prev,
                phoneNumber: changedUser.phoneNumber,
              };
            });
          } else {
            console.log("changedUser: ", changedUser);
            setUser(null);
          }
        }
      }
    });
  }, []);

  function setUpRecaptha(number) {
    console.log("number in context: ", number);
    const recaptchaVerifier = new RecaptchaVerifier(
      "get-Otp-button",
      {
        size: "invisible",
      },
      firebaseAuth
    );
    console.log("number in context: ", number);
    recaptchaVerifier.render();
    return signInWithPhoneNumber(firebaseAuth, number, recaptchaVerifier);
  }

  return (
    <FirebaseAuthContext.Provider
      value={{
        setUpRecaptha,
        user,
        setUserLogin,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
}
