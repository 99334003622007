import OpeningSection from './OpeningSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FifthSection from './FifthSection';

const LandingPage = () => {
    return (
        <div>
            <OpeningSection />
            <SecondSection />
            <ThirdSection />
            <FourthSection />
            <FifthSection />
        </div>
    )
}

export default LandingPage;