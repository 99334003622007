import React from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import {
  Button,
  Input,
  List,
  Form,
  Col,
  Row,
  message,
  Upload,
  Result,
  Modal,
  Progress,
  Select,
  notification,
} from "antd";
import { useDispatch } from "react-redux";

import { actionAddDataNominee } from "../store/slices/nomineeSlice";
import "react-phone-number-input/style.css";

export function AddNominee({
  isAddNomineeModalOpen,
  handleAddNomineeCancel,
  user,
}) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const labels = {
    label1: "Name",
    label2: "Email",
    label3: "Number",
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  const addNominee = (e) => {
    console.log("Add nominee e: ", e);
  };

  const onFinish = (values) => {
    var number = "+" + values?.user.number;
    console.log(values, number);
    const accessToken = sessionStorage.getItem("accessToken");
    let nomineeData = {
      name: values?.user.name,
      email: user?.email,
      nomineeEmail: values?.user.email,
      number: number,
      authorization: accessToken,
      relationship: values?.user.relationship,
    };
    if (
      nomineeData.nomineeEmail != nomineeData.email &&
      user?.name != nomineeData.name
    ) {
      dispatch(actionAddDataNominee(nomineeData));
      form.resetFields();
      openNotificationWithIcon("success", "Added Nominee Successfully");
      handleAddNomineeCancel();
    } else {
      openNotificationWithIcon("error", "Cannot add yourself as nominee");
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description: "",
    });
  };

  return (
    <>
      <Modal
        title="Add new nominee"
        open={isAddNomineeModalOpen}
        footer={null}
        onCancel={handleAddNomineeCancel}
      >
        <hr></hr>
        <Form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "20px",
            justifyItems: "center",
            padding: "1rem 1rem",
          }}
          requiredMark={"optional"}
          colon={false}
          // {...layout}
          // wrapperCol={{ ...layout.wrapperCol }}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          form={form}
        >
          <Form.Item
            name={["user", "name"]}
            // label={label1}
            style={{ justifyContent: "center" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={labels.label1} className="input-add-nominee" />
          </Form.Item>
          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
            <Input placeholder={labels.label2} className="input-add-nominee" />
          </Form.Item>
          <Form.Item
            name={["user", "relationship"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select Relationship"
              className="input-add-nominee-select"
            >
              <Select.Option value="Father">Father</Select.Option>
              <Select.Option value="Mother">Mother</Select.Option>
              <Select.Option value="Son">Son</Select.Option>
              <Select.Option value="Daughter">Daughter</Select.Option>
              <Select.Option value="Sibling">Sibling</Select.Option>
              <Select.Option value="Relative">Relative</Select.Option>
              <Select.Option value="Friend">Friend</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["user", "number"]}
            rules={[
              {
                type: "text",
                required: true,
              },
            ]}
          >
            <PhoneInput
              country="in"
              placeholder="Enter phone number"
              rules={{ required: true }}
              inputStyle={{
                backgroundColor: "#dfebf6", // Set your desired background color here
                height: "40px", // Set your desired height here
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              className="color-white"
              type="primary"
              htmlType="submit"
              onClick={addNominee}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
