import { message } from "antd";
import { sendPasswordResetEmail } from "firebase/auth";

import { firebaseAuth } from "../config/Firebase";

export const passwordResetForEmail = async (email) => {
  sendPasswordResetEmail(firebaseAuth, email)
    .then(() => {
      // Password reset email sent successfully
      message.success("Password reset email sent successfully!");
      console.log("Password reset email sent!");
    })
    .catch((error) => {
      // An error occurred
      const errorCode = error.code;
      if (errorCode === "auth/user-not-found") {
        // Handle "user-not-found" error separately
        message.error("User not found. Please check your email address.");
      } else {
        // Handle other errors
        message.error("Could not send password reset email!");
      }
      console.error(error);
    });
};
