import {
  Avatar,
  Button,
  Layout,
  Tabs,
  Table,
  Modal,
  Form,
  Input,
  notification,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import { axiosPostInstance } from "../utils/axiosPostInstance";
import { useNavigate, Link } from "react-router-dom";
import getPlans from "../helpers/getPlans";
import PlanCard from "../components/PlanCard";
import {
  actionDeleteReferee,
  actionUserAddReferees,
} from "../store/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";

export default function Settings() {
  const user = useSelector((state) => state.account.user);
  const refereesListArray = [];
  const [paymentDetails, setPaymentDetails] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataPaymentList, setDataPaymentList] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description: "",
    });
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  const labels = {
    label1: "Name",
    label2: "Email",
    label3: "PhoneNumber",
  };

  const handleDeleteReferee = (key) => {
    if (user.referees.length <= 1) {
      openNotificationWithIcon("error", "Cannot delete all trustees");
      return;
    }
    const accessToken = sessionStorage.getItem("accessToken");
    const refereeToDelete = refereesListArray[key];

    const refereeData = {
      email: user.email,
      refereeEmail: refereeToDelete.email,
      authorization: accessToken,
    };
    console.log("Nominee js:", refereeData);
    dispatch(actionDeleteReferee(refereeData));
    openNotificationWithIcon(
      "success",
      "Trustee " + refereeToDelete.email + " Deleted"
    );
  };

  const handleRefereeSubmit = async (values) => {
    console.log("Length: ", user.referees.length >= 3);
    if (user.referees.length >= 3) {
      openNotificationWithIcon("error", "There are three trustees already");
      return;
    }

    for (let i in user.referees) {
      if (values.user.email == user.referees[i].refereeEmail) {
        openNotificationWithIcon("error", "Trustee email already exists");
        return;
      }
      if (values.user.phoneNumber == user.referees[i].refereePhone) {
        openNotificationWithIcon(
          "error",
          "Trustee phone number already exists"
        );
        return;
      }
    }
    console.log("values: ", values);
    const refereeList = [];
    const refereeData = values.user;
    refereeList.push(refereeData);

    const dataToSend = {
      email: user.email,
      referees: refereeList,
    };
    console.log("refereeData: ", dataToSend);
    dispatch(actionUserAddReferees(dataToSend));
    openNotificationWithIcon("success", "Trustee Added");
    setIsModalOpen(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const columns = [
    {
      title: "Trustee Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      align: "center  ",
      width: "5%",
      className: "column-title",
    },
    // {
    //     title: "",
    //     dataIndex: 'claim',
    //     key: 'claim',
    //     align: 'center',
    //     width: '5%',
    //     className: 'column-title',
    //     render: (text, _record) => {

    //         return (
    //             <div>
    //                 <Button type='primary' style={{ color: "white" }}><Link to="/editreferee" state={{ record: _record }} >Edit</Link></Button>
    //             </div>

    //         );
    //     },
    // },
  ];
  useEffect(() => {
    console.log(user.referees);
    if (!user.email) return;
    (async () => {
      const monthlyPlans = getPlans(user.premiumAccount);
      console.log("monthlyPlans: ", monthlyPlans);
      setCurrentPlan(monthlyPlans);

      // if user object does not contain payment, then return
      if (!Object.keys(user).includes("payment")) {
        console.log("user does not have payment object");
        return;
      }

      const token = sessionStorage.getItem("accessToken");
      const response = await axiosPostInstance(
        "/api/users/getOrderDetails",
        token,
        {
          email: user.email,
          orderId: user?.payment?.orderId,
          paymentId: user?.payment?.paymentId,
        }
      );

      console.log("Response for Payment in Settings: ", response);

      setPaymentDetails(response.data);
    })();
  }, [user.email, user.premiumAccount]);

  const data = user.referees
    ? user.referees.map((data, key) => {
        refereesListArray.push({
          key: key,
          name: data.refereeName,
          email: data.refereeEmail,
          phone: data.refereePhone,
          id: data.refereeId,
          delete: (
            <Button
              type="primary"
              danger
              onClick={() => {
                handleDeleteReferee(key);
              }}
            >
              Delete
            </Button>
          ),
        });
      })
    : "";

  const ColumnsforPaymentsHistory = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "5%",
      className: "column-title",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "5%",
      className: "column-title",
    },
  ];

  const paymentDetailsUpdate = () => {
    const dataPaymentListTemp = [];

    console.log("Inside paymentDetails");
    var DateTimeCreatedAt = new Date(
      paymentDetails?.order.created_at * 1000
    ).toDateString();
    var Type = paymentDetails?.order.receipt.split("-")[0];
    var Amount =
      paymentDetails?.order.amount_paid / 100 +
      " " +
      paymentDetails?.order.currency;

    dataPaymentListTemp.push({
      date: DateTimeCreatedAt,
      type: Type,
      amount: Amount,
    });
    setDataPaymentList(dataPaymentListTemp);
  };

  useEffect(() => {
    console.log("Inside UseEffect :", paymentDetails);
    if (paymentDetails && paymentDetails.payments) {
      paymentDetailsUpdate();
    }
  }, [paymentDetails]);

  const tableProps = {
    scroll: { x: "max-content" }, // Enable horizontal scrolling for the table
  };

  const items = [
    {
      label: "Account",
      key: "1",
      children: (
        <div className="settings-tab">
          <div
            style={{
              boxShadow: "0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              background: "#b4c5e1",
              padding: "2rem 2rem",
              flexDirection: "column",
              borderRadius: "10px",
            }}
          >
            <Avatar
              className="settings-tab-left-photo"
              src={user.photoURL}
              size={150}
            >
              <p style={{ fontSize: "4rem" }}>
                {user?.name.split("")[0].toUpperCase()}
              </p>
            </Avatar>
            <br />
            <div
              className="big-name color-black"
              style={{ fontSize: "1.8rem" }}
            >
              {user.name}
            </div>
            <div style={{ fontSize: "1.3rem" }}>{user.email}</div>
            <div style={{ fontSize: "1.3rem" }}>{user.phone}</div>
          </div>
        </div>
      ),
    },
    {
      label: "Billing",
      key: "2",
      children: (
        <div className="settings-tab">
          <div style={{ paddingBottom: "1rem" }}>
            <p className="f-l-1_2">Manage billing information</p>
          </div>
          {paymentDetails && (
            <>
              <Table
                className="table-container"
                dataSource={dataPaymentList}
                columns={ColumnsforPaymentsHistory}
                style={{ paddingBottom: "1rem" }}
                {...tableProps}
              ></Table>
              <p className="f-l-1_3">Payment Method</p>
              <p className="f-s-1">
                {paymentDetails?.payments?.items[0]?.bank
                  ? `${paymentDetails?.payments?.items[0]?.bank} ending in ${paymentDetails?.payments?.items[0]?.bank}`
                  : null}
              </p>{" "}
            </>
          )}
          {!paymentDetails && (
            <div>
              <p style={{ color: "red", fontSize: "1rem", fontWeight: "500" }}>
                No orders found
              </p>
              <br />
              <Button type="primary" onClick={() => navigate("/subscription")}>
                Purchase Subscription
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      label: "Subscription",
      key: "3",
      children: (
        <div className="settings-tab f-c-c">
          <p className="f-d-3 f-c">Current Plan</p>
          <PlanCard
            planName={user?.premiumAccount}
            amount={currentPlan?.amount}
            features={currentPlan?.features}
            handleClick={() => navigate("/subscription")}
            buttonText={"Change Plan"}
          />
        </div>
      ),
    },
    {
      label: "Trustees",
      key: "4",
      children: (
        <div>
          <div style={{ marginTop: "30px" }}>
            <Table
              className="table-container"
              columns={columns}
              dataSource={refereesListArray}
              pagination={false}
              {...tableProps}
            />
          </div>
          <div className="horizontal-center">
            {user.referees.length >= 3 ? (
              ""
            ) : (
              <Button
                type="primary"
                className="f-w-600 color-white"
                onClick={showModal}
                style={{ marginTop: "30px" }}
              >
                Add Trustee
              </Button>
            )}
            <Modal
              title="Add New Trustee"
              open={isModalOpen}
              // style={{textAlign:"center"}}
              footer={null}
              onCancel={handleCancel}
            >
              <hr></hr>
              <Form
                form={form}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginTop: "20px",
                  justifyItems: "center",
                }}
                requiredMark={"optional"}
                colon={false}
                {...layout}
                wrapperCol={{ ...layout.wrapperCol, offset: 5 }}
                name="user"
                onFinish={handleRefereeSubmit}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name={["user", "name"]}
                  // label={label1}
                  style={{ justifyContent: "center" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={labels.label1}
                    className="input-add-nominee"
                  />
                </Form.Item>
                <Form.Item
                  name={["user", "email"]}
                  // label={label2}

                  rules={[
                    {
                      type: "email",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={labels.label2}
                    className="input-add-nominee"
                  />
                </Form.Item>
                <Form.Item
                  name={["user", "phoneNumber"]}
                  // label={label3}
                  rules={[
                    {
                      type: "text",
                      required: true,
                    },
                  ]}
                >
                  <PhoneInput
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    className="f-w-600 color-white"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="in-app-layout">
      <Layout>
        <div className="page-title">
          <h1 style={{ margin: "0", fontSize: "2.3rem" }}>Settings</h1>
        </div>
        <div className="page-title-desc">
          <p>Manage your account</p>
        </div>
        <div className="settings-container">
          <Tabs
            defaultActiveKey="1"
            className="active-tab"
            type="card"
            size={"middle"}
            items={items}
            style={{ fontFamily: "Inter", color: "black" }}
          />
        </div>
      </Layout>
    </div>
  );
}
