import styles from "./FifthSection.module.css";
import { useState } from "react";
import axios from "axios";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { axiosPostInstance } from "../../../../utils/axiosPostInstance";

const FifthSection = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      openNotificationWithIcon("error", "Error", "Please enter a valid email");
      return;
    }
    // regex to check if email is valid
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(email)) {
      openNotificationWithIcon("error", "Error", "Please enter a valid email");
      return;
    }
    try {
      const token = sessionStorage.getItem("accessToken");
      await axiosPostInstance("/api/users/addEmailToSheets", token, { email });
      openNotificationWithIcon(
        "success",
        "Success",
        "Email added successfully"
      );
      setTimeout(() => {
        navigate("/register");
      }, 1500);
    } catch (e) {
      openNotificationWithIcon("error", "Error", "Something went wrong");
    }
  };

  return (
    <div className={styles.FifthSection}>
      <div className={styles.gridContainer}>
        <div>
          <h1 className={styles.heading}>Sign Up</h1>
          <p className={styles.para}>
            Don't wait until it's too late. Protect your digital assets for the
            future with Datanominee. Join Us
          </p>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.input}>
            <form>
              <div>
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter your email..."
                />
              </div>
              <div
                onClick={handleSubmit}
                className={styles.icon}
                style={{ cursor: "pointer" }}
              >
                Submit
              </div>
            </form>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default FifthSection;
